import { font } from '@zipdrug/ui';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import LeadListTable from './components/LeadListTable/LeadListTable';

const sx = StyleSheet.create({
  leadListContainer: {
    padding: '15px',
  },
  leadListHeader: {
    fontSize: '18px',
    marginBottom: '20px',
    color: '#231E33',
    fontWeight: font.weight.medium,
  },
});

const LeadList = () => {
  return (
    <div className={css(sx.leadListContainer)}>
      <p className={css(sx.leadListHeader)}>Lead List</p>
      <LeadListTable />
    </div>
  );
};

export default LeadList;
