import { withSession } from '@zipdrug/react-api-sdk';
import { queryUsers } from 'graphql/users';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withRoles from './withRoles';
import fetchOnceOptions from './utils/fetchOnceOptions';

export const buildUserQueryVariables = ({
  isPbsTeam,
  isPharmacistTeam,
  isPharmacyTeam,
  hasRole,
}) => {
  const query = {};
  if (isPbsTeam) {
    query.roles = { user_group: 'pbs' };
  } else if (hasRole.pharmacyAsc || hasRole.pharmacyAscAdmin) {
    query.roles = { user_group: { $in: ['pharmacy', 'pharmacyteam'] } };
  } else if (isPharmacyTeam) {
    query.roles = { user_group: 'pharmacyteam' };
  } else if (isPharmacistTeam) {
    query.roles = { user_group: 'pharmacy' };
  }
  return query;
};

export default function withUsers(Component) {
  const ComponentWithData = graphql(queryUsers, {
    skip: ({ userId }) => !userId,
    options: props =>
      fetchOnceOptions('users', {
        variables: {
          query: buildUserQueryVariables(props),
        },
      }),
    props: ({ data: { loading: isLoading, users: result }, ownProps: { userId } }) => ({
      isLoading,
      // Prevent current session user and deleted users from being included in results
      users: get(result, 'data', []).filter(u => u && !u.deleted_at && u.id !== userId),
    }),
  })(Component);

  return compose(withSession, withRoles)(ComponentWithData);
}
