import {
  border,
  colors,
  Div,
  font,
  FormNumberField,
  Label,
  padding,
  position,
  rem,
  space,
  unit,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';

const NumberInputPadding = {
  horizontal: '0.8rem',
  vertical: '0.6rem',
};

const sx = StyleSheet.create({
  container: {
    position: 'relative',
  },
  field: padding.build(0),
  input: {
    ...border.buildRadius('', '0.4rem'),
    ...padding.build(NumberInputPadding.vertical),
    fontSize: font.size.xSmall,
    fontWeight: font.weight.boldSemi,
    height: 'initial',
    paddingLeft: NumberInputPadding.horizontal,
    paddingRight: rem(unit(NumberInputPadding.horizontal) + unit(space.half)),
    textAlign: 'right',
    width: '5rem',
  },
  label: {
    ...position.absCenterVertical,
    color: colors.gray52,
    fontSize: font.size.xSmall,
    fontWeight: font.weight.boldSemi,
    right: NumberInputPadding.horizontal,
  },
});

const FilterMedicationsFieldItemInput = ({ name, ...props }) => (
  <Label htmlFor={name} styles={sx.container}>
    <FormNumberField {...props} id={name} inputStyles={sx.input} name={name} styles={sx.field} />
    <Div styles={sx.label}>%</Div>
  </Label>
);

FilterMedicationsFieldItemInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FilterMedicationsFieldItemInput;
