import React, { Fragment } from 'react';
import _get from 'lodash/get';
import { HashRouter, Route, Switch } from 'react-router-dom';
import CallCenterBar from 'ui/CallCenter/CallCenterBar';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import { SessionContext } from 'contexts/SessionContextProvider';
import * as models from '../contexts/utils/models';
import EmailVerificationPageContainer from '../login/EmailVerificationPageContainer';
import LoginPage from '../login/LoginPage';
import PinLoginPageContainer from '../login/PinLoginPageContainer';
import ForgotPasswordPage from '../password/ForgotPasswordPage';
import ResetPasswordPage from '../password/ResetPasswordPage';
import PatientsPage from '../patients/PatientsPage';
import CreateWorkstationPhonePageContainer from '../register/CreateWorkstationPhonePageContainer';
import RegisterPage from '../register/RegisterPage';
// import RegisterWorkstationPageContainer from '../register/RegisterWorkstationPageContainer';
import MyAccountPage from '../settings/my-account/MyAccountPage';
import HelpPage from '../settings/help/HelpPage';
import PhonesPage from '../settings/phones/PhonesPage';
import TeamPage from '../settings/team/TeamPage';
import ModalConductorContainer from '../ui/ModalConductorContainer';
import AuthenticatedRoute from './AuthenticatedRoute';
import VoicemailContainer from '../settings/voicemail/VoicemailPage';
import SupervisorContainer from '../settings/supervisor/SupervisorPage';
import IdleTimerContainer from '../session-trackers/IdleTimerContainer';
import AuthenticatedComponent from './AuthenticatedComponent';
import TopBarContainer from '../ui/topbar/TopBarContainer';
// import OutcomesContainer from '../dashboard/components/outcomes/OutcomesContainer';
import LeadList from '../dashboard/components/LeadList/LeadList';
import PharmaciesPage from '../settings/pharmacies/PharmaciesPage';
import EnrollersListPage from '../settings/EnrollersList/EnrollersListPage';
import NotesPage from '../settings/notes/NotesPage';
import PharmacyNetworksPage from '../settings/pharmacy-networks/PharmacyNetworksPage';
import CampaignPage from '../settings/campaigns/CampaignPage';
import PharmacyTabsPage from '../dashboard/components/PharmacyList/components/PharmacyInfoModal/PharmacyTabsPage';
import ZipCodeAssignmentPage from '../settings/zipcode-assignment/ZipCodeAssignmentPage';
import CohortList from '../dashboard/components/CohortList/CohortList';
import SiteDownPage from '../login/SiteDownPage';

const Routes = () => {
  return (
    <HashRouter>
      <Fragment>
        <AuthenticatedComponent>
          <CallCenterContext.Consumer>
            {CallCenterContextProps => {
              if (_get(CallCenterContextProps, 'callCenterUserStatusContext')) {
                return (
                  <IdleTimerContainer
                    callCenterStatus={_get(CallCenterContextProps, 'callCenterUserStatusContext')}
                  />
                );
              }

              return null;
            }}
          </CallCenterContext.Consumer>
        </AuthenticatedComponent>
        <AuthenticatedComponent>
          <TopBarContainer />
        </AuthenticatedComponent>
        <AuthenticatedComponent>
          <SessionContext.Consumer>
            {({ state: { currentUser } }) => {
              if (currentUser?.isPbsTeam || currentUser?.isSystemAdmin) {
                return <CallCenterBar />;
              }
              return null;
            }}
          </SessionContext.Consumer>
        </AuthenticatedComponent>
        <Switch>
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route path="/signin" component={LoginPage} />
          <Route path="/pin-authentication" component={PinLoginPageContainer} />
          <Route path="/verify-email" component={EmailVerificationPageContainer} />
          <Route path="/site-down" component={SiteDownPage} />
          <AuthenticatedRoute
            allowedRoles={[
              models.Role.SystemAdmin,
              ...models.PBSTeamRoles,
              ...models.PharmacyTeamRoles,
            ]}
            path="/create-phone"
            component={CreateWorkstationPhonePageContainer}
          />
          <AuthenticatedRoute
            path="/members"
            allowedRoles={[
              models.Role.SystemAdmin,
              ...models.PBSTeamRoles,
              ...models.PharmacyTeamRoles,
              ...models.PharmacistRoles,
            ]}
            component={PatientsPage}
          />
          {/* <AuthenticatedRoute
            allowedRoles={[
              models.Role.SystemAdmin,
              ...models.PBSTeamRoles,
              ...models.PharmacyTeamRoles,
            ]}
            path="/register-phone"
            component={RegisterWorkstationPageContainer}
          /> */}
          <AuthenticatedRoute path="/settings/account" component={MyAccountPage} />
          <AuthenticatedRoute path="/settings/help" component={HelpPage} />
          <AuthenticatedRoute
            allowedRoles={[models.Role.SystemAdmin, models.Role.PbsAdmin, models.Role.PbsLead]}
            path="/settings/voicemail"
            component={VoicemailContainer}
            allowedFeatureFlags={{ callCenter: true }}
          />
          <AuthenticatedRoute
            allowedRoles={[models.Role.SystemAdmin, models.Role.PbsAdmin, models.Role.PbsLead]}
            path="/settings/supervisor"
            component={SupervisorContainer}
            allowedFeatureFlags={{ callCenter: true }}
          />
          <AuthenticatedRoute
            allowedRoles={[models.Role.SystemAdmin, ...models.PBSTeamRoles]}
            path="/settings/phones"
            component={PhonesPage}
            allowedFeatureFlags={{ callCenter: false }}
          />
          <AuthenticatedRoute path="/settings/team" component={TeamPage} />
          {/* <AuthenticatedRoute
            allowedRoles={[models.Role.PharmacyUser, models.Role.PharmacyAdmin]}
            path="/outcomes"
            component={OutcomesContainer}
          /> */}
          <AuthenticatedRoute
            allowedRoles={[models.Role.SystemAdmin, ...models.PBSTeamRoles]}
            path="/leadlist"
            component={LeadList}
          />
          <AuthenticatedRoute path="/settings/pharmacylist/modify" component={PharmacyTabsPage} />
          <AuthenticatedRoute
            allowedRoles={[
              models.Role.SystemAdmin,
              ...models.PBSTeamRoles,
              ...models.PharmacyTeamRoles,
            ]}
            path="/settings/pharmacylist"
            component={PharmaciesPage}
          />
          <AuthenticatedRoute
            allowedRoles={[
              models.Role.SystemAdmin,
              models.Role.PharmacyBusinessDev,
              models.Role.PharmacyBusinessDevAdmin,
              models.Role.ProjectManager,
            ]}
            path="/settings/pharmacynetworkslist"
            component={PharmacyNetworksPage}
          />
          <AuthenticatedRoute
            allowedRoles={[models.Role.SystemAdmin, ...models.PharmacyTeamRoles]}
            path="/settings/zipcodeassignment"
            component={ZipCodeAssignmentPage}
          />
          <AuthenticatedRoute
            allowedRoles={[models.Role.SystemAdmin, ...models.PharmacyTeamRoles]}
            path="/settings/noteslist"
            component={NotesPage}
          />
          <AuthenticatedRoute
            allowedRoles={[models.Role.SystemAdmin, ...models.PBSTeamRoles]}
            path="/settings/enrollerlist"
            component={EnrollersListPage}
          />
          <AuthenticatedRoute
            allowedRoles={[
              models.Role.SystemAdmin,
              models.Role.PharmacyBusinessDevAdmin,
              models.Role.PbsAdmin,
              models.Role.PbsLead,
              models.Role.PharmacyAscAdmin,
              models.Role.ProjectManager,
            ]}
            path="/settings/campaigns"
            component={CampaignPage}
          />
          <AuthenticatedRoute path="/settings/enrollerlist" component={EnrollersListPage} />
          <AuthenticatedRoute path="/settings/noteslist" component={NotesPage} />
          <AuthenticatedRoute
            allowedFeatureFlags={{ cohort: true }}
            path="/cohortlist"
            component={CohortList}
          />
          <AuthenticatedRoute path="*" />
        </Switch>
        <ModalConductorContainer />
      </Fragment>
    </HashRouter>
  );
};

export default Routes;
