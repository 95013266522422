import { border, Column, FlexItem, Row } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import PasswordExpirationWarning from '../password/PasswordExpirationWarning';
import Page from '../ui/Page';
import ActivityFeedContainer from './activity-feed/ActivityFeedContainer';
import PatientInfoContainer from './info/PatientInfoContainer';
import RxPrescriberGroupListContainer from './rx/RxPrescriberGroupListContainer';
import PatientsSidebarContainer from './sidebar/PatientsSidebarContainer';

const sx = StyleSheet.create({
  activityFeed: {
    ...border.build('left'),
    minWidth: '50rem',
    height: '100%',
  },
  column: {
    flex: 1,
  },
  rxColumn: {
    maxWidth: 'initial',
  },
  sidebar: {
    flex: 'none',
    width: '17vw',
    maxWidth: '32rem',
    minWidth: '20rem',
    height: '100%',
  },
  rightContainer: {
    height: '100%',
    width: '85vw',
  },
  patientInfoContainer: {
    height: '18vh',
  },
  activityContainer: {
    height: '78vh',
    maxHeight: '81vh',
  },
});

export default props => (
  <Page direction="column">
    <FlexItem component={Row}>
      <PatientsSidebarContainer styles={sx.sidebar} />
      <FlexItem component={Column} styles={sx.rightContainer}>
        <PasswordExpirationWarning />
        <PatientInfoContainer styles={sx.patientInfoContainer} {...props} />
        <FlexItem component={Row} styles={sx.activityContainer}>
          <ActivityFeedContainer styles={[sx.activityFeed, sx.column]} />
          <RxPrescriberGroupListContainer styles={[sx.rxColumn, sx.column]} />
        </FlexItem>
      </FlexItem>
    </FlexItem>
  </Page>
);
