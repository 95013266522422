import { StyleSheet } from 'aphrodite/no-important';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  border,
  colors,
  Column,
  Div,
  FlexCentered,
  font,
  Icon,
  padding,
  position,
  space,
  transition,
} from '@zipdrug/ui';
import ContactListItem from './ContactListItem';

const sx = StyleSheet.create({
  addButton: {
    ...border.build('top'),
    ...padding.build(font.size.medium, 0),
    ...transition.build(),
    color: colors.theme.primary.default,
    cursor: 'pointer',
    position: 'relative',
    userSelect: 'none',
    ':hover': {
      backgroundColor: colors.gray98,
      color: colors.theme.primary.hover,
    },
    ':active': { color: colors.theme.primary.active },
  },
  addButtonIcon: {
    ...position.size(font.size.medium),
    marginRight: space.quarter,
  },
  container: {
    width: '28rem',
  },
  list: {
    maxHeight: '50rem',
    overflowY: 'auto',
  },
});

export default class ContactList extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    contactItem: PropTypes.object,
    isEditable: PropTypes.bool,
    onEditItem: PropTypes.func,
    onToggleEditState: PropTypes.func,
    onUpdateContactOrder: PropTypes.func,
  };

  renderAddButton = () =>
    this.props.isEditable ? (
      <FlexCentered
        id="e2e-contactListAddNumber"
        onClick={this.props.onToggleEditState}
        styles={sx.addButton}
      >
        <Icon name="circlePlus" styles={sx.addButtonIcon} />
        <Div>Add number</Div>
      </FlexCentered>
    ) : null;

  renderList = () =>
    get(this.props, 'contactItem.phones', []).map((phone, index) => (
      <ContactListItem
        callStatus={this.props.contactItem.call_status}
        index={index}
        isEditable={this.props.isEditable}
        key={phone.id}
        onClickEdit={this.props.onEditItem}
        onUpdateContactOrder={this.props.onUpdateContactOrder}
        phone={phone}
        isPatientContact={this.props.contactItem.__typename === 'Patient'}
      />
    ));

  render = () => (
    <Column styles={sx.container}>
      <Div styles={sx.list}>{this.renderList()}</Div>
      {this.renderAddButton()}
    </Column>
  );
}
