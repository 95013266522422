import { Button, colors, Div, FlexCentered, font, padding, position, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, withHandlers } from 'recompose';
// eslint-disable-next-line import/extensions
import { DEVELOPER_SECRET } from '../settings.js';

const sx = StyleSheet.create({
  button: {
    ...padding.build(space.half),
    borderRadius: 6,
    color: colors.white,
    flex: 1,
    fontSize: font.size.medium,
    height: '14rem',
    marginLeft: space.default,
    maxWidth: '50%',
    textAlign: 'center',
    ':first-of-type': { marginLeft: 0 },
  },
  innerWrapper: position.cover,
  emoji: {
    fontSize: '3.5rem',
    marginBottom: space.half,
  },
});

const DeveloperLoginPanelButton = ({ onClick, roleType }) => {
  const roleParts = roleType.split('_');
  const isAdmin = roleParts[1] === 'admin';
  const isZipdrugUser = roleParts[0] === 'zipdrug';

  return (
    <Button styles={sx.button} onClick={onClick} type={isZipdrugUser ? 'primary' : 'success'}>
      <FlexCentered direction="column" styles={sx.innerWrapper}>
        <Div styles={sx.emoji}>{isAdmin ? '👑' : '👶'}</Div>
        <Div>{startCase(roleType)}</Div>
      </FlexCentered>
    </Button>
  );
};

DeveloperLoginPanelButton.propTypes = {
  onClick: PropTypes.func,
  roleType: PropTypes.string,
};

export default compose(
  withHandlers({
    onClick: ({ roleType, onClick }) => () =>
      onClick({
        email: `devs+${roleType}@zipdrug.com`,
        password: DEVELOPER_SECRET,
      }),
  }),
)(DeveloperLoginPanelButton);
