import {
  Checkbox,
  CheckboxItem,
  colors,
  Div,
  EnDash,
  font,
  margin,
  Nbsp,
  padding,
  rem,
  Row,
  space,
  Span,
  unit,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import { lifecycle } from 'recompose';
import FilterMedicationsFieldItemInput from './FilterMedicationsFieldItemInput';

const CheckboxItemPadding = rem(unit(font.size.small) / 2);

const sx = StyleSheet.create({
  accordionRow: {
    paddingBottom: CheckboxItemPadding,
    paddingLeft: rem(unit(Checkbox.defaultProps.size) + unit(CheckboxItem.checkboxMargin)),
  },
  checkboxItem: padding.build(0),
  checkboxItemChecked: {
    paddingBottom: space.half,
  },
  container: {
    ...padding.build(CheckboxItemPadding, 0),
    color: colors.white,
    fontSize: font.size.small,
    ':first-child': {
      marginTop: '0.8rem',
      paddingTop: 0,
    },
    ':last-child': { paddingBottom: 0 },
  },
  formField: padding.build(space.default, 0),
  inputDividerText: {
    ...margin.build(0, CheckboxItemPadding),
    color: colors.gray45,
    cursor: 'default',
    fontSize: font.size.nano,
    fontWeight: font.weight.boldSemi,
    userSelect: 'none',
  },
  inputRowLabel: {
    cursor: 'default',
    fontWeight: font.weight.boldSemi,
    marginLeft: font.size.nano,
    userSelect: 'none',
  },
});

const FormProps = {
  hideBrowserInputControls: true,
  max: 100,
  min: 0,
  preserveDimensions: false,
  styles: sx.formField,
  theme: 'dark',
};

const FilterMedicationsFieldItem = props => {
  const { checked, label, shouldAutoFocus, value } = props;
  return (
    <Div styles={sx.container}>
      <CheckboxItem
        {...props}
        checked={checked}
        id={label}
        label={
          <Span>
            {label}
            <Nbsp />
            <EnDash />
            <Nbsp />
            CMS
          </Span>
        }
        styles={[sx.checkboxItem, checked ? sx.checkboxItemChecked : null]}
      />
      {checked && (
        <Row align="center" styles={sx.accordionRow}>
          <FilterMedicationsFieldItemInput
            {...FormProps}
            autoFocus={shouldAutoFocus}
            name={`${value}.min`}
            placeholder="0"
            title="Minimum PDC bounds"
          />
          <Div styles={sx.inputDividerText}>TO</Div>
          <FilterMedicationsFieldItemInput
            {...FormProps}
            name={`${value}.max`}
            placeholder="100"
            title="Maximum PDC bounds"
          />
          <Div styles={sx.inputRowLabel}>PDC</Div>
        </Row>
      )}
    </Div>
  );
};

FilterMedicationsFieldItem.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  shouldAutoFocus: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default lifecycle({
  componentWillReceiveProps(nextProps) {
    this.setState({ shouldAutoFocus: this.props.checked !== nextProps.checked });
  },
})(FilterMedicationsFieldItem);
