import {
  animation,
  colors,
  Div,
  FlexCentered,
  font,
  Icon,
  position,
  space,
  transition,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';

const EMPTY_STATE_TYPES = {
  inbox: {
    icon: 'inbox',
    title: 'Inbox is empty',
  },
  search: {
    icon: 'circleSearch',
    items: ['Name', 'Birthday', 'Member ID', 'Phone number'],
    title: 'Search by:',
  },
  snoozed: {
    icon: 'clock',
    title: 'Snoozed is empty',
  },
};

const sx = StyleSheet.create({
  container: {
    ...animation.build({ delay: 100 }),
    ...transition.build(),
    color: colors.gray48,
    cursor: 'default',
    height: '100%',
    opacity: 0,
    userSelect: 'none',
  },
  icon: {
    ...position.size('5rem'),
    color: colors.gray35,
    marginBottom: font.size.medium,
  },
  item: {
    marginTop: space.quarter,
  },
  title: {
    fontWeight: font.weight.boldSemi,
  },
});

const PatientListEmptyState = ({ styles, type }) => (
  <FlexCentered direction="column" styles={[sx.container, styles]}>
    <Icon name={EMPTY_STATE_TYPES[type].icon} styles={sx.icon} />
    <Div styles={sx.title}>{EMPTY_STATE_TYPES[type].title}</Div>
    {(EMPTY_STATE_TYPES[type].items || []).map(item => (
      <Div key={item} styles={sx.item}>
        {item}
      </Div>
    ))}
  </FlexCentered>
);

PatientListEmptyState.propTypes = {
  styles: aphroditePropType,
  type: PropTypes.oneOf(Object.keys(EMPTY_STATE_TYPES)).isRequired,
};

PatientListEmptyState.defaultProps = {
  type: 'inbox',
};

export default PatientListEmptyState;
