import { font } from '@zipdrug/ui';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { isEmpty, get, debounce, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery, useMutation, useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import { Button, Modal, Typography, Form, Select, Input, Upload, message, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { StyleSheet, css } from 'aphrodite';
import { PHARMACY_NAMES } from 'dashboard/components/ZipCodeAssignmentList/graphql/zipcodeManagementCalls';
import statuses from './constants/constants';
import priorities from './constants/Priorities';
import ADD_CAMPAIGN from '../../graphql/addCampaign';
import UPDATE_CAMPAIGN from '../../graphql/updateCampaign';
import FIND_CAMPAIGN from '../../graphql/findCampaign';
import GET_SPECIAL_NOTES from '../../graphql/getSpecialNotes';
import GET_ENROLLERS_FOR_CAMPAIGNS from '../../graphql/getEnrollersForCampaigns';
import GET_PLANS_BY_FILTER from '../../graphql/getPlansByFilter';

export const GET_PAYERS = gql`
  query payers($query: JSON) {
    payers(query: $query) {
      data {
        name
        id
      }
      total
    }
  }
`;

// CSS styles
const sx = StyleSheet.create({
  largeInputBox: { width: '50%' },
  inputBox: { minWidth: '170px', marginRight: '16px' },
  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  modalContainer: {
    minWidth: '583px',
  },
  label: {
    margin: '0 0 2px 0 !important',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  largeText: {
    fontSize: '16px',
  },
  title: {
    color: '#231E33',
    fontWeight: font.weight.medium,
    fontSize: '24px',
    lineHeight: 1.35,
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
  uploadButton: {
    padding: '8px 10px',
    fontSize: '16px',
  },
  errorMessage: {
    color: 'red',
  },
  successMessage: {
    color: 'green',
  },
});

const { Title } = Typography;
const { Option: SelectOption } = Select;
// function code
const CampaignModal = ({
  isEdit,
  visible,
  campaignId, // send id in case of edit.
  onCloseModal, // use this to close the model and refresh the states.
  onSubmit,
}) => {
  // states.
  const [form] = Form.useForm();
  const [uploadedFile, setUploadedFile] = useState();
  const [campaignInfo, setCampaignInfo] = useState({});
  const [planList, setPlanList] = useState([]);
  const [pharmacyList, setPharmacyList] = useState([]);
  const [specialNotesList, setSpecialNotesList] = useState([]);
  const [enrollerList, setEnrollerList] = useState([]);

  const [isIdentifiers, setIsIdentifiers] = useState(false);
  const [isUpload, setIsUpload] = useState(false);

  const apolloClient = useApolloClient();

  const { data: payerData } = useQuery(GET_PAYERS, {});
  const allPayers = get(payerData, 'payers.data');
  if (allPayers?.length && !allPayers?.some(payer => payer.name === 'ALL')) {
    allPayers.unshift({ name: 'ALL', id: '*' });
  }

  const [getCampaignInfo] = useLazyQuery(FIND_CAMPAIGN, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error Fetching Campaign Information: ${error.message}`);
    },
    onCompleted: ({ findCampaign }) => {
      const clonedData = cloneDeep(findCampaign?.data);
      if (clonedData?.campaign_pharmacies?.length > 0) {
        const assignedPharmacies = clonedData?.campaign_pharmacies.map(pharmacy => {
          return { label: pharmacy.name, value: pharmacy.id };
        });
        clonedData.pharmacy_ids = assignedPharmacies;
        setPharmacyList(assignedPharmacies);
      }
      if (clonedData?.plan_ids?.length > 0) {
        const assignedPlans = clonedData?.plan_ids.map(plan => {
          return { label: plan, value: plan };
        });
        clonedData.plan_ids = assignedPlans;
        setPlanList(assignedPlans);
      }
      setCampaignInfo(clonedData);
      form.resetFields();
    },
  });

  const fetchPlanOptions = async value => {
    const variables = {
      limit: 25,
      filter: value,
    };
    const { data } = await apolloClient.query({
      query: GET_PLANS_BY_FILTER,
      variables,
      fetchPolicy: 'network-only',
    });

    return data ? data?.getPlansByFilter?.data : [];
  };

  const fetchPharmacyOptions = async value => {
    let queryCheck;
    if (!isNaN(parseFloat(value))) {
      queryCheck = {
        npi: value,
      };
    } else {
      queryCheck = {
        name: { $ilike: `%${value}%` },
        status: { $ne: null },
      };
    }
    const variables = {
      skip: 0,
      limit: 25,
      query: queryCheck,
    };
    const { data } = await apolloClient.query({
      query: PHARMACY_NAMES,
      variables,
      fetchPolicy: 'network-only',
    });

    return data ? data?.pharmacyNames?.data : [];
  };

  const [getSpecialNotes] = useLazyQuery(GET_SPECIAL_NOTES, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error Fetching Special Notes Information: ${error.message}`);
    },
    onCompleted: ({ getSpecialNotesForCampaigns }) => {
      setSpecialNotesList(
        getSpecialNotesForCampaigns.map(note => {
          return { text: note, value: note };
        }),
      );
    },
  });

  const [getEnrollersList] = useLazyQuery(GET_ENROLLERS_FOR_CAMPAIGNS, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching enroller list: ${error.message}`);
    },
    onCompleted: ({ getEnrollersForCampaigns }) => {
      setEnrollerList(getEnrollersForCampaigns?.data);
    },
  });

  const [addCampaign, { loading: addingCampaign }] = useMutation(ADD_CAMPAIGN, {
    onError: error => {
      message.error(`Error adding Campaign: ${error.message}`);
    },
    onCompleted: () => {
      message.info(`Campaign Added Successfully.`);
      setIsIdentifiers(false);
      setUploadedFile(null);
      setIsUpload(false);
      onSubmit();
    },
  });

  const [updateCampaign, { loading: updatingCampaign }] = useMutation(UPDATE_CAMPAIGN, {
    onError: error => {
      message.error(`Error Updating Campaign: ${error.message}`);
    },
    onCompleted: ({ updateCampaign }) => {
      setCampaignInfo(updateCampaign?.data);
      form.resetFields();
      setIsIdentifiers(false);
      setUploadedFile(null);
      setIsUpload(false);
      message.info(`Campaign Updated Successfully.`);
      onSubmit();
    },
  });

  const fetchCampaignbyId = () => {
    if (campaignId) {
      const variables = { id: campaignId };

      getCampaignInfo({
        variables: {
          ...variables,
        },
      });
    }
  };

  const applyInputFilter = (input, option) => {
    return option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const onCancel = () => {
    setIsUpload(false);
    setIsIdentifiers(false);
    onCloseModal();
  };

  useEffect(() => {
    if (!isEdit) {
      setCampaignInfo({});
      form.resetFields();
    }
  }, [visible, isEdit]);

  useEffect(() => {
    if (isEdit) {
      fetchCampaignbyId();
    }
  }, [isEdit]);

  useEffect(() => {
    if (!isEmpty(campaignInfo)) {
      form.setFieldsValue(campaignInfo);
      checkForIdentifiers(campaignInfo);
    }
  }, [campaignInfo]);

  useEffect(() => {
    getSpecialNotes();
    getEnrollersList();
  }, []);

  const checkForIdentifiers = identifiers => {
    const { plan_ids, pharmacy_ids, special_notes, file_name } = identifiers;
    if (plan_ids?.length > 0 || pharmacy_ids?.length > 0 || special_notes?.length > 0) {
      setIsIdentifiers(true);
    } else if (file_name) {
      setIsUpload(true);
    } else {
      setIsIdentifiers(false);
    }
  };
  function DebounceSelect({ fetchOptions, debounceTimeout = 500, name, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
      const loadOptions = value => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        fetchOptions(value).then(newOptions => {
          if (fetchId !== fetchRef.current) {
            return;
          }
          const mappedNewOptions = cloneDeep(newOptions).map(option => {
            return {
              label: name === 'pharmacy' ? `${option.name} ${option.npi}` : option.id,
              value: option.id,
            };
          });
          setOptions(mappedNewOptions);
          setFetching(false);
        });
      };
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
        disabled={isUpload}
        key={`${name}-select`}
      />
    );
  }

  DebounceSelect.propTypes = {
    debounceTimeout: PropTypes.number,
    fetchOptions: PropTypes.func,
    onChange: PropTypes.func,
    name: PropTypes.string,
  };

  const submitForm = () => {
    const newFormValues = form.getFieldsValue();
    newFormValues.file = uploadedFile;
    if (newFormValues?.payer_ids?.includes('*')) {
      const payerIds = allPayers?.filter(payer => payer.id !== '*').map(payer => payer.id);
      newFormValues.payer_ids = payerIds;
    }
    if (newFormValues?.file?.status === 'removed') {
      delete newFormValues.file;
    }
    if (newFormValues?.plan_ids?.length > 0) {
      const cleanPlanIds = newFormValues?.plan_ids.map(plan => plan.value);
      newFormValues.plan_ids = cleanPlanIds;
    }
    if (newFormValues?.pharmacy_ids?.length > 0) {
      const cleanPharmacyIds = newFormValues?.pharmacy_ids.map(pharmacy => pharmacy.value);
      newFormValues.pharmacy_ids = cleanPharmacyIds;
    }
    form
      .validateFields()
      .then(() => {
        if (isEdit) {
          if (!campaignInfo.id) {
            throw new Error('Campaign Id is required.');
          } else {
            if (campaignInfo?.file_upload_status === 'fail' && !newFormValues.file) {
              message.error('Please upload a new file');
              return;
            }

            delete campaignInfo.file_name;
            delete campaignInfo.file_upload_message;
            delete campaignInfo.file_upload_status;
            delete campaignInfo.campaign_pharmacies;
            const FormValues = { ...campaignInfo, ...newFormValues };

            delete FormValues.id;
            delete FormValues.__typename;

            updateCampaign({
              variables: { id: campaignInfo.id, campaignModifiers: { ...FormValues } },
            });
          }
        } else {
          addCampaign({
            variables: { campaignModifiers: { ...newFormValues } },
          });
        }
      })
      .catch(info => {
        return info;
      });
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal
      onOk={() => submitForm()}
      onCancel={() => onCancel()}
      visible={visible}
      okText={`${isEdit ? 'Save' : 'Add Campaign'}`}
      className={css(sx.modalContainer)}
      footer={[
        <Button key="cancel" onClick={() => onCancel()} className={css(sx.button)}>
          Cancel
        </Button>,
        <Button
          loading={addingCampaign || updatingCampaign}
          key="submit"
          type="primary"
          onClick={() => submitForm()}
          className={css(sx.button)}
        >
          {isEdit ? 'Save' : 'Add Campaign'}
        </Button>,
      ]}
    >
      <Title className={css(sx.title)} level={3}>
        {isEdit ? 'Edit' : 'Add'} Campaign
      </Title>
      <Form
        name="modify-form-ref"
        form={form}
        layout="vertical"
        onFieldsChange={() => checkForIdentifiers(form.getFieldsValue())}
      >
        <div className={css(sx.flexContainer)}>
          <div className={css(sx.inputBox, sx.largeInputBox)}>
            <p className={css(sx.label)}>Campaign Name</p>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Campaign Name is required.' }]}
            >
              <Input className={css(sx.inputBox)} />
            </Form.Item>
          </div>
          <div className={css(sx.inputBox)}>
            <p className={css(sx.label)}>Status</p>
            <Form.Item name="status" rules={[{ required: true, message: 'Status is required.' }]}>
              <Select size="large" key="status-select">
                {statuses.map(status => (
                  <SelectOption value={status.value} key={status.value}>
                    {status.text}
                  </SelectOption>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div>
          <div>
            <p className={css(sx.label)}>Payer</p>
            <Form.Item name="payer_ids" rules={[{ required: true, message: 'Payer is required.' }]}>
              <Select mode="multiple" showArrow size="medium" key="client-select">
                {allPayers?.map(payer => (
                  <SelectOption value={payer.id} key={payer.id}>
                    {payer.name}
                  </SelectOption>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <p className={css(sx.label)}>PLAN ID</p>
            <Form.Item name="plan_ids">
              <DebounceSelect
                placeholder="Enter Plan ID"
                fetchOptions={fetchPlanOptions}
                mode="multiple"
                value={planList}
                onChange={newValue => setPlanList(newValue)}
                name="plan"
              />
            </Form.Item>
          </div>
          <div>
            <p className={css(sx.label)}>SPECIAL NOTES</p>
            <Form.Item name="special_notes">
              <Select
                mode="multiple"
                showArrow
                size="medium"
                key="notes-select"
                disabled={isUpload}
              >
                {specialNotesList?.map(notes => (
                  <SelectOption value={notes.value} key={notes.value}>
                    {notes.text}
                  </SelectOption>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <p className={css(sx.label)}>PHARMACY</p>
            <Form.Item name="pharmacy_ids">
              <DebounceSelect
                placeholder="Enter Pharmacy Name"
                fetchOptions={fetchPharmacyOptions}
                mode="multiple"
                value={pharmacyList}
                onChange={newValue => setPharmacyList(newValue)}
                name="pharmacy"
              />
            </Form.Item>
          </div>
          <div className={css(sx.largeInputBox)}>
            <p className={css(sx.label)}>Priority</p>
            <Form.Item name="priority">
              <Select size="large" key="priority-select">
                {priorities.map(priority => (
                  <SelectOption value={priority.value} key={priority.value}>
                    {priority.text}
                  </SelectOption>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <p className={css(sx.label)}>ASSIGNED ENROLLERS</p>
            <Form.Item name="assigned_enrollers">
              <Select
                mode="multiple"
                showArrow
                size="medium"
                key="enroller-select"
                filterOption={(input, value) => applyInputFilter(input, value)}
              >
                {enrollerList?.map(enroller => (
                  <SelectOption value={enroller.id} key={enroller.id}>
                    {`${enroller.first_name} ${enroller.last_name}`}
                  </SelectOption>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <p className={css(sx.label)}>Description</p>
            <Form.Item
              name="description"
              rules={[{ required: true, message: 'Description is required.' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </div>
          <div className={css(sx.label)}>
            FILE UPLOAD. CSV ONLY.
            <Upload
              name="file"
              key="file-upload"
              accept={'.csv'}
              multiple={false}
              beforeUpload={() => false}
              maxCount={'1'}
              onChange={({ file }) => {
                // onChange on upload triggers on both upload and delete (can differentiate using status)
                if (file?.status && file?.status === 'removed') {
                  setIsUpload(false);
                  setUploadedFile(null);
                } else {
                  setIsUpload(true);
                  setUploadedFile(file);
                }
              }}
              className={css(sx.uploadButton)}
            >
              <Button disabled={isIdentifiers} icon={<UploadOutlined />}>
                Click to Upload
              </Button>
            </Upload>
            <div>
              {isEdit && campaignInfo?.file_name !== null && campaignInfo?.file_name !== undefined
                ? `Existing File: ${campaignInfo.file_name}`
                : ''}
            </div>
            <div className={css(sx.errorMessage)}>
              {campaignInfo.file_upload_status === 'fail'
                ? `${campaignInfo.file_upload_message}`
                : ''}
            </div>
            <div className={css(sx.successMessage)}>
              {campaignInfo.file_upload_status === 'success' ||
              campaignInfo.file_upload_status === 'processing'
                ? `${campaignInfo.file_upload_message}`
                : ''}
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

CampaignModal.propTypes = {
  isEdit: PropTypes.bool,
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onSubmit: PropTypes.func,
  campaignId: PropTypes.any,
};

export default CampaignModal;
