import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import Sidebar from '../ui/Sidebar';
import SidebarItem from '../ui/SidebarItem';
import withRoles from '../hoc/withRoles';

const SettingsSidebar = ({
  location,
  onClickAccount,
  onClickHelp,
  onClickLogout,
  onClickPhones,
  onClickSupervisor,
  onClickVoicemail,
  onClickTeam,
  onClickPharmacyList,
  onClickPharmacyNetworksList,
  onClickZipcodeAssignment,
  onClickCampaigns,
  onClickEnrollersList,
  onClickNotesList,
  currentUser,
  hasRole: { pbsLead, pbsAdmin, pharmacyBusinessDev, pharmacyBusinessDevAdmin, pharmacyAscAdmin },
  isSystemAdmin,
  isPbsTeam,
  isPharmacyTeam,
  isPharmacistTeam,
  isProjectManager,
}) => {
  const callCenterFeatureFlag = get(currentUser, 'feature_flags.callCenter');

  return (
    <Sidebar title="Settings">
      <SidebarItem
        icon="personOutline"
        id="e2e-settingsSidebarAccessAcountsButton"
        onClick={onClickAccount}
        selected={location.pathname === '/settings/account'}
        text="My account"
      />
      <SidebarItem
        icon="people"
        onClick={onClickTeam}
        selected={location.pathname === '/settings/team'}
        text="Team"
      />
      {(isSystemAdmin || isPbsTeam) && (
        <SidebarItem
          icon="people"
          onClick={onClickEnrollersList}
          selected={location.pathname === '/settings/enrollerlist'}
          text="Enroller List"
        />
      )}
      {!isPharmacistTeam && !isProjectManager && (
        <SidebarItem
          icon="storefront"
          onClick={onClickPharmacyList}
          selected={location.pathname === '/settings/pharmacylist'}
          text="Pharmacies"
        />
      )}
      {(isSystemAdmin || pharmacyBusinessDev || pharmacyBusinessDevAdmin || isProjectManager) && (
        <SidebarItem
          icon="group"
          onClick={onClickPharmacyNetworksList}
          selected={location.pathname === '/settings/pharmacynetworkslist'}
          text="Pharmacy Networks"
        />
      )}
      {(isSystemAdmin || isPharmacyTeam) && (
        <SidebarItem
          icon="locationPin"
          onClick={onClickZipcodeAssignment}
          selected={location.pathname === '/settings/zipcodeassignment'}
          text="Zip Code Assignment"
        />
      )}
      {(isSystemAdmin ||
        pbsLead ||
        pbsAdmin ||
        pharmacyBusinessDevAdmin ||
        pharmacyAscAdmin ||
        isProjectManager) && (
        <SidebarItem
          icon="notification"
          onClick={onClickCampaigns}
          selected={location.pathname === '/settings/campaigns'}
          text="Campaigns"
        />
      )}
      {(isSystemAdmin || isPharmacyTeam) && (
        <SidebarItem
          icon="note"
          onClick={onClickNotesList}
          selected={location.pathname === '/settings/noteslist'}
          text="# Notes"
        />
      )}
      {currentUser && !callCenterFeatureFlag && (isPbsTeam || isSystemAdmin) && (
        <SidebarItem
          icon="telephone"
          iconProps={{ filled: false }}
          id="e2e-settingsSidebarAccessPhonesButton"
          onClick={onClickPhones}
          selected={location.pathname === '/settings/phones'}
          text="Phones"
        />
      )}
      {callCenterFeatureFlag && (isSystemAdmin || pbsLead || pbsAdmin) && (
        <SidebarItem
          icon="voicemail"
          onClick={onClickVoicemail}
          selected={location.pathname === '/settings/voicemail'}
          text="Missed Calls"
        />
      )}
      <SidebarItem
        icon="help"
        onClick={onClickHelp}
        selected={location.pathname === '/settings/help'}
        text="Help"
      />
      {callCenterFeatureFlag && (isSystemAdmin || pbsLead || pbsAdmin) && (
        <SidebarItem
          icon="person"
          onClick={onClickSupervisor}
          selected={location.pathname === '/settings/supervisor'}
          text="Supervise Calls"
        />
      )}
      <SidebarItem
        icon="signOut"
        id="e2e-settingsSidebarSignOutButton"
        onClick={onClickLogout}
        text="Sign out"
      />
    </Sidebar>
  );
};

SettingsSidebar.propTypes = {
  location: PropTypes.object,
  onClickAccount: PropTypes.func,
  onClickHelp: PropTypes.func,
  onClickLogout: PropTypes.func,
  onClickPhones: PropTypes.func,
  onClickSupervisor: PropTypes.func,
  onClickPharmacyList: PropTypes.func,
  onClickPharmacyNetworksList: PropTypes.func,
  onClickZipcodeAssignment: PropTypes.func,
  onClickCampaigns: PropTypes.func,
  onClickEnrollersList: PropTypes.func,
  onClickNotesList: PropTypes.func,
  currentUser: PropTypes.shape({
    feature_flags: PropTypes.object,
  }),
  hasRole: PropTypes.object,
  isSystemAdmin: PropTypes.bool,
  isPbsTeam: PropTypes.bool,
  isPharmacyTeam: PropTypes.bool,
  isPharmacistTeam: PropTypes.bool,
  onClickVoicemail: PropTypes.func,
  onClickTeam: PropTypes.func,
  isProjectManager: PropTypes.bool,
};

export default withRoles(SettingsSidebar);
