import { colors } from '@zipdrug/ui';
import { css, StyleSheet } from 'aphrodite/no-important';
import { compact } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import PatientListEmptyState from './PatientListEmptyState';
import PatientListItemContainer from './PatientListItemContainer';
import './PatientList.css';

const sx = StyleSheet.create({
  list: {
    backgroundColor: colors.gray20,
    outline: 'none',
  },
});

export default class PatientList extends Component {
  static propTypes = {
    filterCount: PropTypes.number,
    isLoading: PropTypes.bool,
    loadMoreRows: PropTypes.func,
    onSelectPatient: PropTypes.func.isRequired,
    patients: PropTypes.arrayOf(PropTypes.object),
    search: PropTypes.string,
    selectedPatientId: PropTypes.number,
    showSnoozed: PropTypes.bool,
    totalPatients: PropTypes.number,
  };

  componentWillReceiveProps = ({ isLoading, patients, selectedPatientId, totalPatients }) => {
    const didStopLoading = !isLoading && this.props.isLoading;
    const isNewTotalPatientsValue = totalPatients !== this.props.totalPatients;
    const isNewSelectedPatientId = selectedPatientId !== this.props.selectedPatientId;

    if (didStopLoading && !selectedPatientId) {
      // If there is no patient selected, select the first one
      this.handleSelectPatient(patients[0]);
    }

    // Force refresh of list
    this.handleForceUpdate([didStopLoading, isNewSelectedPatientId, isNewTotalPatientsValue]);
  };

  handleIsRowLoaded = ({ index }) => !!this.props.patients[index];
  handleListItemClick = (selectedPatient = {}) => this.handleSelectPatient(selectedPatient);

  handleListRef = ref => {
    this.listRef = ref;
    this.registerList(ref);
  };

  handleSelectPatient = newSelectedPatient => {
    if (!newSelectedPatient) return;

    const { onSelectPatient, selectedPatientId } = this.props;
    if (selectedPatientId !== newSelectedPatient.id) {
      onSelectPatient(newSelectedPatient.id);
    }
  };

  handleForceUpdate = conditions =>
    compact(conditions).length ? this.listRef.forceUpdateGrid() : null;

  renderEmptyState = () => {
    const { filterCount, isLoading, search, showSnoozed } = this.props;

    if (isLoading) return null;

    let type = 'inbox';
    if (!!search || filterCount) type = 'search';
    else if (showSnoozed) type = 'snoozed';

    return <PatientListEmptyState type={type} />;
  };

  renderListItem = ({ index, key, style, ...rest }) => {
    const patient = this.props.patients[index];
    return patient ? (
      <PatientListItemContainer
        {...rest}
        key={`${key}-${patient.id}`}
        loading={this.props.isLoading}
        onClick={this.handleListItemClick}
        patient={patient}
        selected={patient.id === this.props.selectedPatientId}
        style={style}
      />
    ) : null;
  };

  render = () => (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isRowLoaded={this.handleIsRowLoaded}
          loadMoreRows={this.props.loadMoreRows}
          rowCount={this.props.totalPatients}
        >
          {({ onRowsRendered, registerChild }) => {
            this.registerList = registerChild;
            return (
              <List
                className={css(sx.list)}
                height={height}
                id="patientList"
                noRowsRenderer={this.renderEmptyState}
                onRowsRendered={onRowsRendered}
                ref={this.handleListRef}
                rowCount={this.props.patients.length}
                rowHeight={72}
                rowRenderer={this.renderListItem}
                tabIndex={-1}
                width={width}
              />
            );
          }}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}
