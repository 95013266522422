import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Typography, Button } from 'antd';

const sx = StyleSheet.create({
  modalContainer: {
    minWidth: '579px',
    minHeight: '252px',
  },
  description: {
    height: '99px',
    width: '539px',
    fontSize: '16px',
  },
  buttonCancel: {
    width: '54px',
    fontSize: '16px',
  },
  buttonOk: {
    width: '80px',
    fontSize: '16px',
  },
});

const { Title, Text } = Typography;

const DeleteModal = ({
  type,
  selectedTag,
  onDeleteOk,
  onDeleteCancel,
  visible,
  rowData,
  patientCount,
}) => {
  const checkAssignedPharmacies = () => {
    let hasAnthemOrAlohacare = false;
    if (rowData?.anthemAssignedPharmacies?.filter(p => p.id === selectedTag?.id).length) {
      hasAnthemOrAlohacare = true;
    }
    if (rowData?.alohacareAssignedPharmacies?.filter(p => p.id === selectedTag?.id).length) {
      hasAnthemOrAlohacare = true;
    }
    return hasAnthemOrAlohacare;
  };

  return (
    <Modal
      onCancel={onDeleteCancel}
      visible={visible}
      className={css(sx.modalContainer)}
      footer={[
        <Button key="camcel" onClick={onDeleteCancel} className={css(sx.buttonCancel)}>
          No
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => onDeleteOk(type, selectedTag)}
          className={css(sx.buttonOk)}
        >
          Yes
        </Button>,
      ]}
    >
      <Title className="modelHeaderText" level={3}>
        Delete {type}
      </Title>
      <div className={css(sx.description)}>
        {type === 'Potential Pharmacy' ? (
          <Text>
            {checkAssignedPharmacies()
              ? `Deleting this potential pharmacy will also delete it as an assigned pharmacy for zipcode ${rowData.postal_code}. Removing the assigned pharmacy may affect ${patientCount} members. Do you still want to remove it?`
              : 'Are you sure you want to delete this pharmacy?'}
          </Text>
        ) : (
          <Text>
            Removing this pharmacy may affect {patientCount} members. Are you sure you want to
            delete this pharmacy?
          </Text>
        )}
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  type: PropTypes.string,
  rowData: PropTypes.object,
  selectedTag: PropTypes.object,
  onDeleteOk: PropTypes.func,
  onDeleteCancel: PropTypes.func,
  visible: PropTypes.bool,
  patientCount: PropTypes.number,
};

export default DeleteModal;
