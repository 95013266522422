import { border, colors, Div, font, padding, position, rem, space, unit } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';
import stylePropType from 'react-style-proptype';
import PatientListItemHeader from './PatientListItemHeader';
import PatientListItemMetaSection from './PatientListItemMetaSection';

export const PatientListItemClassName = 'js-patientListItem';

const AlertDotSize = '0.6rem';

const ListItemPadding = {
  horizontal: space.default,
  vertical: font.size.large,
};

const sx = StyleSheet.create({
  alertContainer: {
    ...padding.build(0, ListItemPadding.horizontal),
    bottom: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: rem(unit(AlertDotSize) + unit(ListItemPadding.horizontal) * 2),
  },
  alertDot: {
    ...border.circle,
    ...position.absCenterVertical,
    ...position.size(AlertDotSize),
    backgroundColor: colors.red,
  },
  borderWrapper: {
    ...border.build('bottom', { color: colors.gray24 }),
    padding: '1rem 0',
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  container: {
    ...padding.build(0, ListItemPadding.horizontal),
    backgroundColor: colors.darkBlue,
    cursor: 'pointer',
    position: 'relative',

    ':hover': {
      backgroundColor: colors.bluePurple,
    },
  },
  containerLoading: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  containerSelected: {
    backgroundColor: colors.bluePurple,
  },
});

const PatientListItem = ({ loading, onClick, patient, selected, style, styles }) => {
  const { latest_alert_at, snoozed_until } = patient;
  const showAlert = latest_alert_at && !loading && !snoozed_until;

  const childProps = {
    loading,
    patient,
    skeletonStyles: sx.skeleton,
  };

  return (
    <Div
      className={PatientListItemClassName}
      onClick={onClick}
      style={style}
      styles={[
        sx.container,
        loading ? sx.containerLoading : null,
        selected ? sx.containerSelected : null,
        styles,
      ]}
    >
      <Div styles={sx.borderWrapper}>
        <PatientListItemHeader {...childProps} />
        <PatientListItemMetaSection {...childProps} />
      </Div>
      {showAlert && (
        <Div styles={sx.alertContainer} title="Patient has unread alerts">
          <Div styles={sx.alertDot} />
        </Div>
      )}
    </Div>
  );
};

PatientListItem.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  patient: PropTypes.object,
  selected: PropTypes.bool,
  style: stylePropType,
  styles: aphroditePropType,
};

export default PatientListItem;
