import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Checkbox, Modal, Typography } from 'antd';

const sx = StyleSheet.create({
  flexContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '15px',
  },
  pharmacyListContainer: {
    display: 'flex',
    width: '100%',
    margin: '0',
  },
  pharmacyListItem: {
    marginBottom: '10px',
    display: 'block',
    ':last-child': {
      marginBottom: 0,
    },
  },
  pharmacyNpi: {
    fontSize: '11px',
    marginLeft: '25px',
  },
});

const { Text, Title, Paragraph } = Typography;

const SavePharmacyModal = ({ savePharmacyInfo, savePharmacyCancel, pharmacyInfo, visible }) => {
  const childNpis = pharmacyInfo?.child_pharmacies?.map(chain => chain.npi);

  const updateNpi = (addNpi, childNpi) => {
    if (addNpi) {
      if (childNpis.indexOf(childNpi) === -1) {
        childNpis.push(childNpi);
      }
    } else {
      const index = childNpis.indexOf(childNpi);
      if (index > -1) {
        childNpis.splice(index, 1);
      }
    }
  };

  return (
    <Modal
      onOk={() => savePharmacyInfo('status', childNpis)}
      onCancel={() => savePharmacyCancel('status')}
      visible={visible}
      okText="Save"
    >
      <Title level={3}>Status Change</Title>
      <div className={css(sx.flexContainer)}>
        <div>
          <Text className={css(sx.cellText)}>
            Click &apos;Save&apos; to change this hub pharmacy and all child pharmacy&apos;s
            statuses. You can deselect any child pharmacies you do not want to change.
          </Text>
        </div>
      </div>

      <div className={css(sx.pharmacyListContainer)}>
        {pharmacyInfo?.child_pharmacies && (
          <div>
            {pharmacyInfo?.child_pharmacies.map(childPharmacy => (
              <div className={css(sx.pharmacyListItem)}>
                <Checkbox
                  defaultChecked
                  onChange={e => updateNpi(e?.target?.checked, childPharmacy.chain_pharmacy.npi)}
                >
                  <Text className={css(sx.pharmacyName)}>
                    {' '}
                    {`${childPharmacy.chain_pharmacy.name}, ${childPharmacy.chain_pharmacy.address.state} Pharmacy`}
                  </Text>
                </Checkbox>
                <Paragraph className={css(sx.pharmacyNpi)}>
                  {' '}
                  {childPharmacy.chain_pharmacy.npi}
                </Paragraph>
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

SavePharmacyModal.propTypes = {
  savePharmacyInfo: PropTypes.func,
  savePharmacyCancel: PropTypes.func,
  pharmacyInfo: PropTypes.object,
  visible: PropTypes.bool,
};

export default SavePharmacyModal;
