import {
  border,
  colors,
  Div,
  font,
  FullName,
  position,
  rem,
  Row,
  Skeleton,
  unit,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';

const HEADER_FONT_SIZE = font.size.small;
const SKELETON_MIN_WIDTH = 12;

const sx = StyleSheet.create({
  container: {
    color: colors.white,
    fontSize: HEADER_FONT_SIZE,
    minHeight: HEADER_FONT_SIZE,
    width: '100%',
  },
  tag: {
    marginLeft: rem(unit(HEADER_FONT_SIZE) / 2),
  },
  taskIndicator: {
    ...border.circle,
    ...position.size(rem(unit(HEADER_FONT_SIZE) / 2)),
    backgroundColor: colors.red,
  },
});

const PatientListItemHeader = ({ loading, patient }) => {
  const { hasTasks } = patient;
  const skeletonMinWidth = () => SKELETON_MIN_WIDTH * Math.random() * 2;

  return (
    <Div styles={sx.container}>
      {loading ? (
        <Skeleton
          color={colors.gray27}
          style={{ minWidth: rem(SKELETON_MIN_WIDTH) }}
          width={rem(skeletonMinWidth())}
        />
      ) : (
        <Row align="center">
          <Row align="center" style={{ width: '100%' }}>
            <FullName user={patient} />
          </Row>
          {hasTasks && <Div styles={sx.taskIndicator} />}
        </Row>
      )}
    </Div>
  );
};

PatientListItemHeader.propTypes = {
  loading: PropTypes.bool,
  patient: PropTypes.object,
};

export default PatientListItemHeader;
