export const Role = {
  PbsUser: 'pbs_user',
  PbsLead: 'pbs_lead',
  PbsAdmin: 'pbs_admin',
  PharmacyBusinessDev: 'pharmacy_business_dev',
  PharmacyBusinessDevAdmin: 'pharmacy_business_dev_admin',
  PharmacyAsc: 'pharmacy_asc',
  PharmacyAscAdmin: 'pharmacy_asc_admin',
  PharmacistUser: 'pharmacist_user',
  PharmacistAdmin: 'pharmacist_admin',
  SystemAdmin: 'system_admin',
  ProjectManager: 'project_manager',
};

export const AdminRoles = [
  Role.PbsAdmin,
  Role.PharmacyBusinessDevAdmin,
  Role.PharmacyAscAdmin,
  Role.PharmacistAdmin,
  Role.SystemAdmin,
];
export const isSystemAdminOrAscRoles = [Role.SystemAdmin, Role.PharmacyAscAdmin, Role.PharmacyAsc];
export const PBSTeamRoles = [Role.PbsUser, Role.PbsLead, Role.PbsAdmin];
export const PharmacyTeamRoles = [
  Role.PharmacyBusinessDev,
  Role.PharmacyBusinessDevAdmin,
  Role.PharmacyAsc,
  Role.PharmacyAscAdmin,
];
export const PharmacistRoles = [Role.PharmacistUser, Role.PharmacistAdmin];

export const BdRoles = [Role.PharmacyBusinessDev, Role.PharmacyBusinessDevAdmin];
export const AscRoles = [Role.PharmacyAsc, Role.PharmacyAscAdmin];

export const ManagerRoles = [Role.ProjectManager];

export const AllRoles = Object.values(Role);

export const UserTypesMapping = {
  system_admin: 'System Admin',
  pbs_user: 'PBS User',
  pbs_lead: 'PBS Lead',
  pbs_admin: 'PBS Admin',
  pharmacy_business_dev: 'Pharmacy Business Dev',
  pharmacy_business_dev_admin: 'Pharmacy Business Dev Admin',
  pharmacy_asc: 'Pharmacy ASC',
  pharmacy_asc_admin: 'Pharmacy ASC Admin',
  pharmacist_user: 'Pharmacist User',
  pharmacist_admin: 'Pharmacist Admin',
  project_manager: 'Project Manager',
};

export const ExtUserTypesMapping = {
  pharmacist_user: 'Pharmacist User',
  pharmacist_admin: 'Pharmacist Admin',
};

export const getCurrentUserRole = user => user?.roles[0]?.type;
export const isRole = (user, roleType) =>
  !!(user && user.roles.find(r => roleType.indexOf(r.type) > -1));
export const isSystemAdmin = user => isRole(user, [Role.SystemAdmin]);
export const isAdmin = user => isRole(user, AdminRoles);
export const isPbsTeam = user => isRole(user, PBSTeamRoles);
export const isPharmacyTeam = user => isRole(user, PharmacyTeamRoles);
export const isPharmacistTeam = user => isRole(user, PharmacistRoles);
export const isBdTeam = user => isRole(user, BdRoles);
export const isAscTeam = user => isRole(user, AscRoles);
export const isSystemAdminOrAsc = user => isRole(user, isSystemAdminOrAscRoles);
export const isProjectManager = user => isRole(user, ManagerRoles);

export const LanguageType = {
  English: 'english',
  Spanish: 'spanish',
};

export const MeasureType = {
  Cholesterol: 'cholesterol',
  Diabetes: 'diabetes',
  Hypertension: 'hypertension',
};
