import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { searchPatients } from '../../redux/queryReducer';
import PatientListHeader from './PatientListHeader';

const mapDispatchToProps = dispatch => ({
  onClearFilters() {
    dispatch(reset('filter'));
  },
  onSearch(search) {
    dispatch(searchPatients({ search }));
  },
});

export default compose(
  connect(null, mapDispatchToProps),
  withHandlers({
    onSearch: ({ onSearch }) => (_, value) => {
      onSearch(value);
    },
  }),
)(PatientListHeader);
