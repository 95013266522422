import { font } from '@zipdrug/ui';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Select, Typography } from 'antd';
import percentAllocations from './constants/constants';

const sx = StyleSheet.create({
  modalTitle: {
    color: '#231E33',
    fontWeight: font.weight.medium,
    fontSize: '24px',
    lineHeight: 1.35,
  },
  cellText: {
    color: '#231E33',
  },
  title: {
    fontWeight: 'bold',
    color: '#231E33',
  },
  dropdownFlexContainer: {
    display: 'flex',
    marginTop: '20px',
    width: '100%',
  },
  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  halfSplitLeft: {
    width: '50%',
  },
  halfSplitRight: {
    width: '50%',
    marginLeft: '150px',
  },
  fullWidth: {
    width: '100%',
  },
  error: {
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#B50D0D',
  },
});

const { Text, Title } = Typography;
const { Option } = Select;

const CampaignPriorityModal = ({
  saveCampaignWeight,
  onCloseModal,
  visible,
  originalPriorityAllocationObj,
}) => {
  const [priorityAllocationObj, setPriorityAllocationObj] = useState([]);
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  useEffect(() => {
    setPriorityAllocationObj([...originalPriorityAllocationObj]);
  }, [originalPriorityAllocationObj]);

  const onChange = (field, value) => {
    const newPriorityAllocationObj = [...priorityAllocationObj];
    const priorityAllocationEdited = newPriorityAllocationObj.map(e => {
      if (e.weight === field) {
        return {
          weight: field,
          percent_allocation: value,
        };
      }
      return e;
    });
    setPriorityAllocationObj(priorityAllocationEdited);
  };

  const validateForm = async () => {
    let total = 0;
    priorityAllocationObj.forEach(p => {
      total += p.percent_allocation;
    });
    if (total <= 100) {
      setShowErrorMsg(false);
      return false;
    }
    setShowErrorMsg(true);
    return true;
  };

  const onSubmit = async () => {
    const hasError = await validateForm();
    if (!hasError) {
      saveCampaignWeight(priorityAllocationObj);
    }
  };

  const onClose = async () => {
    setShowErrorMsg(false);
    setPriorityAllocationObj(originalPriorityAllocationObj);
    onCloseModal();
  };

  return (
    <Modal onOk={onSubmit} onCancel={onClose} visible={visible} okText="Save">
      <Title className={css(sx.modalTitle)} level={3}>
        Set Campaign Priority Allocation
      </Title>
      <div className={css(sx.flexContainer)}>
        <div>
          <Text className={css(sx.cellText)}>
            Allocate the maximum percentage of available leads to be filled with each campaign
            priority weight. The total must be less than or equal to 100 percent.
          </Text>
        </div>
      </div>

      <div className={css(sx.dropdownFlexContainer)}>
        <div className={css(sx.halfSplitLeft)}>
          <Text className={css(sx.title)}>Campaign Weight</Text>
        </div>
        <div className={css(sx.halfSplitRight)}>
          <Text className={css(sx.title)}>Max Percent Allocation</Text>
        </div>
      </div>

      {priorityAllocationObj.map(p => {
        return (
          <div className={css(sx.dropdownFlexContainer)}>
            <div className={css(sx.halfSplitLeft)}>
              {' '}
              <Text className={css(sx.cellText)}>
                {p.weight} {p.weight === 5 && ` - Highest`} Priority
              </Text>{' '}
              <br />
            </div>
            <div className={css(sx.halfSplitRight)}>
              <Select
                className={css(sx.fullWidth)}
                value={p.percent_allocation}
                onChange={value => {
                  onChange(p.weight, value);
                }}
              >
                {percentAllocations.map(p => {
                  return (
                    <Option value={p.value} key={p.value}>
                      {p.text}
                    </Option>
                  );
                })}
              </Select>
              <br />
            </div>
          </div>
        );
      })}

      <div className={css(sx.dropdownFlexContainer)}>
        <div className={css(sx.halfSplitLeft)}>
          <Text className={css(sx.cellText)}>
            0 - Normal Priority and non-campaign assigned leads
          </Text>
        </div>
        <div className={css(sx.halfSplitRight)}>
          <Text className={css(sx.cellText)}>All Remaining Leads</Text>
          <br />
          {showErrorMsg && (
            <Text className={css(sx.error)}>
              {'total percent allocation should be less than or equal to 100'}
            </Text>
          )}
        </div>
      </div>
    </Modal>
  );
};

CampaignPriorityModal.propTypes = {
  saveCampaignWeight: PropTypes.func,
  onCloseModal: PropTypes.func,
  originalPriorityAllocationObj: PropTypes.array,
  visible: PropTypes.bool,
};

export default CampaignPriorityModal;
