import React, { Component } from 'react';
import { colors, Div, font, Icon, padding, position, Row, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';

const sx = StyleSheet.create({
  border: {
    backgroundColor: colors.theme.primary.default,
    bottom: 0,
    left: 0,
    position: 'absolute',
    top: 0,
    width: '0.4rem',
  },
  container: {
    ...padding.build('0.6rem', space.get(1.5)),
    color: colors.gray57,
    cursor: 'pointer',
    marginBottom: font.size.medium,
    position: 'relative',
    userSelect: 'none',

    ':hover': {
      color: colors.gray20,
    },
  },
  icon: {
    ...position.size(font.size.large),
    marginRight: font.size.small,
  },
  iconSelected: {
    color: colors.theme.primary.default,
  },
  text: {
    color: 'inherit',
    fontSize: font.size.medium,
  },
  textSelected: {
    color: colors.gray20,
  },
});

export default class SidebarItem extends Component {
  static propTypes = {
    icon: Icon.propTypes.name,
    iconProps: PropTypes.object,
    id: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    text: PropTypes.string.isRequired,
  };

  state = { isActive: false };
  isSelected = this.state.isActive || this.props.selected;

  handleMouseDown = () => this.setState({ isActive: true });
  handleMouseUp = () => this.setState({ isActive: false });

  render = () => (
    <Div
      id={this.props.id}
      onClick={this.props.onClick}
      onMouseDown={this.handleMouseDown}
      onMouseUp={this.handleMouseUp}
      styles={sx.container}
    >
      <Row align="center">
        <Icon
          {...this.props.iconProps}
          name={this.props.icon}
          styles={[sx.icon, this.isSelected ? sx.iconSelected : null]}
        />
        <Div styles={[sx.text, this.isSelected ? sx.textSelected : null]}>{this.props.text}</Div>
      </Row>
      <Div style={{ opacity: this.props.selected ? 1 : 0 }} styles={sx.border} />
    </Div>
  );
}
