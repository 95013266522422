import { StyleSheet } from 'aphrodite/no-important';
import { get, lowerCase, startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Flex, Div, Bold, colors, font, space, Span } from '@zipdrug/ui';
import SystemEventActivityItem from './SystemEventActivityItem';

const sx = StyleSheet.create({
  content: {
    lineHeight: font.lineHeight.looser,
    paddingTop: space.half,
  },
  footer: {
    marginTop: 0,
  },
  header: {
    color: colors.orange,
  },
  marginBottom: {
    marginBottom: 10,
  },
});

export default class EnrollmentActivityItem extends Component {
  static propTypes = {
    activity: PropTypes.object,
    enroll_status: PropTypes.string.isRequired,
  };

  renderHeading = () => {
    const status = (
      <Bold className="e2e-activityListItem-enrollmentStatus">
        {startCase(lowerCase(this.props.enroll_status))}
      </Bold>
    );

    return <Span>Enrollment status changed to {status}.</Span>;
  };

  renderContent = () => {
    const category = this.props.activity.enrollStatusOutcome?.enroll_status_outcome_reason[0]
      ?.outcome_type.outcome;
    const getReasons = () => {
      const reasonMap = this.props.activity.enrollStatusOutcome?.enroll_status_outcome_reason?.map(
        r => r.outcome_type.outcome_reason,
      );
      return reasonMap?.join('; ');
    };
    const notes = this.props.activity.enrollStatusOutcome?.notes;
    return (
      <blockquote>
        <Flex direction="column">
          <Div styles={sx.marginBottom}>
            <Bold>CATEGORY</Bold>
            <br />
            {category}
          </Div>
          <Div styles={sx.marginBottom}>
            <Bold>REASONS</Bold>
            <br />
            {getReasons()}
          </Div>
          <Div>
            <Bold>NOTES</Bold>
            <br />
            {notes || '-'}
          </Div>
        </Flex>
      </blockquote>
    );
  };

  render = () => {
    const { activity, ...props } = this.props;
    const {
      created_by_user,
      new_fields: { enroll_status_reasons },
    } = get(activity, 'model_update');
    return (
      <SystemEventActivityItem
        {...props}
        content={
          activity.enrollStatusOutcome ? this.renderContent() : enroll_status_reasons || null
        }
        contentHeading={enroll_status_reasons ? 'Reason' : null}
        contentStyles={sx.content}
        createdAt={activity.created_at}
        footerName={created_by_user}
        footerStyles={enroll_status_reasons ? null : sx.footer}
        headerStyles={sx.header}
        heading={this.renderHeading()}
        icon="person"
      />
    );
  };
}
