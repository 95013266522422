import { LinkDropdown } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import PatientInfoDropdownButton from '../PatientInfoDropdownButton';
import PharmacyMenu from './PharmacyMenu';
import PharmacyFormContainer from './PharmacyFormContainer';

const sx = StyleSheet.create({
  menuStyles: {
    left: '50%',
    right: 'initial',
    transform: 'translateX(-50%)',
  },
  title: {
    maxWidth: '25rem',
  },
});

const PharmacyDropdown = ({ isEditing, onEdit, onToggleDropdown, patient }) => {
  if (!patient) return null;

  const menu = isEditing ? (
    <PharmacyFormContainer onToggleEdit={onEdit} />
  ) : (
    <PharmacyMenu onToggleEdit={onEdit} pharmacy={get(patient, 'assigned_pharmacy')} />
  );

  return (
    <LinkDropdown
      buttonRenderer={
        <PatientInfoDropdownButton
          id="e2e-pharmacyDropdownNameDropdown"
          icon="storefront"
          text={get(patient, 'assigned_pharmacy.name', 'Pharmacy unavailable')}
          title="View patient's preferred pharmacy"
        />
      }
      menuRenderer={menu}
      menuStyles={sx.menuStyles}
      onToggle={onToggleDropdown}
    />
  );
};

PharmacyDropdown.propTypes = {
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func,
  onToggleDropdown: PropTypes.func,
  patient: PropTypes.object,
};

export default compose(
  withState('isEditing', 'toggleIsEditing', false),
  withHandlers({
    onEdit: ({ toggleIsEditing }) => (_, forceResetEditState) =>
      toggleIsEditing(isEditing => (forceResetEditState ? false : !isEditing)),
  }),
  withHandlers({
    onToggleDropdown: ({ onEdit }) => isOpen => {
      if (!isOpen) {
        onEdit(null, true);
      }
    },
  }),
)(PharmacyDropdown);
