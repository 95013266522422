import { border, Button, colors, padding, position, transition } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';

export const FilterButtonId = 'js-toggleFilterPanelButton';

const BUTTON_ACTIVE_STYLES = {
  backgroundColor: colors.bluePurple,
  borderColor: colors.bluePurple,
  color: colors.white,
};

const sx = StyleSheet.create({
  button: {
    ...border.build('', { color: colors.gray27 }),
    ...padding.build(0),
    ...position.size('3.2rem'),
    ...transition.build(),
    backgroundColor: colors.gray20,
    borderRadius: '0.4rem',
    color: colors.gray48,

    ':hover': {
      backgroundColor: colors.gray48,
      borderColor: colors.gray48,
      color: colors.gray20,
    },
    ':active': BUTTON_ACTIVE_STYLES,
  },
  buttonActive: {
    ...BUTTON_ACTIVE_STYLES,
    ':hover': BUTTON_ACTIVE_STYLES,
  },
});

const FilterButton = ({ isActive, ...props }) => (
  <Button
    {...props}
    icon="filter"
    id={FilterButtonId}
    outline
    styles={[sx.button, isActive ? sx.buttonActive : null]}
    title="Show filters"
    type="default"
  />
);

FilterButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default FilterButton;
