import React, { useState, useEffect, useRef, useCallback } from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Typography, Input, Button, message, Spin } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';

const { Search } = Input;

const sx = StyleSheet.create({
  largeInputBox: { width: '50%' },
  inputBox: { fontSize: '16px', color: '#979797' },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '208px',
    height: '40px',
  },
  modalContainer: {
    minWidth: '579px',
  },
  zipcount: {
    margin: '17px 0 17px 0',
    fontSize: '11px',
  },
  scrollable: {
    height: 400,
    overflowY: 'auto',
  },
  list: {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
  listContainer: {
    height: 400,
  },
  listItem: {
    height: 20,
  },
});

const { Title } = Typography;

const ShowMore = ({
  title,
  visible,
  onModifyCancel,
  codes,
  zipCodesCount,
  isPotentialZipcodes,
  pharmacyId,
}) => {
  const [zipCodes, setZipCodes] = useState(codes);
  const [uniqueZipCodes, setUniqueZipcodes] = useState([...new Set(codes)]);
  const [loaded, setLoaded] = useState(false);
  const [filteredByZipcode, setFilteredByZipcode] = useState(null);
  const [filteredZipcodes, setFilteredZipcodes] = useState([]);

  const [loadingMoreZipCodes, setLoadingMoreZipCodes] = useState(true);
  const [hasMoreZipCodes, setHasMoreZipCodes] = useState(false);
  const [skipNumber, setSkipNumber] = useState(codes?.length);

  // callback updates the skipNumber when the last item of the zipcodes list is visible onto the screen
  const observer = useRef();
  const lastZipCodeElementRef = useCallback(
    node => {
      if (loadingMoreZipCodes) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreZipCodes) {
          setSkipNumber(zipCodes?.length);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMoreZipCodes, zipCodes],
  );

  const SeachZipcodes = value => {
    setFilteredByZipcode(value);
  };

  useEffect(() => {
    if (filteredByZipcode) {
      const variables = {
        variables: {
          query: {
            pharmacy_id: pharmacyId,
          },
        },
      };
      if (isPotentialZipcodes) {
        variables.variables.query.potential_postal_code = { $ilike: `${filteredByZipcode}%` };
        getMorePotentialZipcodes(variables);
      } else {
        variables.variables.query.postal_code = { $ilike: `${filteredByZipcode}%` };
        getMoreZipcodes(variables);
      }
    }
  }, [filteredByZipcode]);

  const [getMoreZipcodes, { loading: isFetchingZipCodes }] = useLazyQuery(
    gql`
      query postalCodes($limit: Int, $query: JSON, $skip: Int) {
        postalCodes(limit: $limit, query: $query, skip: $skip) {
          data {
            postal_code
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onError: error => {
        message.error(`Error Fetching zip code coverage: ${error.message}`);
      },
      onCompleted: ({ postalCodes }) => {
        const postalCodeData = postalCodes?.data?.map(p => p?.postal_code);
        if (postalCodes?.data && !filteredByZipcode) {
          setZipCodes(prevZipCodes => {
            return [...prevZipCodes, ...postalCodeData];
          });
          setUniqueZipcodes(prevUniqueZipCodes => {
            return [...new Set([...prevUniqueZipCodes, ...postalCodeData])];
          });
          setHasMoreZipCodes(postalCodes?.data?.length > 0);
          setLoadingMoreZipCodes(false);
        } else if (postalCodes?.data && filteredByZipcode) {
          setFilteredZipcodes([...new Set(postalCodeData)]);
        }
      },
    },
  );

  const [getMorePotentialZipcodes, { loading: isFetchingPotentialZipCodes }] = useLazyQuery(
    gql`
      query potentialPostalCodes($limit: Int, $query: JSON, $skip: Int) {
        potentialPostalCodes(limit: $limit, query: $query, skip: $skip) {
          data {
            potential_postal_code
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onError: error => {
        message.error(`Error Fetching potential zip codes: ${error.message}`);
      },
      onCompleted: ({ potentialPostalCodes }) => {
        const potentialPostalCodeData = potentialPostalCodes?.data?.map(
          p => p?.potential_postal_code,
        );
        if (potentialPostalCodes?.data && !filteredByZipcode) {
          setZipCodes(prevZipCodes => {
            return [...prevZipCodes, ...potentialPostalCodeData];
          });
          setUniqueZipcodes(prevUniqueZipCodes => {
            return [...new Set([...prevUniqueZipCodes, ...potentialPostalCodeData])];
          });
          setHasMoreZipCodes(potentialPostalCodes?.data?.length > 0);
          setLoadingMoreZipCodes(false);
        } else if (potentialPostalCodes?.data && filteredByZipcode) {
          setFilteredZipcodes([...new Set(potentialPostalCodeData)]);
        }
      },
    },
  );

  useEffect(() => {
    if (zipCodes?.length > 0) {
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    const variables = {
      variables: {
        limit: 20,
        query: { pharmacy_id: pharmacyId },
        skip: zipCodes?.length,
      },
    };
    setLoadingMoreZipCodes(true);
    if (isPotentialZipcodes) {
      getMorePotentialZipcodes(variables);
    } else {
      getMoreZipcodes(variables);
    }
  }, [skipNumber]);

  const onChangeOfZipCodeSearch = value => {
    if (value === '') {
      setFilteredByZipcode(null);
      setFilteredZipcodes([]);
    }
  };

  const onCancel = () => {
    setFilteredByZipcode(null);
    setZipCodes(codes);
    onModifyCancel();
  };

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      className={css(sx.modalContainer)}
      getContainer={false}
      footer={[
        <Button key="cancel" onClick={onCancel} className={css(sx.button)}>
          Close
        </Button>,
      ]}
    >
      <Title className="modelHeaderText" level={3}>
        {title}
      </Title>
      <div className={css(sx.flexContainer)}>
        <Search
          bordered
          placeholder="Search zip codes"
          allowClear
          onSearch={value => SeachZipcodes(value)}
          onChange={e => onChangeOfZipCodeSearch(e?.target?.value)}
          loading={!loadingMoreZipCodes && (isFetchingZipCodes || isFetchingPotentialZipCodes)}
        />
      </div>
      {loaded && (
        <Title className={css(sx.zipcount)}>
          {filteredByZipcode ? filteredZipcodes?.length : zipCodesCount} ZIP CODES
        </Title>
      )}
      {loaded && (
        <div className={css(sx.listContainer)}>
          {filteredByZipcode && filteredZipcodes?.length > 0 ? (
            <div className={css(sx.scrollable)}>
              <ul className={css(sx.list)}>
                {filteredZipcodes?.map(code => {
                  return <li key={code}>{code}</li>;
                })}
              </ul>
            </div>
          ) : (
            <div className={css(sx.scrollable)}>
              {uniqueZipCodes.map((zipCode, index) => {
                // Adding a ref if the zipcode is last of the current unique zipcodes list
                if (uniqueZipCodes?.length === index + 1) {
                  return (
                    <ul ref={lastZipCodeElementRef} className={css(sx.list)} key={zipCode}>
                      {zipCode}
                    </ul>
                  );
                }
                return (
                  <ul className={css(sx.list)} key={zipCode}>
                    {zipCode}
                  </ul>
                );
              })}
              {loadingMoreZipCodes && <Spin size="small" />}
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

ShowMore.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onModifyCancel: PropTypes.func,
  codes: PropTypes.array,
  zipCodesCount: PropTypes.number,
  isPotentialZipcodes: PropTypes.bool,
  pharmacyId: PropTypes.number,
};

export default ShowMore;
