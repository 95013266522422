import {
  colors,
  Column,
  Div,
  DropdownFooter,
  EnDash,
  font,
  LinkButton,
  Nbsp,
  NewDropdown,
  padding,
  space,
  Span,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';

const sx = StyleSheet.create({
  container: {
    ...padding.build(space.half),
    width: '29rem',
  },
  content: {
    color: colors.gray52,
    fontSize: font.size.xSmall,
    lineHeight: font.lineHeight.looser,
    width: '100%',
  },
  inputLabel: {
    cursor: 'pointer',
    fontSize: font.size.xSmall,
    fontWeight: font.weight.medium,
    marginBottom: space.quarter,
    userSelect: 'none',
  },
  reminderHeading: {
    color: colors.gray20,
    fontWeight: font.weight.medium,
  },
  reminderSection: {
    marginTop: space.half,
    whiteSpace: 'initial',
    width: '100%',
  },
});

const formatPharmacyItems = item => (item ? item.name : '');

const PharmacyDropdownLabel = props => (
  <Div {...props} styles={sx.inputLabel}>
    Pharmacy
  </Div>
);

const PharmacyForm = ({
  isLoading,
  onSelectPharmacy,
  onSubmit,
  onToggleEdit,
  pharmacyItems,
  selectedPharmacyId,
}) => (
  <Column>
    <Div styles={sx.container}>
      <Column styles={sx.content}>
        <Column>
          <NewDropdown
            id="e2e-pharmacyFormSelectPharmacyDropdown"
            items={pharmacyItems}
            itemToString={formatPharmacyItems}
            labelRenderer={PharmacyDropdownLabel}
            onChange={onSelectPharmacy}
            placeholder="Select a pharmacy..."
          />
        </Column>
        <Div styles={sx.reminderSection}>
          <Span styles={sx.reminderHeading}>Quick reminder</Span>
          <Span>
            <Nbsp />
            <EnDash />
            <Nbsp />
          </Span>
          <Span>
            Let the member know that they can still call 1-844-947-3784 to speak with their new
            preferred pharmacist.
          </Span>
        </Div>
      </Column>
    </Div>
    <DropdownFooter>
      <LinkButton onClick={onToggleEdit}>Cancel</LinkButton>
      <LinkButton
        disabled={!selectedPharmacyId}
        id="e2e-pharmacyFormSaveButton"
        isLoading={isLoading}
        onClick={onSubmit}
      >
        Save
      </LinkButton>
    </DropdownFooter>
  </Column>
);

PharmacyForm.propTypes = {
  isLoading: PropTypes.bool,
  onSelectPharmacy: PropTypes.func,
  onSubmit: PropTypes.func,
  onToggleEdit: PropTypes.func,
  pharmacyItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedPharmacyId: PropTypes.number,
};

export default PharmacyForm;
