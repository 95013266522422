import { colors, Div, Dot, font, Row, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import { get, isEqual, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const sx = StyleSheet.create({
  clearButton: {
    color: colors.white,
    textDecoration: 'underline',
    cursor: 'pointer',
    pointerEvents: 'auto',

    ':hover': { opacity: 0.75 },
    ':active': { opacity: 0.5 },
  },
  container: {
    color: colors.white,
    cursor: 'default',
    fontSize: font.size.small,
    marginBottom: space.half,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  showingAll: {
    color: colors.gray45,
  },
});

export default class FilterFieldLabel extends Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.any,
    }).isRequired,
    meta: PropTypes.shape({ initial: PropTypes.any }).isRequired,
    styles: aphroditePropType,
    text: PropTypes.string,
  };

  hasValue = () => {
    const currentValue = get(this.props, 'input.value');
    const initialValue = get(this.props, 'meta.initial');

    if (isEqual(currentValue, initialValue)) return false;
    else if (Array.isArray(currentValue)) return currentValue.length;
    return !!currentValue;
  };

  handleClickClear = event => {
    const { input, meta } = this.props;

    event.preventDefault();
    input.onChange(get(meta, 'initial', null));
  };

  renderClearButton = () => (
    <Div
      onClick={this.handleClickClear}
      styles={sx.clearButton}
      title={`Clear ${this.props.text} filter`}
    >
      Clear
    </Div>
  );

  renderLabelDecoration = () =>
    this.hasValue() ? this.renderClearButton() : <Div styles={sx.showingAll}>Showing all</Div>;

  render() {
    if (get(this.props, 'input.name') === 'sort_by') {
      return (
        <Row align="center" styles={[sx.container, this.props.styles]}>
          <Div>{upperFirst(this.props.text)}</Div>
        </Row>
      );
    }
    return (
      <Row align="center" styles={[sx.container, this.props.styles]}>
        <Div>{upperFirst(this.props.text)}</Div>
        <Dot color={colors.gray27} />
        {this.renderLabelDecoration()}
      </Row>
    );
  }
}
