import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Typography, Input, Button } from 'antd';

const { Search } = Input;

const sx = StyleSheet.create({
  largeInputBox: { width: '50%' },
  inputBox: { fontSize: '16px', color: '#979797' },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '208px',
    height: '40px',
  },
  modalContainer: {
    minWidth: '579px',
    height: '445px ',
  },
  zipcount: {
    margin: '17px 0 17px 0',
    fontSize: '11px',
  },
  scrollable: {
    height: 200,
    overflowY: 'auto',
  },
  list: {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  },
  button: {
    fontSize: '16px',
  },
  listContainer: {
    height: 200,
  },
  listItem: {
    height: 20,
  },
});

const { Title } = Typography;

const ZipcodesModal = ({ title, visible, onCloseModal, zipcodes }) => {
  const [filteredZipcodes, setFilteredZipcodes] = useState(zipcodes);

  const SearchZipcodes = value => {
    const matchedZipcodes = zipcodes?.filter(zipcode => zipcode.toString().includes(value));
    setFilteredZipcodes(matchedZipcodes);
  };

  const resetZipcodes = () => {
    setFilteredZipcodes(zipcodes);
  };

  const onCancel = () => {
    setFilteredZipcodes(zipcodes);
    onCloseModal();
  };

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      className={css(sx.modalContainer)}
      getContainer={false}
      footer={[
        <Button key="cancel" onClick={onCancel} className={css(sx.button)}>
          Close
        </Button>,
      ]}
    >
      <Title level={3}>{title}</Title>
      <div className={css(sx.flexContainer)}>
        <Search
          bordered
          placeholder="Search zip codes"
          allowClear
          onSearch={value => SearchZipcodes(value)}
          onReset={resetZipcodes}
        />
      </div>
      {filteredZipcodes && (
        <Title className={css(sx.zipcount)}>{filteredZipcodes?.length} ZIP CODES</Title>
      )}
      {filteredZipcodes && filteredZipcodes?.length > 0 && (
        <div className={css(sx.listContainer)}>
          <div className={css(sx.scrollable)}>
            <ul className={css(sx.list)}>
              {filteredZipcodes?.map(code => {
                return <li key={code}>{code}</li>;
              })}
            </ul>
          </div>
        </div>
      )}
    </Modal>
  );
};

ZipcodesModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func,
  zipcodes: PropTypes.array,
};

export default ZipcodesModal;
