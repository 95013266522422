import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

const sx = StyleSheet.create({
  label: {
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  largeText: {
    fontSize: '16px',
  },
  flex: {
    display: 'flex',
    gap: '10px',
    ':nth-child(1n) > div > p': {
      fontSize: '16px',
      color: 'rgba(0,0,0,0.65)',
    },
  },
  itemWidthSmall: {
    ':nth-child(1n) > div': {
      minWidth: '180px',
    },
  },
  itemWidthLarge: {
    ':nth-child(1n) > div': {
      width: '370px',
    },
  },
  section: {
    marginTop: '10px',
  },
});

const { Text, Title } = Typography;

const GeneralInformationReadOnly = ({
  editedPharmacyInfo,
  viewData,
  showPotentialAndAssignedZipcodes,
  showSelectedServices,
  isHubPharmacy,
  isChild,
}) => {
  const { asc, languages, networks, phone, state } = viewData;
  return (
    <>
      <div className={css(sx.section)}>
        <div className={css([sx.flex, sx.itemWidthSmall])}>
          <div>
            <p>Status: {editedPharmacyInfo?.status !== 'active' ? 'onHold' : 'active'}</p>
          </div>
          <div>
            <p>Account Service Coordinator: {asc}</p>
          </div>
        </div>
      </div>
      <Title level={5}>{'General'}</Title>
      <div className={css([sx.flex, sx.itemWidthSmall])}>
        <div>
          <Text className={css(sx.label)}>npi</Text>
          <p>{editedPharmacyInfo?.npi}</p>
        </div>
        <div>
          <Text className={css(sx.label)}>Phone Number</Text>
          <p>{phone}</p>
        </div>
        {!isChild && (
          <div>
            <Text className={css(sx.label)}>Skip Number (OPTIONAL)</Text>
            <p>{editedPharmacyInfo?.skip_number}</p>
          </div>
        )}
      </div>
      <div className={css([sx.flex, sx.itemWidthSmall])}>
        <div>
          <Text className={css(sx.label)}>Street Address</Text>
          <p>{editedPharmacyInfo?.address?.line1}</p>
        </div>
        <div>
          <Text className={css(sx.label)}>apt/suite</Text>
          <p>{editedPharmacyInfo?.address?.line2}</p>
        </div>
        <div>
          <Text className={css(sx.label)}>city</Text>
          <p>{editedPharmacyInfo?.address?.city}</p>
        </div>
        <div>
          <Text className={css(sx.label)}>state</Text>
          <p>{`${state?.name} (${state?.value})`}</p>
        </div>
        <div>
          <Text className={css(sx.label)}>zipcode</Text>
          <p>{editedPharmacyInfo?.address?.postal_code}</p>
        </div>
      </div>
      {showPotentialAndAssignedZipcodes()}
      <div className={css(sx.section)}>
        <Title level={5}>{'Network & Language'}</Title>
        <div className={css([sx.flex, sx.itemWidthLarge])}>
          <div>
            <Text className={css(sx.label)}>Networks</Text>
            <br />
            {networks?.length ? (
              <p>{networks?.join(', ')} </p>
            ) : (
              <Text className={css(sx.label)}>-</Text>
            )}
          </div>
          <div>
            <Text className={css(sx.label)}>Languages</Text>
            <br />
            {languages?.length ? (
              <p>{languages?.join(', ')} </p>
            ) : (
              <Text className={css(sx.label)}>-</Text>
            )}
          </div>
        </div>
      </div>
      <div className={css(sx.section)}>
        <Title level={5}>{'Services'}</Title>
        <div>{showSelectedServices()}</div>
      </div>
      <div className={css(sx.section)}>
        <Title level={5}>{'Hub Pharmacy'}</Title>
        <p className={css(sx.largeText)}>{`This is ${
          !isHubPharmacy ? 'not' : ''
        } a hub pharmacy`}</p>
      </div>
    </>
  );
};

GeneralInformationReadOnly.propTypes = {
  editedPharmacyInfo: PropTypes.object,
  viewData: PropTypes.object,
  showPotentialAndAssignedZipcodes: PropTypes.func,
  showSelectedServices: PropTypes.func,
  isHubPharmacy: PropTypes.bool,
  isChild: PropTypes.bool,
};

export default GeneralInformationReadOnly;
