import { border, colors, Column, Div, margin, padding, SmallCaps, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';

export const SIDEBAR_PADDING = space.get(1.5);

const sx = StyleSheet.create({
  container: {
    ...border.build('right', { color: colors.gray92 }),
    ...padding.build(SIDEBAR_PADDING, 0),
    backgroundColor: colors.white,
    flex: 'none',
    width: '26rem',
  },
  list: {
    paddingTop: space.default,
  },
  title: {
    ...margin.build(0, SIDEBAR_PADDING),
  },
});

const Sidebar = ({ children, title, ...props }) => (
  <Column {...props} styles={sx.container}>
    <SmallCaps styles={sx.title}>{title}</SmallCaps>
    <Div styles={sx.list}>{children}</Div>
  </Column>
);

Sidebar.padding = SIDEBAR_PADDING;

Sidebar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Sidebar;
