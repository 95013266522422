import { fields, patients, sanitizePhones } from '@zipdrug/react-api-sdk';
import { alertOnError, phoneField, requiredField, validateForm } from '@zipdrug/ui';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { reduxForm } from 'redux-form';
import ContactForm from './ContactForm';

const CONTACT_LABEL_MAP = ['Home', 'Mobile', 'Other'];

const DEFAULT_INITIAL_VALUES = {
  label: 'Home',
};

const CONTACT_FORM_SCHEMA = {
  label: requiredField,
  number: {
    ...phoneField,
    required: true,
  },
};

const ERROR_MESSAGES = {
  invalidPhone: 'is invalid',
  required: 'is required.',
};

const validate = values => validateForm(values, CONTACT_FORM_SCHEMA, ERROR_MESSAGES);

const ReduxContactForm = reduxForm({
  form: 'contactForm',
  validate,
})(ContactForm);

class ContactFormContainer extends Component {
  static propTypes = {
    contactItem: PropTypes.object,
    mutateUpdatePatient: PropTypes.func,
    onClickCancel: PropTypes.func,
    phone: PropTypes.object,
  };

  state = {
    animateCustomLabel: false,
    showCustomLabel: false,
  };

  componentWillMount() {
    this.initialValues = this.buildInitialValues();
  }

  initialValues = {};

  buildInitialValues = () => {
    const { phone } = this.props;
    if (Object.keys(phone).length) {
      const matchesDefaultLabels = CONTACT_LABEL_MAP.includes(phone.label);
      const isLabelOther = phone.label === 'Other';

      if (matchesDefaultLabels && !isLabelOther) {
        return phone;
      }

      this.setState({ showCustomLabel: true });

      return Object.assign({}, phone, {
        label: 'Other',
        customLabel: isLabelOther ? null : phone.label,
      });
    }

    return DEFAULT_INITIAL_VALUES;
  };

  handleChange = values => {
    if (Object.keys(values).length && values.label) {
      let newState = {
        showCustomLabel: values.label === 'Other',
      };

      if (!this.state.showCustomLabel) {
        newState = Object.assign({}, newState, {
          animateCustomLabel: true,
        });
      }

      this.setState(newState);
    }
  };

  handleClickDelete = () => {
    const { onClickCancel, contactItem, phone } = this.props;

    if (contactItem.phones.length === 1) {
      // eslint-disable-next-line no-alert
      window.alert('Sorry! You can not delete the last phone number associated with a member.');
      return onClickCancel();
    }

    const phones = sanitizePhones(contactItem.phones);
    phones.splice(phone.index, 1);

    return this.handleMutation({ phones });
  };

  handleMutation = async modifier =>
    alertOnError(
      this.props.mutateUpdatePatient({
        variables: {
          id: this.props.contactItem.id,
          modifier,
        },
      }),
    ).then(this.props.onClickCancel);

  handleSubmit = ({ index, ...values }) => {
    const phones = sanitizePhones(this.props.contactItem.phones);

    phones.forEach(phone => {
      phone.order += 1;
    });

    let label = values.label;
    if (values.label === 'Other') {
      label = values.customLabel || 'Other';
    }

    const phone = {
      ...pick(values, ...fields.PhoneModifier),
      label,
    };

    if (Number.isInteger(index)) phones.splice(index, 1, phone);
    else phones.push(phone);

    return this.handleMutation({ phones });
  };

  render = () => (
    <ReduxContactForm
      {...this.props}
      animateCustomLabel={this.state.animateCustomLabel}
      initialValues={this.initialValues}
      labelOptions={CONTACT_LABEL_MAP}
      onChange={this.handleChange}
      onClickDelete={this.handleClickDelete}
      onSubmit={this.handleSubmit}
      showCustomLabel={this.state.showCustomLabel}
    />
  );
}

export default graphql(patients.mutateUpdatePatient, {
  name: 'mutateUpdatePatient',
})(ContactFormContainer);
