import {
  animation,
  border,
  colors,
  Column,
  Div,
  FlexCentered,
  font,
  Icon,
  padding,
  position,
  Row,
  space,
  transition,
  zIndex,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import { some } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ClickOutside from 'react-click-outside';
import { PatientListItemClassName } from '../list/PatientListItem';
import { ClearFiltersButtonId } from '../sidebar/SearchResultsListHeader';
import { ColumnMinWidth } from '../../ui/ThreeColumnPageTemplate';
import { TopBarHeight } from '../../ui/topbar/TopBar';
import { FilterButtonId } from './FilterButton';
import FilterFormContainer from './FilterFormContainer';

const DefaultPanelTransform = {
  transform: `translateX(-${space.two})`,
};

const HeaderHeight = '3.2rem';

const PanelAnimation = {
  from: {
    ...DefaultPanelTransform,
    opacity: 0.9,
  },
  to: {
    transform: 'translateX(0)',
    opacity: 1,
  },
};

const ClickOutsideSelectorBlacklist = [
  ClearFiltersButtonId,
  FilterButtonId,
  PatientListItemClassName,
];

const sx = StyleSheet.create({
  backdrop: {
    ...animation.build(),
    ...position.cover,
    backgroundColor: colors.alpha(colors.gray18, 0.75),
  },
  closeButton: {
    ...border.build('', { color: colors.gray27 }),
    ...border.buildRadius('', '0.4rem'),
    ...position.size(HeaderHeight),
    ...transition.build(),
    color: colors.gray48,
    cursor: 'pointer',

    ':hover': {
      backgroundColor: colors.gray48,
      borderColor: colors.gray48,
      color: colors.gray20,
    },
    ':active': {
      backgroundColor: colors.bluePurple,
      borderColor: colors.bluePurple,
      color: colors.gray20,
    },
  },
  closeButtonIcon: position.size('1.4rem'),
  container: {
    bottom: 0,
    left: ColumnMinWidth,
    position: 'fixed',
    right: 0,
    top: TopBarHeight,
    zIndex: zIndex[6],
    ':focus': { outline: 'none' },
  },
  header: {
    color: colors.gray45,
    cursor: 'default',
    fontSize: font.size.small,
    height: HeaderHeight,
    userSelect: 'none',
  },
  headerTitle: {
    color: colors.white,
    fontSize: '1.5rem',
    fontWeight: font.weight.bold,
    marginBottom: '0.4rem',
  },
  panel: {
    ...animation.build(),
    ...DefaultPanelTransform,
    ...border.build('right', { color: colors.gray27 }),
    ...padding.build(space.default),
    animationName: PanelAnimation,
    backgroundColor: colors.darkBlue,
    height: '100%',
    opacity: 0,
    width: ColumnMinWidth,
    overflowY: 'scroll',
  },
});

export default class FilterPanel extends Component {
  static propTypes = {
    onToggleFilterPanel: PropTypes.func.isRequired,
    styles: aphroditePropType,
  };

  componentWillMount = () => document.addEventListener('keydown', this.handleKeyDown);
  componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

  handleClickOutside = ({ target: { classList, id } }) => {
    if (some([...classList, id], ClickOutsideSelectorBlacklist)) {
      // Toggle FilterPanel visibility if user "clicks outside" the panel,
      // but ignore the "outside click" if user has clicked the main FilterButton toggle,
      // the "Clear" filters button in the PatientList header, or a PatientListItem.
      this.props.onToggleFilterPanel();
    }
  };

  handleKeyDown = event => {
    // Submit form on Esc keypress
    if (event.which === 27) {
      event.preventDefault();
      this.props.onToggleFilterPanel();
    }
  };

  render = () => (
    <ClickOutside onClickOutside={this.handleClickOutside}>
      <Div styles={[sx.container, this.props.styles]} tabIndex={0}>
        <Div onClick={this.props.onToggleFilterPanel} styles={sx.backdrop} />
        <Column styles={sx.panel}>
          <Row justify="space-between" styles={sx.header}>
            <Column justify="center">
              <Div styles={sx.headerTitle}> Sort and Filter Members </Div>
            </Column>
            <FlexCentered
              id="e2e-filterPanel-closeButton"
              onClick={this.props.onToggleFilterPanel}
              styles={sx.closeButton}
              title="Close filter panel"
            >
              <Icon name="close" styles={sx.closeButtonIcon} />
            </FlexCentered>
          </Row>
          <FilterFormContainer />
        </Column>
      </Div>
    </ClickOutside>
  );
}
