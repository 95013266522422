import { CheckboxItem, Div, FormCheckboxGroupField, rem, space, unit } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import React from 'react';
import FilterFieldLabel from './FilterFieldLabel';

const sx = StyleSheet.create({
  label: {
    paddingBottom: rem(unit(space.default) - unit(CheckboxItem.padding)),
  },
});

const FilterCheckboxGroupField = ({ styles, ...props }) => (
  <FormCheckboxGroupField
    {...props}
    containerRenderer={Div}
    labelRenderer={FilterFieldLabel}
    styles={[styles, sx.label]}
    theme="dark"
  />
);

FilterCheckboxGroupField.propTypes = {
  styles: aphroditePropType,
};

export default FilterCheckboxGroupField;
