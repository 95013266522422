/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Table, message } from 'antd';
import { get, cloneDeep } from 'lodash';
import gql from 'graphql-tag';
import { StyleSheet, css } from 'aphrodite';
import withRoles from 'hoc/withRoles';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import OmitDeep from 'dashboard/components/PharmacyList/utils/OmitDeep';
import GET_CAMPAIGNS from '../../graphql/getCampaigns';
import GET_CAMPAIGN_WEIGHTS from '../../graphql/getCampaignWeights';
import UPDATE_CAMPAIGN_WEIGHTS from '../../graphql/updateCampaignWeights';
import CampaignModal from '../EditCampaign/CampaignModal';
import CampaignPriorityModal from '../CampaignPriority/CampaignPriorityModal';
import priorities from '../EditCampaign/constants/Priorities';
import generateVariables from '../../utils/GenerateVariables.utils';

const sx = StyleSheet.create({
  marginWrapper: {
    margin: '10px',
  },
  tableOptionsContainer: {
    marginBottom: '20px',
  },
  tableOptionsButtonMargin: {
    marginRight: '16px',
  },
});

export const GET_PAYERS = gql`
  query payers($query: JSON) {
    payers(query: $query) {
      data {
        name
        id
      }
      total
    }
  }
`;

const CampaignsList = ({
  hasRole: {
    systemAdmin,
    pbsLead,
    pbsAdmin,
    pharmacyBusinessDevAdmin,
    pharmacyAscAdmin,
    projectManager,
  },
}) => {
  const [data, setData] = useState([]);
  const [showEditModalFlag, setShowEditModalFlag] = useState(false);
  const [isEditFlag, setIsEditFlag] = useState(false);
  const [editingCampaignId, setEditingCampaignId] = useState({});
  const [tableActions, setTableActions] = useState({});

  const [originalPriorityAllocationObj, setOriginalPriorityAllocationObj] = useState([]);

  const [showCampaignPriorityModal, setShowCampaignPriorityModal] = useState(false);

  const { data: payerData } = useQuery(GET_PAYERS, {});
  const payerArr = get(payerData, 'payers.data');

  const payerById = payerArr?.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

  const [
    getCampaignsList,
    { loading: isFetchingCampaignsList, data: campaignsData, refetch: refetchCampaignList },
  ] = useLazyQuery(GET_CAMPAIGNS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching campaigns: ${error.message}`);
    },
    onCompleted: ({ campaigns }) => {
      setData(campaigns?.data);
    },
  });

  const [getCampaignWeightData] = useLazyQuery(GET_CAMPAIGN_WEIGHTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error Fetching Campaign Weights: ${error.message}`);
    },
    onCompleted: ({ campaignWeights }) => {
      setOriginalPriorityAllocationObj([...OmitDeep(campaignWeights?.data, ['__typename'])]);
    },
  });

  useEffect(() => {
    getCampaignWeightData();
  }, []);

  const [updateCampaignWeights] = useMutation(UPDATE_CAMPAIGN_WEIGHTS, {
    onError: error => {
      message.error(`Error Updating Campaign Weights: ${error.message}`);
    },
    onCompleted: ({ updateCampaignWeight }) => {
      setOriginalPriorityAllocationObj([...OmitDeep(updateCampaignWeight?.data, ['__typename'])]);
      message.info(`Campaign Weights Updated Successfully.`);
    },
  });

  const openEditModal = (isEdit, campaignId) => {
    setShowEditModalFlag(true);
    if (isEdit) {
      setEditingCampaignId(campaignId);
    }
    setIsEditFlag(isEdit);
  };

  const closeEditModal = () => {
    setEditingCampaignId(null);
    setIsEditFlag(false);
    setShowEditModalFlag(false);
    refetchCampaignList();
  };

  const onSubmit = () => {
    setIsEditFlag(false);
    setShowEditModalFlag(false);
    refetchCampaignList();
  };

  const saveCampaignWeight = priorityAllocationObj => {
    updateCampaignWeights({
      variables: { campaignWeightModifiers: priorityAllocationObj },
    });
    setShowCampaignPriorityModal(false);
  };

  const openCampaignPriorityModal = () => {
    setShowCampaignPriorityModal(true);
  };

  const closeCampaignPriorityModal = () => {
    setShowCampaignPriorityModal(false);
  };

  useEffect(() => {
    if (
      systemAdmin ||
      pbsLead ||
      pbsAdmin ||
      pharmacyBusinessDevAdmin ||
      pharmacyAscAdmin ||
      projectManager
    ) {
      getCampaignsList();
    }
  }, []);

  const generateColumns = () => {
    const headers = [
      {
        title: 'Campaign Name',
        dataIndex: 'name',
        key: 'name',
        render: text => {
          return text || 'N/A';
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => {
          return text || 'N/A';
        },
      },
      {
        title: 'Payer',
        dataIndex: 'payer_ids',
        key: 'payer_ids',
        render: payerIds => {
          return payerIds?.length === payerArr?.length
            ? 'All'
            : payerIds?.map(
                (payerId, index) =>
                  `${payerById[payerId]?.name}${index !== payerIds.length - 1 ? ', ' : ''}`,
              ) || 'None Assigned';
        },
      },
      {
        title: 'Plan ID',
        dataIndex: 'plan_ids',
        key: 'plan_ids',
        render: text => {
          return text?.length > 0
            ? text?.map((plan, index) => `${plan}${index !== text.length - 1 ? ', ' : ''}`)
            : 'None Assigned';
        },
      },
      {
        title: 'Special Notes',
        dataIndex: 'special_notes',
        key: 'special_notes',
        render: text => {
          return text?.length > 0
            ? text?.map((notes, index) => `${notes}${index === text.length - 1 ? '' : ', '}`)
            : 'None Assigned';
        },
      },
      {
        title: 'Pharmacy',
        dataIndex: 'pharmacy_names',
        key: 'pharmacy_names',
        render: text => {
          return text?.length > 0
            ? text?.map((pharmacy, index) => `${pharmacy}${index !== text.length - 1 ? ', ' : ''}`)
            : 'None Assigned';
        },
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'campaign',
        render: text => {
          const priority = priorities.find(p => p.value === text);
          return priority.text || 'N/A';
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'campaign',
        render: text => {
          return text || 'N/A';
        },
      },
      {
        title: 'Action',
        key: 'action',

        render: text => {
          return (
            <Button type="link" onClick={() => openEditModal(true, text.id)}>
              Edit
            </Button>
          );
        },
      },
    ];
    return headers;
  };

  const fetchCampaignsList = (payload = cloneDeep(tableActions), refetch = false) => {
    const variables = generateVariables(payload);
    if (refetch) {
      refetchCampaignList({
        ...variables,
      });
    } else {
      getCampaignsList({
        variables: {
          ...variables,
        },
      });
    }
  };

  const onTableChange = (pagination, filters = {}, sorter = {}, { action }) => {
    setTableActions({
      pagination: action === 'paginate' ? pagination : { ...pagination, current: 1 },
    });
    if (action === 'paginate') {
      fetchCampaignsList({
        pagination,
        filters,
        sorter,
      });
    } else {
      fetchCampaignsList({ filters, sorter });
    }
  };

  return (
    <>
      <div className={css(sx.tableOptionsContainer)}>
        <Button
          type="secondary"
          className={css(sx.tableOptionsButtonMargin)}
          onClick={() => openEditModal(false, {})}
        >
          Add Campaign
        </Button>
        {systemAdmin ||
        pbsAdmin ||
        pharmacyBusinessDevAdmin ||
        pharmacyAscAdmin ||
        projectManager ? (
          <Button type="secondary" onClick={() => openCampaignPriorityModal()}>
            Set Campaign Priority Allocation
          </Button>
        ) : null}
      </div>
      <Table
        columns={generateColumns()}
        dataSource={data}
        size="middle"
        rowKey="id"
        onChange={onTableChange}
        loading={isFetchingCampaignsList}
        pagination={{
          defaultPageSize: 25,
          showSizeChanger: true,
          pageSizeOptions: [25, 50, 100],
          total: campaignsData?.campaigns?.total || 0,
          current: tableActions?.pagination?.current || 1,
        }}
      />
      <CampaignModal
        visible={showEditModalFlag}
        isEdit={isEditFlag}
        campaignId={editingCampaignId}
        onCloseModal={() => closeEditModal()}
        onSubmit={() => onSubmit()}
      />
      <CampaignPriorityModal
        visible={showCampaignPriorityModal}
        onCloseModal={closeCampaignPriorityModal}
        saveCampaignWeight={saveCampaignWeight}
        originalPriorityAllocationObj={originalPriorityAllocationObj}
      />
    </>
  );
};

export default withRoles(CampaignsList);
