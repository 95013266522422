import { border, colors, font, formatFullName, padding, rem, Row, space, unit } from '@zipdrug/ui';
import aphroditePropType from 'aphrodite-prop-type';
import { StyleSheet } from 'aphrodite/no-important';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import withRoles from 'hoc/withRoles';
import withCurrentPharmacy from '../../hoc/withCurrentPharmacy';
import HidePHIButton from '../../ui/HidePHIButton';
import TopBarItem from './TopBarItem';
import { SessionContext } from '../../contexts/SessionContextProvider';

export const TopBarHeight = '3.6rem';

const sx = StyleSheet.create({
  container: {
    ...border.build('bottom', { color: colors.gray87 }),
    ...padding.build(0, rem(unit(space.default) - unit(TopBarItem.padding.horizontal))),
    backgroundColor: colors.white,
    fontSize: font.size.small,
    height: TopBarHeight,
    width: '100%',
  },
  containerGradient: {
    // backgroundImage: 'linear-gradient(to bottom, #F5F5F5 0%, #F0F0F0 100%)',
    backgroundColor: '#F5F5F5',
  },
  itemMargin: {
    marginRight: '12px',
  },
});

const TopBar = ({
  currentPharmacy,
  // currentUser,
  onAccountClick,
  onPhoneClick,
  onMembersClick,
  // onTasksClick,
  onLeadListClick,
  onCohortListClick,
  // onOutcomesClick,
  onHelpClick,
  showGradient,
  styles,
  workstationPhone,
  isSystemAdmin,
  isPbsTeam,
  isPharmacyTeam,
  hasRole,
}) => {
  const { state } = useContext(SessionContext);
  const { currentUser } = state;
  const cohortFlag = get(currentUser, 'feature_flags.cohort');
  const currentUserName = currentUser ? formatFullName(currentUser) : null;

  const teamName =
    !hasRole.pharmacistUser && !hasRole.pharmacistAdmin
      ? 'Care Manager'
      : get(currentPharmacy, 'name');
  // const canSeeTasks = isZipdrugUser || (currentPharmacy && currentPharmacy.canAccessTasks);
  const callCenterFeatureFlag = get(currentUser, 'feature_flags.callCenter');

  return (
    <Row
      align="center"
      justify="space-between"
      styles={[sx.container, showGradient ? sx.containerGradient : null, styles]}
    >
      {teamName && (
        <Row align="center" justify="start" style={{ flex: 1 }}>
          <TopBarItem isBrandName text={teamName} />
          {!hasRole.projectManager && (
            <TopBarItem onClick={onMembersClick} styles={sx.itemMargin} text="Members" />
          )}

          {/* {canSeeTasks && <TopBarItem onClick={onTasksClick} styles={sx.itemMargin} text="Tasks" />} */}
          {(isSystemAdmin || isPbsTeam) && (
            <TopBarItem onClick={onLeadListClick} styles={sx.itemMargin} text="Lead List" />
          )}
          {cohortFlag ? (
            <TopBarItem onClick={onCohortListClick} styles={sx.itemMargin} text="Cohort List" />
          ) : (
            <></>
          )}
          {/* <TopBarItem onClick={onOutcomesClick} styles={sx.itemMargin} text="OutcomesMTM" /> */}
        </Row>
      )}
      <Row align="center" justify="end" style={{ flex: 1 }}>
        {(isPbsTeam || isPharmacyTeam) && <HidePHIButton styles={sx.itemMargin} />}
        {currentUserName && (
          <TopBarItem
            icon="person"
            onClick={onAccountClick}
            id="e2e-appHeaderOpenSettingsButton"
            styles={sx.itemMargin}
            text={currentUserName}
          />
        )}
        {currentUser && !callCenterFeatureFlag && (isPbsTeam || isSystemAdmin) && (
          <TopBarItem
            icon="telephone"
            onClick={onPhoneClick}
            styles={sx.itemMargin}
            text={get(workstationPhone, 'label', 'Assign workstation phone')}
            tooltipText="Phone settings"
          />
        )}
        <TopBarItem icon="help" onClick={onHelpClick} styles={sx.itemMargin} text="Help" />
      </Row>
    </Row>
  );
};

TopBar.propTypes = {
  currentPharmacy: PropTypes.shape({
    name: PropTypes.string.isRequired,
    canAccessTasks: PropTypes.any,
  }),
  currentUser: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    feature_flags: PropTypes.object,
  }),
  hasRole: PropTypes.object,
  isSystemAdmin: PropTypes.bool,
  isPbsTeam: PropTypes.bool,
  isPharmacyTeam: PropTypes.bool,
  onAccountClick: PropTypes.func.isRequired,
  // onTasksClick: PropTypes.func,
  onLeadListClick: PropTypes.func,
  onCohortListClick: PropTypes.func,
  onMembersClick: PropTypes.func,
  onPhoneClick: PropTypes.func.isRequired,
  // onOutcomesClick: PropTypes.func.isRequired,
  onHelpClick: PropTypes.func,
  showGradient: PropTypes.bool,
  styles: aphroditePropType,
  workstationPhone: PropTypes.shape({ label: PropTypes.string.isRequired }),
};

TopBar.defaultProps = {
  showGradient: true,
};

export default withRoles(withCurrentPharmacy(TopBar));
