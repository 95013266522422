import { ActivitySeparator } from '@zipdrug/ui';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import CompletedDeliveryActivityItem from './activity-item/delivery/CompletedDeliveryActivityItem';
import FailedDeliveryActivityItem from './activity-item/delivery/FailedDeliveryActivityItem';
import ScheduledDeliveryActivityItem from './activity-item/delivery/ScheduledDeliveryActivityItem';
import AudioActivityItemContainer from './activity-item/AudioActivityItemContainer';
import CallActivityItem from './activity-item/CallActivityItem';
import EnrollmentActivityItem from './activity-item/EnrollmentActivityItem';
import MissedCallActivityItem from './activity-item/MissedCallActivityItem';
import NoteActivityItemContainer from './activity-item/NoteActivityItemContainer';
import VoicemailActivityItem from './activity-item/VoicemailActivityItem';
import LetterActivityItem from './activity-item/LetterActivityItem';
import ReturnedLetterActivityItem from './activity-item/ReturnedLetterActivityItem';
import DoNotCallActivityItem from './activity-item/DoNotCallActivityItem';
import PharmacyActivityItem from './activity-item/PharmacyActivityItem';
import SurveyActivityItem from './activity-item/SurveyActivityItem';

const LETTER_SENT = 'LETTER_SENT';
const LETTER_RETURNED_TO_SENDER = 'LETTER_RETURNED_TO_SENDER';

export default class ActivityListItem extends Component {
  static propTypes = {
    activity: PropTypes.object,
    currentPharmacy: PropTypes.object,
  };

  delayInterval = null;

  buildActivityProps = () => ({
    activity: this.props.activity,
    className: 'e2e-activityListItem',
    currentPharmacy: this.props.currentPharmacy,
    key: this.props.activity.id,
  });

  renderCall = () => {
    if (!this.props.activity.call) return null;

    if (!this.props.activity.call.file_url) {
      // Outbound calls and incoming calls will immediately show up
      // as missed calls unless we add a 1 minute delay before
      // displaying them in the activity feed.
      const delayMilliseconds = 60 * 1000;

      const activityCreatedAt = moment(this.props.activity.created_at).utc();
      const oneMinuteAgo = moment()
        .utc()
        .subtract(delayMilliseconds, 'ms');

      if (activityCreatedAt.isAfter(oneMinuteAgo)) {
        if (this.delayInterval) clearTimeout(this.delayInterval);
        this.delayInterval = setTimeout(() => this.forceUpdate(), delayMilliseconds);
        return null;
      }

      return <MissedCallActivityItem {...this.buildActivityProps()} />;
    }

    if (
      this.props.activity.call.to_phone ||
      this.props.activity.call.to_pharmacy_id ||
      this.props.activity.call.to_user_id
    ) {
      return (
        <AudioActivityItemContainer
          {...this.buildActivityProps()}
          componentRenderer={CallActivityItem}
        />
      );
    }
    return (
      <AudioActivityItemContainer
        {...this.buildActivityProps()}
        componentRenderer={VoicemailActivityItem}
      />
    );
  };

  renderLetter() {
    const { activity } = this.props;
    if (activity.letter && activity.type === LETTER_SENT) {
      return <LetterActivityItem {...this.buildActivityProps()} />;
    } else if (activity.letter && activity.type === LETTER_RETURNED_TO_SENDER) {
      return <ReturnedLetterActivityItem {...this.buildActivityProps()} />;
    }

    return null;
  }

  renderDoNotCallActivity() {
    const { activity } = this.props;
    if (activity.model_update && 'is_dnc' in activity.model_update.new_fields) {
      return <DoNotCallActivityItem {...this.buildActivityProps()} />;
    }
    return null;
  }

  renderDelivery = () => {
    const { activity } = this.props;
    if (!activity.delivery) return null;

    if (!activity.model_update) {
      return <ScheduledDeliveryActivityItem {...this.buildActivityProps()} />;
    }

    if (activity.delivery.failure_reason) {
      return <FailedDeliveryActivityItem {...this.buildActivityProps()} />;
    }

    if (activity.model_update.new_fields.state === '3') {
      return <CompletedDeliveryActivityItem {...this.buildActivityProps()} />;
    }

    return null;
  };

  renderEnrollStatus = () => {
    let enroll_status = get(this.props, 'activity.model_update.new_fields.enroll_status');
    const withCallOutcome = get(this.props, 'activity.call');
    if (enroll_status === 'interested') {
      enroll_status = 'Enrolled';
    }

    return enroll_status && !withCallOutcome ? (
      <EnrollmentActivityItem {...this.buildActivityProps()} enroll_status={enroll_status} />
    ) : null;
  };

  renderNote = () =>
    this.props.activity.note ? <NoteActivityItemContainer {...this.buildActivityProps()} /> : null;

  renderPharmacy() {
    const newFields = get(this.props, 'activity.model_update.new_fields') || {};
    const oldFields = get(this.props, 'activity.model_update.old_fields') || {};

    const set = new Set([...Object.keys(newFields), ...Object.keys(oldFields)]);

    const conditional = set.size === 1 && set.has('assigned_pharmacy_id');

    if (conditional === true) {
      return <PharmacyActivityItem {...this.buildActivityProps()} />;
    }

    return null;
  }

  renderSurvey = () =>
    this.props.activity.interaction ? <SurveyActivityItem {...this.buildActivityProps()} /> : null;

  renderSeparator = () =>
    this.props.activity instanceof Date ? (
      <ActivitySeparator date={this.props.activity} key={this.props.activity.getTime()} />
    ) : null;

  render = () => (
    <Fragment>
      {this.renderPharmacy()}
      {this.renderDoNotCallActivity()}
      {this.renderCall()}
      {this.renderDelivery()}
      {this.renderEnrollStatus()}
      {this.renderLetter()}
      {this.renderNote()}
      {this.renderSeparator()}
      {this.renderSurvey()}
    </Fragment>
  );
}
