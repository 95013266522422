import { withSession } from '@zipdrug/react-api-sdk';
import { queryPatientsBasic } from 'graphql/patients';
import { defer, get } from 'lodash';
import { graphql } from 'react-apollo';
import { compose, withProps } from 'recompose';
import withFilterPanelVisibility from '../../hoc/withFilterPanelVisibility';
import withFilters from '../../hoc/withFilters';
import withPharmacyId from '../../hoc/withPharmacyId';
import withRefreshPatients from '../../hoc/withRefreshPatients';
import withRoles from '../../hoc/withRoles';
import PatientsSidebar from './PatientsSidebar';

let REFETCH_PATIENTS_CACHE = 0;

const PatientsSidebarWithData = graphql(queryPatientsBasic, {
  skip: ({ userId }) => !userId,
  options: ({
    alert_type,
    assigned_pharmacy_id,
    enroll_status,
    language,
    pdc,
    plan_id,
    sort_by,
    search,
    showAllPatients,
    showSnoozed,
  }) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      alert_type,
      assigned_pharmacy_id,
      enroll_status,
      language,
      limit: 25,
      no_snoozed: showAllPatients ? false : !showSnoozed,
      only_snoozed: showAllPatients ? false : showSnoozed,
      sort_by: sort_by ? sort_by.value : 'enrollment_date',
      pdc,
      plan_id,
      search,
      skip: 0,
    },
  }),
  props: ({
    data: { fetchMore, loading, patients: result, refetch },
    ownProps: {
      alert_type,
      assigned_pharmacy_id,
      enroll_status,
      language,
      pdc,
      plan_id,
      sort_by,
      refetchPatientsCount,
      search,
      showAllPatients,
      showSnoozed,
    },
  }) => {
    let totalPatients = 0;
    let patients = [];

    if (result && result.data) {
      patients = result.data;
      totalPatients = result.total;
    }

    if (refetchPatientsCount > REFETCH_PATIENTS_CACHE) {
      REFETCH_PATIENTS_CACHE = refetchPatientsCount;
      defer(refetch);
    }

    return {
      isLoading: loading,
      loadMoreRows: ({ startIndex, stopIndex }) =>
        fetchMore({
          query: queryPatientsBasic,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const data = [...get(previousResult, 'patients.data', {})];
            for (let i = 0; i < fetchMoreResult.patients.data.length; i += 1) {
              data[startIndex + i] = fetchMoreResult.patients.data[i];
            }

            return {
              patients: {
                ...fetchMoreResult.patients,
                data,
              },
            };
          },
          variables: {
            alert_type,
            assigned_pharmacy_id,
            enroll_status,
            language,
            limit: stopIndex - startIndex + 1,
            no_snoozed: showAllPatients ? false : !showSnoozed,
            only_snoozed: showAllPatients ? false : showSnoozed,
            sort_by: sort_by ? sort_by.value : 'enrollment_date',
            pdc,
            plan_id,
            search,
            skip: startIndex,
          },
        }).catch(() => {
          // this catches the ObservableQuery bug https://github.com/apollographql/apollo-client/issues/4114#issuecomment-502111099
          // should be addressed in rearchitecture
        }),
      patients,
      totalPatients,
    };
  },
})(PatientsSidebar);

export default compose(
  withSession,
  withFilterPanelVisibility,
  withFilters,
  withPharmacyId,
  withRoles,
  withRefreshPatients,
  withProps(({ filterCount, isFilterPanelOpen, search }) => ({
    showAllPatients: filterCount || isFilterPanelOpen || search,
  })),
)(PatientsSidebarWithData);
