import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Space, Typography, Card, message, Select, TimePicker, Input, Button, Form } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { isAscTeam, isBdTeam } from 'contexts/utils/models';
import moment from 'moment';
import { withRouter } from 'react-router';
import UPDATE_PHARMACY_HOURS from '../../graphql/updatePharmacyHours';
import './pharmacyHours.css';

const sx = StyleSheet.create({
  modalContainer: {
    minWidth: '100%',
  },
  marginBottom10: {
    marginBottom: '10px',
    gap: '10px',
  },
  split: {
    display: 'flex',
  },
  division: {
    marginRight: '32px',
  },
  flexGapLarge: {
    gap: '50px',
  },
  row: {
    height: '40px',
  },
  rowGapLarge: {
    marginBottom: '88px',
  },
  column: {
    width: '400px',
  },
  itemWidthLarge: {
    width: '65px',
  },
  itemWidthSmall: {
    margin: '0px 20px',
  },
  titleCaseText: {
    fontSize: '16px',
  },
  upperCaseText: {
    fontSize: '12px',
  },
  longText: {
    display: 'inline-block',
    minWidth: '88px',
    minHeight: '19px',
    marginRight: '39px',
    color: '#212121',
  },
  shortText: {
    width: '16px',
    height: '19px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
    marginRight: '10px',
    marginTop: '10px',
  },
  timezoneSelect: {
    minWidth: '164px',
    minHeight: '40px',
  },
  timePicker: {
    width: '131px',
    height: '40px',
  },
  description: {
    width: '539px',
    height: '96px',
    marginTop: '8px',
  },
  border: {
    border: '1px solid rgba(0,0,0,0.15)',
    borderRadius: '4px',
  },
});

const { Text, Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const PharmacyHoursTab = ({ isEdit, readOnly, pharmacyInfo, history }) => {
  const initialDays = [
    { name: 'Sunday', key: 'Sunday', from: '', to: '' },
    { name: 'Monday', key: 'Monday', from: '', to: '' },
    { name: 'Tuesday', key: 'Tuesday', from: '', to: '' },
    { name: 'Wednesday', key: 'Wednesday', from: '', to: '' },
    { name: 'Thursday', key: 'Thursday', from: '', to: '' },
    { name: 'Friday', key: 'Friday', from: '', to: '' },
    { name: 'Saturday', key: 'Saturday', from: '', to: '' },
  ];
  const [timeZone, setTimeZone] = useState('');
  const [days, setDays] = useState(initialDays);
  const [notes, setNotes] = useState('');
  const [champion, setChampion] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [form] = Form.useForm();

  const fetchHours = () => {
    const { hours } = pharmacyInfo;
    delete hours?.champion;
    if (hours === null || Object.keys(hours).length === 0) {
      setDataLoaded(true);
    } else {
      Object.keys(hours).forEach(key => {
        if (key === 'timezone') {
          setTimeZone(hours[key]);
        } else if (key === 'notes') {
          setNotes(hours[key]);
        } else {
          const itemIndex = days.findIndex(day => day.name === key);
          const newArray = [...days];
          newArray[itemIndex].from = hours[key].from;
          newArray[itemIndex].to = hours[key].to;
          setDays(newArray);
        }
      });
      setDataLoaded(true);
    }

    if (pharmacyInfo.champion) {
      setChampion(pharmacyInfo.champion);
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchHours();
    } else {
      setDataLoaded(true);
    }
  }, [pharmacyInfo]);

  // query to add Hours.
  const [addHours, { loading: isAddingHours }] = useMutation(UPDATE_PHARMACY_HOURS, {
    onError: e => {
      message.error(`Error Updating Pharmacy Hours: ${e.message}`);
    },
    onCompleted: () => {
      message.success(`Successfully ${isEdit ? 'Updated' : 'Added'} Hours`);
      // do we need to add a redirect here?
    },
  });

  const onChangeOfTimezone = value => {
    setTimeZone(value);
  };

  const onchange = (time, day, type) => {
    const timeString = moment(time).format('h:mm A');
    const obj = [...days];
    const itemIndex = days.findIndex(item => item.name === day);
    obj[itemIndex][type] = timeString;
    setDays(obj);
  };

  const onChangeOfNotes = ({ target: { value } }) => {
    setNotes(value);
  };

  const onChangeOfZipdrugChampion = ({ target: { value } }) => {
    setChampion(value);
  };

  const validateForm = () => {
    let workDays = {};
    const errorPayload = {};
    days.map(day => {
      if (day.from !== '') {
        const item = day.from;
        workDays = { ...workDays, [day.name]: { ...workDays[day.name], from: item } };
      }
      if (day.to !== '') {
        const item = day.to;
        workDays = { ...workDays, [day.name]: { ...workDays[day.name], to: item } };
      }
      return workDays;
    });
    if (timeZone === '' && Object.keys(workDays).length === 0 && notes === '') {
      errorPayload.error = 'NO INFO PROVIDED TO SAVE';
      return { errorPayload, workDays };
    } else if (Object.keys(workDays).length !== 0) {
      Object.keys(workDays).forEach(workday => {
        if (!('from' in workDays[workday])) {
          errorPayload.error = 'PLEASE ENTER OPENING TIME';
          return { errorPayload, workDays };
        } else if (!('to' in workDays[workday])) {
          errorPayload.error = 'PLEASE ENTER CLOSING TIME';
          return { errorPayload, workDays };
        } else if (
          moment(workDays[workday]?.from, 'hh:mm A').format('HH:mm') >
          moment(workDays[workday]?.to, 'hh:mm A').format('HH:mm')
        ) {
          errorPayload.error = `PLEASE ENTER CORRECT VALUES FOR TIME OF ${workday.toUpperCase()}`;
          return { errorPayload, workDays };
        }
      });
      return { errorPayload, workDays };
    }
    return { errorPayload, workDays };
  };

  const saveData = async () => {
    const validations = await validateForm();
    const { errorPayload, workDays } = validations;
    if (Object.keys(errorPayload).length === 0) {
      const hours = {
        timezone: timeZone,
        ...workDays,
        notes: notes.trim(),
        champion: champion.trim(),
      };
      addHours({
        variables: {
          pharmacyId: pharmacyInfo?.id,
          modifiers: {
            hours,
          },
        },
      });
    } else {
      message.error(`${errorPayload.error}`);
    }
  };

  const handleCancelAction = () => {
    setTimeZone('');
    setNotes('');
    setChampion('');
    setDays(initialDays);
    history.push({
      pathname: '/settings/pharmacylist',
    });
  };

  const readOnlyView = () => {
    return (
      <>
        <div className={css([sx.split, sx.flexGapLarge])}>
          <div className={css(sx.column)}>
            <div className={css(sx.row)}>
              <Space align="baseline">
                <Text className={css([sx.longText, sx.titleCaseText])}>Timezone</Text>
                <p className={css(sx.titleCaseText)}>{timeZone === '' ? 'None' : timeZone}</p>
              </Space>
            </div>
            {dataLoaded &&
              days.map(day => {
                const { to, from } = day;
                return (
                  <div key={day.key} className={css([sx.row, sx.titleCaseText])}>
                    <Space align="baseline">
                      <Text className={css(sx.longText)}>{day.name}</Text>
                      {to !== '' && from !== '' ? (
                        <>
                          <p className={css(sx.itemWidthLarge)}>{from}</p>
                          <Text className={css([sx.shortText, sx.itemWidthSmall])}>to</Text>
                          <p className={css(sx.itemWidthLarge)}>{to}</p>
                        </>
                      ) : (
                        <p className={css(sx.itemWidthLarge)}>None</p>
                      )}
                    </Space>
                  </div>
                );
              })}
          </div>
          <div className={css(sx.column)}>
            <div className={css(sx.rowGapLarge)}>
              <Text className={css([sx.longText, sx.titleCaseText])}>Hours Notes</Text>
              <p className={css(sx.titleCaseText)}>{notes === '' ? 'None' : notes}</p>
            </div>
            <div className={css(sx.rowGapLarge)}>
              <Text className={css([sx.longText, sx.upperCaseText])}>ZIPDRUG CHAMPION</Text>
              <p className={css(sx.titleCaseText)}>{champion === '' ? 'None' : champion}</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const hoursView = () => {
    return (
      <>
        <div className={css(sx.split)}>
          <div className={css(sx.division)}>
            <Space className={css(sx.marginBottom10)} align="baseline">
              <Text className={css([sx.longText, sx.titleCaseText])}>Timezone</Text>
              <Select
                disabled={readOnly}
                className={css([sx.timezoneSelect, sx.border])}
                name="timezone"
                value={timeZone}
                onChange={onChangeOfTimezone}
                placeholder="Select"
                bordered={false}
              >
                <Option value="Eastern EST">Eastern EST</Option>
                <Option value="Central PST">Central PST</Option>
                <Option value="Mountain MST">Mountain MST</Option>
                <Option value="Pacific PST">Pacific PST</Option>
              </Select>
            </Space>
            <br />
            <Form name="hours-form" form={form} layout="vertical">
              {dataLoaded &&
                days.map((day, index) => {
                  const { to, from } = day;
                  return (
                    <div key={day.key}>
                      <Space className={css(sx.marginBottom10)} align="baseline">
                        <Text className={css([sx.longText, sx.titleCaseText])}>{day.name}</Text>
                        <div className={css([sx.timePicker, sx.border])}>
                          <Form.Item name={`timeFrom${index}`} trigger="onSelect">
                            <TimePicker
                              allowClear={false}
                              disabled={readOnly}
                              bordered={false}
                              format="h:mm A"
                              use12Hours
                              minuteStep={30}
                              showNow={false}
                              defaultValue={from !== '' ? moment(from, 'h:mm A') : ''}
                              value={moment(from, 'h:mm A')}
                              onSelect={time => onchange(time, day.key, 'from')}
                            />
                          </Form.Item>
                        </div>
                        <Text className={css([sx.shortText, sx.titleCaseText])}>to</Text>
                        <div className={css([sx.timePicker, sx.border])}>
                          <Form.Item name={`timeTo${index}`} trigger="onSelect">
                            <TimePicker
                              allowClear={false}
                              disabled={readOnly}
                              bordered={false}
                              format="h:mm A"
                              use12Hours
                              minuteStep={30}
                              showNow={false}
                              value={moment(to, 'h:mm A')}
                              defaultValue={to !== '' ? moment(to, 'h:mm A') : ''}
                              onSelect={time => onchange(time, day.key, 'to')}
                            />
                          </Form.Item>
                        </div>
                      </Space>
                    </div>
                  );
                })}
            </Form>
          </div>
          <div>
            <Text className={css([sx.longText, sx.titleCaseText])}>Add Hours Notes</Text>
            <div className={css([sx.description, sx.border])}>
              <TextArea
                disabled={readOnly}
                placeholder="Add notes about holidays, special hours, etc…"
                autoSize={{ minRows: 3, maxRows: 3 }}
                bordered={false}
                value={notes}
                onChange={onChangeOfNotes}
              />
            </div>
            <br />
            <Text className={css([sx.longText, sx.upperCaseText])}>ZIPDRUG CHAMPION</Text>
            <div className={css([sx.description, sx.border])}>
              <TextArea
                disabled={readOnly || !(isAscTeam || isBdTeam)}
                placeholder="Add Zipdrug champions here"
                autoSize={{ minRows: 3, maxRows: 3 }}
                bordered={false}
                value={champion}
                onChange={onChangeOfZipdrugChampion}
              />
            </div>
          </div>
        </div>
        <div>
          <Button
            type="primary"
            disabled={readOnly}
            className={css(sx.button)}
            onClick={saveData}
            loading={isAddingHours}
          >
            Save
          </Button>
          <Button onClick={handleCancelAction} className={css(sx.button)}>
            Cancel
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <Card className={css(sx.modalContainer)} getContainer={false}>
        <Title level={4}>{'Hours'}</Title>
        {!readOnly ? <div>{hoursView()}</div> : <div>{readOnlyView()}</div>}
      </Card>
    </>
  );
};

PharmacyHoursTab.propTypes = {
  pharmacyInfo: PropTypes.object,
  isEdit: PropTypes.bool,
  readOnly: PropTypes.bool,
  history: PropTypes.object,
};

export default withRouter(PharmacyHoursTab);
