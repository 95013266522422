import gql from 'graphql-tag';

const UPDATE_PHARMACY_ACTIVE_STATUS = gql`
  mutation bulkUpdatePharmacyHoldStatus($pharmacyIds: [Int]!, $modifiers: HoldModifiers) {
    bulkUpdatePharmacyHoldStatus(pharmacyIds: $pharmacyIds, modifiers: $modifiers) {
      data {
        id
        status
        zipdrug_active_comment
      }
    }
  }
`;

export default UPDATE_PHARMACY_ACTIVE_STATUS;
