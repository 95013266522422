import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';
import { Column, Div, Row, space } from '@zipdrug/ui';
import Page from '../ui/Page';
import SettingsSidebarContainer from './SettingsSidebarContainer';

const sx = StyleSheet.create({
  container: {
    height: '100%',
  },
  content: {
    maxWidth: '100rem',
    paddingBottom: space.get(10),
  },
  contentWrapper: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
  },
  header: {
    paddingLeft: space.get(1.5),
  },
});

const SettingsPageTemplate = ({ children, styles }) => (
  <Page direction="column">
    <Row styles={sx.container}>
      <SettingsSidebarContainer />
      <Div styles={sx.contentWrapper}>
        <Column styles={[sx.content, styles]}>{children}</Column>
      </Div>
    </Row>
  </Page>
);

SettingsPageTemplate.propTypes = {
  children: PropTypes.node,
  styles: aphroditePropType,
};

export default SettingsPageTemplate;
