import { border, colors, Div, Dot, font, margin, padding, Row, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import PropTypes from 'prop-types';

export const ClearFiltersButtonId = 'js-patientListClearFiltersButton';

const sx = StyleSheet.create({
  clearFilterButton: {
    color: colors.white,
    textDecoration: 'underline',
    cursor: 'pointer',
    ':hover': { opacity: 0.75 },
  },
  container: {
    ...border.build('bottom', { color: colors.gray27 }),
    ...margin.build(0, space.default),
    cursor: 'default',
    fontSize: font.size.small,
    height: space.get(1.5),
    userSelect: 'none',
  },
  searchContainer: padding.build(space.default, space.default, font.size.xSmall),
  tabs: border.build('bottom', { color: colors.gray27 }),
});

const SearchResultsListHeader = ({ filterCount, onClearFilters, totalPatients, search }) => {
  let titleText = totalPatients || 'No';
  let memberText = 'members';
  if (filterCount === 0 && !search) titleText = 'All';
  if (totalPatients === 1) memberText = 'member';
  return (
    <Row align="center" justify="space-between" styles={sx.container}>
      <Div style={{ color: colors.gray45 }}>{`${titleText}  ${memberText}`}</Div>
      {!!filterCount && (
        <Row align="center">
          <Div style={{ color: colors.white }}>{`${filterCount} filters`}</Div>
          <Dot color={colors.gray40} />
          <Div
            id={ClearFiltersButtonId}
            onClick={onClearFilters}
            styles={sx.clearFilterButton}
            title="Clear all filters"
          >
            Clear
          </Div>
        </Row>
      )}
    </Row>
  );
};

SearchResultsListHeader.propTypes = {
  filterCount: PropTypes.number.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  totalPatients: PropTypes.number,
  search: PropTypes.string,
};

export default SearchResultsListHeader;
