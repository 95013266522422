import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import { get, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dotdotdot from 'react-dotdotdot';
import {
  border,
  Button,
  colors,
  Div,
  Dot,
  FlexCentered,
  font,
  formatAddress,
  Icon,
  padding,
  Row,
  space,
  transition,
  TruncateText,
} from '@zipdrug/ui';

const CONTENT_TRANSITION_DELAY = 25;
const CONTENT_TRANSITION_DURATION = 200;
const CONTENT_TRANSITION = transition.build({
  delay: CONTENT_TRANSITION_DELAY,
  duration: CONTENT_TRANSITION_DURATION,
});

const sx = StyleSheet.create({
  arrowButton: {
    ...transition.build({
      delay: CONTENT_TRANSITION_DELAY * 5,
      duration: CONTENT_TRANSITION_DURATION * 2,
      ease: 'ease-out',
      property: 'opacity',
    }),
    color: colors.theme.primary.default,
    cursor: 'pointer',
    flex: 'none',
    height: font.size.large,
    position: 'relative',
    width: '100%',
    willChange: 'opacity',
    ':hover': {
      backgroundColor: colors.alpha(colors.gray80, 0.25),
      color: colors.theme.primary.hover,
    },
    ':active': {
      backgroundColor: colors.gray87,
      color: colors.theme.primary.active,
    },
  },
  arrowIcon: {
    height: '0.8rem',
    width: '0.4rem',
  },
  arrowButtonsContainer: {
    ...border.build('right'),
    alignSelf: 'stretch',
    backgroundColor: colors.gray98,
    flex: 'none',
    position: 'relative',
    width: space.get(1.5),
  },
  button: {
    alignSelf: 'flex-start',
    flex: 'none',
    marginTop: font.size.small,
  },
  container: {
    ...border.build('bottom'),
    overflow: 'hidden',
    paddingRight: font.size.small,
    ':last-of-type': {
      borderBottomWidth: 0,
    },
  },
  content: {
    ...padding.build(font.size.small),
    flex: 1,
    maxWidth: '21rem',
    position: 'relative',
    width: '100%',
  },
  editButton: {
    ...CONTENT_TRANSITION,
    color: colors.theme.primary.default,
    cursor: 'pointer',
    userSelect: 'none',
  },
  editButtonDot: CONTENT_TRANSITION,
  header: {
    marginBottom: 4,
  },
  indexLabel: {
    color: colors.gray62,
    cursor: 'default',
    flex: 'none',
    lineHeight: font.lineHeight.tight,
    userSelect: 'none',
  },
  addressNote: {
    fontStyle: 'italic',
    marginTop: space.half,
  },
  addressText: {
    color: colors.gray62,
    fontSize: font.size.xSmall,
    lineHeight: font.lineHeight.loose,
  },
  addressLine: {
    whiteSpace: 'nowrap',
  },
});

export default class AddressListItem extends Component {
  static propTypes = {
    address: PropTypes.object,
    index: PropTypes.number,
    label: PropTypes.string,
    onClickEdit: PropTypes.func,
    onClickOrder: PropTypes.func,
    onUpdateAddressOrder: PropTypes.func,
    styles: aphroditePropType,
  };

  state = { isHover: false };

  handleArrowDownClick = () => this.handleUpdateAddressOrder(-1);
  handleArrowUpClick = () => this.handleUpdateAddressOrder(1);

  handleUpdateAddressOrder = direction =>
    this.props.onUpdateAddressOrder({
      direction,
      order: get(this.props, 'address.order'),
    });

  handleClickEdit = () => {
    this.props.onClickEdit({
      ...this.props.address,
      index: this.props.index,
    });
  };

  handleMouseEnter = () => this.setState({ isHover: true });
  handleMouseLeave = () => this.setState({ isHover: false });
  handeMouseMove = () => {
    if (!this.state.isHover) {
      this.setState({ isHover: true });
    }
  };

  calcHoverOpacity = () => ({ opacity: this.state.isHover ? 1 : 0 });

  renderArrowButtons = () => (
    <FlexCentered direction="column" styles={sx.arrowButtonsContainer}>
      {this.renderArrowIcon('up')}
      <Div styles={sx.indexLabel}>{this.props.index + 1}</Div>
      {this.renderArrowIcon('down')}
    </FlexCentered>
  );

  renderArrowIcon = direction => (
    <FlexCentered
      onClick={this[`handleArrow${upperFirst(direction)}Click`]}
      style={{ opacity: this.state.isHover ? 1 : 0 }}
      styles={sx.arrowButton}
    >
      <Icon
        direction={direction}
        filled
        name="caret"
        style={{ opacity: this.state.isHover ? 1 : 0 }}
        styles={sx.arrowIcon}
      />
    </FlexCentered>
  );

  renderContent = () => {
    const { city, label, line1, line2, note, postal_code, state } = this.props.address;

    return (
      <Div styles={sx.content}>
        <Row align="center" styles={sx.header}>
          <TruncateText styles={sx.label}>{label}</TruncateText>
          <Row align="center">
            <Dot style={this.calcHoverOpacity()} styles={sx.editButtonDot} />
            {this.renderEditButton()}
          </Row>
        </Row>
        <Div styles={sx.addressText}>
          <Div className="fs-hide" styles={sx.addressLine} data-id="e2e-addressContent">
            {formatAddress({ line1, line2 })}
          </Div>
          <Div className="fs-hide" styles={sx.addressLine}>
            {formatAddress({ city, state, postal_code })}
          </Div>
          {note && (
            <Div styles={sx.addressNote}>
              <Dotdotdot clamp={6}>{note}</Dotdotdot>
            </Div>
          )}
        </Div>
      </Div>
    );
  };

  renderEditButton = () => (
    <Div
      id="e2e-addressListItemEdit"
      onClick={this.handleClickEdit}
      style={{
        ...this.calcHoverOpacity(),
        transform: `translateX(${this.state.isHover ? '2px' : 0})`,
      }}
      styles={sx.editButton}
      title="Edit address"
    >
      Edit
    </Div>
  );

  render = () => (
    <Row
      align="center"
      onMouseEnter={this.handleMouseEnter}
      onMouseLeave={this.handleMouseLeave}
      onMouseMove={this.handeMouseMove}
      styles={[sx.container, this.props.styles]}
    >
      {this.renderArrowButtons()}
      {this.renderContent()}
      <Button
        icon="shoppingCart"
        id="e2e-addressListItemDeliverButton"
        onClick={this.props.onClickOrder}
        outline
        styles={sx.button}
        title={`Deliver to ${this.props.label}`}
        type="violet"
      />
    </Row>
  );
}
