import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { uniqBy, isEmpty } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Button, Modal, Typography, Form, Select, Input, message } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { OutcomesContext } from 'contexts/OutcomesProvider';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import { ADD_CALL_OUTCOMES, GET_CALL_OUTCOMES } from './queries';

// CSS styles
const sx = StyleSheet.create({
  largeInputBox: { width: '50%' },
  inputBox: { minWidth: '170px', marginRight: '16px' },
  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  modalContainer: {
    minWidth: '583px',
  },
  label: {
    margin: '0 0 2px 0 !important',
    textTransform: 'uppercase',
    fontSize: '12px',
    color: 'black',
  },
  largeText: {
    fontSize: '16px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
  uploadButton: {
    padding: '8px 10px',
    fontSize: '16px',
  },
  errorMessage: {
    color: 'red',
  },
  successMessage: {
    color: 'green',
  },
});

const { Title } = Typography;
const { Option: SelectOption } = Select;
const CallOutcomeModal = ({ isModalOpen, onSuccessCallOutcome, patient, callId, patientId }) => {
  const [form] = Form.useForm();
  const [outcomeTypes, setOutcomeReasonTypes] = useState([]);
  const [reasonsArr, setReasonsArr] = useState([]);
  const [disabledSave, setDisabledSave] = useState(true);
  const [finalFormValues, setFinalFormValues] = useState({});
  const [finalOutcomeValue, setFinalOutcomeValue] = useState('');
  const [pitchedStatus, setPitchedStatus] = useState(false);

  const {
    setOutcomesContext,
    isOutcomesUpdatedInCallContext,
    setOutcomesUpdatedInCallContext,
    setIsEnrollStatusUpdatedContext,
    updatedPharmacyContext,
  } = useContext(OutcomesContext);

  const { callIdContext } = useContext(CallCenterContext);

  const outcomesTriggerEnrollmentChange = {
    Disqualified: 'not_interested',
    NotInterested: 'not_interested',
    Enrolled: 'interested',
    ZDPharmacyAbrasion: 'not_interested',
  };

  const [getOutcomeTypes] = useLazyQuery(GET_CALL_OUTCOMES, {
    fetchPolicy: 'network-only',
    onError: error => {
      message.error(`Error Fetching Status Outcomes Information: ${error.message}`);
    },
    onCompleted: ({ outcomeReasonTypes }) => {
      // pre fill modal outcome if enroll_status was changed during call
      if (!isEmpty(isOutcomesUpdatedInCallContext)) {
        const outcomeObjFromCall = { ...isOutcomesUpdatedInCallContext };
        form.setFieldsValue(outcomeObjFromCall);
        filterOutcomeTypes(outcomeReasonTypes?.data, outcomeObjFromCall.status);
        setOutcomesUpdatedInCallContext({});
      } else {
        filterOutcomeTypes(outcomeReasonTypes?.data, outcomeReasonTypes?.data[0].outcome);
      }
      checkValues();
    },
  });

  const [addCallOutcome] = useMutation(ADD_CALL_OUTCOMES, {
    onError: error => {
      message.error(`Error Creating Call Outcome: ${error.message}`);
    },
    onCompleted: ({ createCallOutcome }) => {
      form.resetFields();
      setOutcomesContext(finalFormValues);
      const enrollmentChange = outcomesTriggerEnrollmentChange[finalOutcomeValue.replace(/ /g, '')];
      message.success(
        !enrollmentChange
          ? `Call outcome changed to ${finalOutcomeValue}`
          : `Call outcome and Enrollment status changed to ${finalOutcomeValue}`,
      );
      if (enrollmentChange && createCallOutcome?.data?.patient_id === patientId) {
        setIsEnrollStatusUpdatedContext(enrollmentChange);
      }
      setPitchedStatus(false);
      onSuccessCallOutcome();
    },
  });

  const submitForm = () => {
    const newFormValues = form.getFieldsValue();
    const formValues = {
      callId: callId ? parseInt(callId, 10) : callIdContext,
      outcomeReasonTypeIds: newFormValues.reasons,
      notes: newFormValues.notes,
    };
    setFinalOutcomeValue(newFormValues.status);
    setFinalFormValues(formValues);
    form
      .validateFields()
      .then(() => {
        addCallOutcome({
          variables: formValues,
        });
      })
      .catch(info => {
        message.error('Unable to submit form ', info);
      });
  };

  const changeReasonsDropdown = (outcomeTypes, outcome) => {
    setReasonsArr(outcomeTypes.filter(o => o.outcome === outcome));
  };

  const updateDropdownValues = values => {
    // Reset selected reasons on change of status or pitched/not pitched fields
    const changedField = Object.keys(values)[0];
    if (changedField === 'status' || changedField === 'pitched') {
      form.resetFields(['reasons']);
    }

    // Set options for Reasons field
    if (values?.status === 'Not Interested') {
      setPitchedStatus(true);
      changeReasonsDropdown(outcomeTypes, values.status);
    } else if (values?.pitched === 'pitched') {
      setReasonsArr(
        outcomeTypes.filter(type => type.pitched === 'pitched' || type.pitched === 'both'),
      );
    } else if (values?.pitched === 'not_pitched') {
      setReasonsArr(
        outcomeTypes.filter(type => type.pitched === 'not_pitched' || type.pitched === 'both'),
      );
    } else if (changedField === 'status') {
      form.resetFields(['pitched']);
      setPitchedStatus(false);
      changeReasonsDropdown(outcomeTypes, values?.status);
    }
    checkValues();
  };

  const filterOutcomeTypes = (outcomeTypes, outcome) => {
    // filter out pharmacy abrasion if patient has no assigned pharmacy
    if (!patient?.pharmacyInfo?.pharmacyId || !updatedPharmacyContext) {
      setOutcomeReasonTypes(outcomeTypes.filter(o => o.outcome !== 'ZD Pharmacy Abrasion'));
    } else {
      setOutcomeReasonTypes(outcomeTypes);
    }
    changeReasonsDropdown(outcomeTypes, outcome);
  };

  const checkValues = () => {
    let emptyBoolean = false;
    const newFormValues = form.getFieldsValue();
    emptyBoolean = !!newFormValues.status && newFormValues.reasons?.length > 0;
    emptyBoolean = newFormValues.reasons?.length > 0;
    setDisabledSave(!emptyBoolean);
  };

  useEffect(() => {
    if (isModalOpen) {
      getOutcomeTypes({
        variables: {
          query: {
            call_outcome_type: true,
          },
        },
      });
    }
  }, [isModalOpen]);

  const getPitchedLabel = pStatus => {
    switch (pStatus) {
      case 'pitched':
        return 'Pitched';
      case 'not_pitched':
        return 'Not Pitched';
      default:
        break;
    }
  };

  const selectPitchedType = (outcomeTypesArr, type) => {
    const pitchedTypeArr = uniqBy(outcomeTypesArr, type).filter(
      isPitched => Boolean(isPitched.pitched) && isPitched.pitched !== 'both',
    );
    return (
      <Select size="large" key="pitched-select" placeholder="<Select>">
        {pitchedTypeArr.map(pStatus => (
          <SelectOption value={pStatus.pitched} key={pStatus.id}>
            {getPitchedLabel(pStatus.pitched)}
          </SelectOption>
        ))}
      </Select>
    );
  };

  return (
    <Modal
      closable={false}
      onOk={() => submitForm()}
      visible={isModalOpen}
      okText="Save"
      className={css(sx.modalContainer)}
      footer={[
        <Button
          key="submit"
          type="primary"
          disabled={disabledSave}
          onClick={() => submitForm()}
          className={css(sx.button)}
        >
          Save
        </Button>,
      ]}
      getContainer={false}
    >
      <Title className="modelHeaderText" level={3}>
        Mark Call Outcome
      </Title>
      <Form
        name="modify-form-ref"
        form={form}
        layout="vertical"
        onValuesChange={changedValues => updateDropdownValues(changedValues)}
      >
        <div className={css(sx.flexContainer)}>
          <div className={css(sx.inputBox)}>
            <p className={css(sx.label)}>Call Outcome</p>
            <Form.Item name="status" rules={[{ required: true, message: 'Status is required.' }]}>
              {outcomeTypes.length > 0 && (
                <Select size="large" key="status-select" placeholder="<Select>">
                  {uniqBy(outcomeTypes, 'outcome').map(status => (
                    <SelectOption
                      value={status.outcome}
                      key={`${status.outcome}-${status.outcome_reason}`}
                    >
                      {status?.outcome}
                    </SelectOption>
                  ))}
                </Select>
              )}
            </Form.Item>
          </div>
          {pitchedStatus ? (
            <div className={css(sx.inputBox)}>
              <p className={css(sx.label)}>Pitched/Not Pitched</p>
              <Form.Item
                name="pitched"
                rules={[{ required: true, message: 'Pitched/Not Pitched is required.' }]}
                placeholder="<Select>"
              >
                {selectPitchedType(outcomeTypes, 'pitched')}
              </Form.Item>
            </div>
          ) : null}
        </div>
        <div>
          <p className={css(sx.label)}>Select Reasons (Can Select Multiple)</p>
          <Form.Item name="reasons" rules={[{ required: true, message: 'Reasons are required.' }]}>
            <Select
              size="large"
              key="reasons-select"
              mode="multiple"
              showArrow
              placeholder="<Select>"
            >
              {reasonsArr.map(reason => (
                <SelectOption value={reason.id} key={`${reason.id}-${reason.outcome_reason}`}>
                  {reason?.outcome_reason}
                </SelectOption>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <p className={css(sx.label)}>Notes</p>
          <Form.Item name="notes">
            <Input.TextArea />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

CallOutcomeModal.propTypes = {
  patient: PropTypes.object,
  callId: PropTypes.number,
  patientId: PropTypes.number,
  isModalOpen: PropTypes.bool,
  onSuccessCallOutcome: PropTypes.func,
};

export default CallOutcomeModal;
