import { sortList } from '@zipdrug/ui';
import { get, groupBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import withActivePharmacies from '../../hoc/withActivePharmacies';
import withPlans from '../../hoc/withPlans';
import withRoles from '../../hoc/withRoles';
import FilterForm from './FilterForm';

const DEFAULT_STATUS_OPTIONS = [
  { label: 'active', value: 'active' },
  { label: 'enrolled', value: 'interested' },
];

const STATUS_BUTTON_MAP = [
  { label: 'active', value: 'active' },
  { label: 'churned', value: 'churned' },
  { label: 'enrolled', value: 'interested' },
  { label: 'not_interested', value: 'not_interested' },
  { label: 'outreached', value: 'outreached' },
];

const INITIAL_VALUES = {
  alert_type: [],
  enroll_status: [],
  language: [],
  pdc: [],
};

const ReduxFilterForm = reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: 'filter',
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
})(FilterForm);

class FilterFormContainer extends Component {
  static propTypes = {
    activePharmacies: PropTypes.arrayOf(PropTypes.object),
    hasRole: PropTypes.object,
    plans: PropTypes.arrayOf(PropTypes.object),
  };

  buildEnrollmentStatuses = () => {
    const { pharmacistUser, pharmacistAdmin } = this.props.hasRole;
    return !pharmacistUser && !pharmacistAdmin ? STATUS_BUTTON_MAP : DEFAULT_STATUS_OPTIONS;
  };

  buildInitialValues = () => ({
    ...INITIAL_VALUES,
    assigned_pharmacy_id: get(this.buildPharmacyItems(), '[0]', []),
    plan_id: get(this.buildPharmacyItems(), '[0]', []),
  });

  buildPharmacyItems = () => {
    const pharmacies = sortList(this.props.activePharmacies, 'name');
    const pharmacyItems = pharmacies.map(pharmacy => ({
      label: pharmacy.name,
      value: pharmacy.id,
    }));

    const allActivePharmacyIds = pharmacyItems.map(({ value }) => value);

    return [{ label: 'All', value: allActivePharmacyIds }, ...pharmacyItems];
  };

  buildPlanItems = () => {
    const plans = sortList(this.props.plans, 'name');

    const planItems = Object.values(groupBy(plans, plan => plan.name)).map(group => ({
      label: group[0].name,
      value: group.map(p => p.id),
    }));

    return [{ label: 'All', value: plans.map(p => p.id) }, ...planItems];
  };

  render = () => (
    <ReduxFilterForm
      {...this.props}
      enrollmentStatusOptions={this.buildEnrollmentStatuses()}
      initialValues={this.buildInitialValues()}
      pharmacyOptions={this.buildPharmacyItems()}
      planOptions={this.buildPlanItems()}
    />
  );
}

export default compose(withRoles, withActivePharmacies, withPlans)(FilterFormContainer);
