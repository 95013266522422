import { get } from 'lodash';
import { connect } from 'react-redux';
import { setShowSnoozed } from '../redux/queryReducer';

const mapDispatchToProps = dispatch => ({
  onToggleShowSnoozed(showSnoozedBool) {
    dispatch(setShowSnoozed({ showSnoozed: showSnoozedBool }));
  },
});

const mapStateToProps = state => ({
  showSnoozed: get(state, 'query.patients.showSnoozed'),
});

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
