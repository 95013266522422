import { colors, font, padding, space, Tabs } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';

export const TABS = {
  inbox: 'inbox',
  snoozed: 'snoozed',
};

const sx = StyleSheet.create({
  container: {
    ...padding.build(0, space.default),
    color: colors.white,
    fontSize: font.size.small,
    height: space.get(1.5),
  },
});

const PatientListTabs = ({ onChange, showSnoozed, styles }) => (
  <Tabs
    items={Object.values(TABS)}
    onChange={onChange}
    selectedTab={showSnoozed ? TABS.snoozed : TABS.inbox}
    styles={[sx.container, styles]}
  />
);

PatientListTabs.propTypes = {
  onChange: PropTypes.func,
  showSnoozed: PropTypes.bool,
  styles: aphroditePropType,
};

export default PatientListTabs;
