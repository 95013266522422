/* eslint-disable no-case-declarations */
import cloneDeep from 'lodash/cloneDeep';

const defaultQueryVariables = {
  query: {},
};

const generatePagination = pagination => {
  return {
    limit: pagination?.pageSize || 25,
    skip: pagination?.current > 0 ? (pagination?.current - 1) * pagination?.pageSize : 0,
  };
};

const generateQuery = filters => {
  if (!filters) {
    return { ...defaultQueryVariables };
  }

  return {
    query: {
      ...Object.entries(filters).reduce(
        (acc, curr) => {
          if (curr[1]) {
            switch (curr[0]) {
              case 'zipcode':
                acc.postal_code = { $ilike: `${curr[1]}%` };
                acc.potential_postal_code = { $ilike: `${curr[1]}%` };
                return acc;
              case 'potentialPharmacies':
                acc.pharmacyQuery = {
                  name: { $ilike: `%${curr[1]}%` },
                  is_potential: true,
                };
                return acc;
              case 'anthemAssignedPharmacies':
                acc.pharmacyQuery = {
                  name: { $ilike: `%${curr[1]}%` },
                  is_potential: false,
                };
                acc.payerQuery = {
                  name: 'Anthem',
                };
                return acc;
              case 'state':
                acc.stateQuery = curr[1][0];
                return acc;
              default:
                return acc;
            }
          }
          return acc;
        },
        {
          ...defaultQueryVariables.query,
        },
      ),
    },
  };
};

const generateVariables = actions => {
  const { filters, pagination } = cloneDeep(actions);

  const pageOptions = generatePagination(pagination);
  const query = filters ? generateQuery(filters) : { ...defaultQueryVariables };

  return {
    ...pageOptions,
    ...query,
  };
};

export default generateVariables;
