import { border, colors, Div, font, padding, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import FilterAlertsFieldContainer from './FilterAlertsFieldContainer';
import FilterCheckboxGroupField from './FilterCheckboxGroupField';
import FilterMedicationsField from './FilterMedicationsField';
import FilterSelectField from './FilterSelectField';

const sx = StyleSheet.create({
  alertFormStyle: {
    paddingTop: space.default,
    ...border.build('bottom', { color: colors.bluePurple }),
  },
  enrollmentCheckboxItems: {
    marginRight: space.default,
  },
  formField: {
    ...border.build('bottom', { color: colors.bluePurple }),
    ...padding.build(space.default, 0),
    paddingBottom: space.default,
    ':last-child': { borderBottom: 'none' },
  },
  formFieldStart: {
    ...border.build('bottom', { color: colors.bluePurple }),
    paddingBottom: space.default,
    paddingTop: space.default,
  },
  headerLabel: {
    color: 'white',
  },
  pharmacyMenuStyles: {
    maxHeight: '28rem',
    overflowY: 'auto',
    position: 'relative !important',
  },
});

const FORM_PROPS = {
  preserveDimensions: false,
  styles: sx.formField,
};

const formatEnrollmentStatus = status => upperFirst(status.replace(/_/g, ' '));

const FilterForm = ({
  enrollmentStatusOptions,
  pharmacyOptions,
  planOptions,
  hasRole: { pharmacistUser, pharmacistAdmin },
}) => (
  <Div>
    <p style={{ color: 'white', fontSize: '1.3rem', fontWeight: font.weight.boldSemi }}>
      {' '}
      Sort by:{' '}
    </p>
    <FilterSelectField
      preserveDimensions={false}
      styles={sx.formFieldStart}
      items={[
        { label: 'Alphabetically Ascending', value: 'alphabetically' },
        { label: 'Latest Notes and Updates', value: 'latest_activity' },
        { label: 'Missed Calls', value: 'latest_alert' },
        { label: 'Latest Enrollment Date', value: 'enrollment_date' },
      ]}
      menuStyles={sx.pharmacyMenuStyles}
      name="sort_by"
      defaultSelectedItem={{ label: 'Latest Enrollment Date', value: 'enrollment_date' }}
    />
    <p
      style={{
        color: 'white',
        paddingTop: space.default,
        fontSize: '1.3rem',
        fontWeight: font.weight.boldSemi,
      }}
    >
      {' '}
      Filter by:{' '}
    </p>
    <FilterAlertsFieldContainer
      preserveDimensions={false}
      styles={sx.alertFormStyle}
      label="Alerts"
      name="alert_type"
    />
    <FilterMedicationsField {...FORM_PROPS} label="medications" name="pdc" />
    <FilterCheckboxGroupField
      {...FORM_PROPS}
      id="e2e-language-filter"
      label="language"
      name="language"
      values={['english', 'spanish']}
    />
    {!pharmacistUser && !pharmacistAdmin && (
      <FilterSelectField
        {...FORM_PROPS}
        items={pharmacyOptions}
        label="pharmacy"
        menuStyles={sx.pharmacyMenuStyles}
        name="assigned_pharmacy_id"
      />
    )}
    {!pharmacistUser && !pharmacistAdmin && (
      <FilterSelectField
        {...FORM_PROPS}
        items={planOptions}
        label="plan"
        menuStyles={sx.pharmacyMenuStyles}
        name="plan_id"
      />
    )}
    <FilterCheckboxGroupField
      {...FORM_PROPS}
      formatLabel={formatEnrollmentStatus}
      id="e2e-enrollment-filter"
      itemStyles={sx.enrollmentCheckboxItems}
      label="enrollment"
      name="enroll_status"
      values={enrollmentStatusOptions}
      wrap
    />
  </Div>
);

FilterForm.propTypes = {
  enrollmentStatusOptions: PropTypes.array,
  pharmacyOptions: PropTypes.arrayOf(PropTypes.object),
  planOptions: PropTypes.arrayOf(PropTypes.object),
  hasRole: PropTypes.object,
};

export default FilterForm;
