import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatPhone } from '@zipdrug/ui';
import { Tooltip } from 'antd';
import { StyleSheet, css } from 'aphrodite/no-important';
import { COLORS } from './constants';
import { HoldIcon, UnholdIcon, HangupIcon, InboundIcon, HangupIconColor } from './svg';
import commonSx from './styles';

const sx = StyleSheet.create({
  onCallContainer: {
    display: 'flex',
    marginLeft: '15px',
    flexDirection: 'row',
    borderRadius: '10px',
    border: '1px solid green',
    background: COLORS.LIGHT_GREEN,
    alignItems: 'center',
    color: COLORS.GREEN,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    display: 'flex',
    alignItems: 'center',

    height: '100%',
    padding: '5px 0px',
    borderRadius: '0px 6px 6px 0px',
  },
  timer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: '5px 15px',
  },
  borderRight: {
    borderRight: '1px solid green',
  },
  patientInfo: {
    marginLeft: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  holdAndHangup: {
    width: '30px',
    height: '30px',
    borderRadius: '6px',
    border: 'none',
    outline: 'none',
    background: COLORS.LIGHT_GREEN,
    padding: '0px',
    cursor: 'pointer',
    ':hover': {
      filter: 'drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.25))',
    },
  },
  itemOnHold: {
    backgroundColor: COLORS.GREEN,
    color: 'white',
  },
  holdBtn: {
    margin: '0px 15px',
  },
  onHold: {
    background: '#035E01', // dark green background for hold btn
  },
  hangupBtn: {
    color: 'red',
    marginRight: '15px',
    ':hover': {
      filter: 'drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.25))',
    },
  },
  verLine: {
    borderRight: '1.3px solid #048802',
    height: '26px',
  },
  verLineWhite: {
    borderRight: '1.3px solid white',
  },
  hover: {
    color: 'white',
  },
  hangupOnHold: {
    color: 'white',
    background: COLORS.GREEN,
    ':hover': {
      filter: 'drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.25))',
      color: 'red',
      background: COLORS.LIGHT_GREEN,
    },
  },
  rotate: {
    transform: 'rotate(180deg)',
    paddingTop: '3px',
  },
  dialingHangup: {
    marginLeft: '10px',
  },
  callerName: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
});

function calculateOnCallTime(onCallStartTime) {
  if (!onCallStartTime) {
    return null;
  }

  const totalMin = Math.floor((Date.now() - onCallStartTime) / 1000);

  const hour = Math.floor(totalMin / 60);
  let min = totalMin % 60;
  min = min < 10 ? `0${min}` : min;

  return `${hour}:${min}`;
}

function OnCall({
  currentClient,
  onCallStartTime,
  names = [],
  phones = [],
  onCallTypes = [],
  callSids = [],
  dialingNames,
  dialingNumbers,
  cancelDialingName,
  holdModifiers = [],
  onHangup,
  onHold,
  isSupervising,
}) {
  const [timer, setTimer] = useState(calculateOnCallTime(null));

  useEffect(() => {
    let id;
    if (onCallStartTime && !timer) {
      setTimer(calculateOnCallTime(onCallStartTime));

      id = setInterval(() => {
        setTimer(calculateOnCallTime(onCallStartTime));
      }, 1000);
    }

    return () => {
      clearInterval(id);
    };
  }, [onCallStartTime]);

  let counter = 0;
  // length is less than 1 if it's not supervising since the current client is included in the list.
  const lengthParticipants = isSupervising ? names.length : names.length - 1;

  return (
    <>
      {names && names.length > 0 && (
        <div className={css(sx.onCallContainer)}>
          {timer && (
            <div
              className={css(sx.timer, names.length > 1 || dialingNames ? sx.borderRight : null)}
            >
              On Call: {timer}
            </div>
          )}
          {names.map((name, index) => {
            // We don't want to show the client or the supervisor
            if (phones[index] === currentClient) {
              return null;
            }
            counter += 1;

            const currentlyOnHold = holdModifiers[index];
            const style = {};

            if (counter !== lengthParticipants) {
              style.borderRadius = '0px';
            }

            return (
              <div
                className={css(sx.item, currentlyOnHold ? sx.itemOnHold : null)}
                style={style}
                key={`name${index + 1}`}
              >
                <div
                  className={`${css(sx.patientInfo, isSupervising ? commonSx.mr15 : null)} fs-hide`}
                >
                  <div className={css(sx.callerName)}>{name}</div>
                  {/* We only show numbers for non client participant */}
                  {!phones[index].match('client') && (
                    <div>
                      {onCallTypes[index] === 'pharmacy' ? 'Pharmacy' : formatPhone(phones[index])}
                    </div>
                  )}
                  {phones[index].match('client') && <div>Enroller</div>}
                </div>

                {!isSupervising && (
                  <Tooltip placement="bottom" title={currentlyOnHold ? 'Unhold' : 'Hold'}>
                    <button
                      onClick={() => onHold(callSids[index])}
                      className={css(
                        sx.holdAndHangup,
                        sx.holdBtn,
                        currentlyOnHold ? sx.onHold : null,
                      )}
                    >
                      {currentlyOnHold ? <UnholdIcon /> : <HoldIcon />}
                    </button>
                  </Tooltip>
                )}

                {!isSupervising && (
                  <Tooltip placement="bottom" title="End Call">
                    <button
                      onClick={() => onHangup(callSids[index])}
                      className={css(
                        sx.holdAndHangup,
                        sx.hangupBtn,
                        currentlyOnHold ? sx.hangupOnHold : null,
                      )}
                    >
                      <HangupIconColor />
                    </button>
                  </Tooltip>
                )}
                {/* We only show the vertical line if it's not the last participant or if there's dialing happening */}
                {counter !== lengthParticipants && !dialingNames && (
                  <div className={css(sx.verLine, currentlyOnHold && sx.verLineWhite)} />
                )}
              </div>
            );
          })}
          {/* Render participant that are still dialing */}
          {dialingNames &&
            dialingNames.map((dialingName, index) => (
              <div
                className={css(sx.flexRow, commonSx.alignItemsCenter)}
                key={`dialingName${index + 1}`}
              >
                <div className={css(commonSx.mlr15, sx.rotate)}>
                  <InboundIcon />
                </div>

                <div className={css(commonSx.mr15)}>Calling</div>

                <div className={css(sx.verLine)} />

                <div className={css(sx.patientInfo)}>
                  <div className={css(sx.callerName)}>{dialingName}</div>

                  {/* We only show numbers for non enroller participant */}
                  {dialingNumbers &&
                    dialingNumbers[index] &&
                    !dialingNumbers[index].match('client') && (
                      <div>{formatPhone(dialingNumbers[index])}</div>
                    )}
                </div>

                <Tooltip placement="bottom" title="End Call">
                  <button
                    onClick={cancelDialingName}
                    className={css(sx.holdAndHangup, sx.hangupBtn, sx.dialingHangup)}
                  >
                    <HangupIcon color="red" />
                  </button>
                </Tooltip>
              </div>
            ))}
        </div>
      )}
    </>
  );
}

OnCall.propTypes = {
  onCallStartTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  currentClient: PropTypes.string,
  names: PropTypes.array,
  phones: PropTypes.array,
  onCallTypes: PropTypes.array,
  callSids: PropTypes.array,
  holdModifiers: PropTypes.array,
  dialingNames: PropTypes.array,
  dialingNumbers: PropTypes.array,
  cancelDialingName: PropTypes.func,
  onHangup: PropTypes.func,
  onHold: PropTypes.func,
  isSupervising: PropTypes.bool,
};

export default OnCall;
