import _get from 'lodash/get';
import { Role } from 'contexts/utils/models';
import { message } from 'antd';
import {
  GET_TWILIO_TOKEN,
  GET_ENROLLERS,
  GET_CALL_RECORD,
  GET_PHONES_WITH_ASSOCIATIONS,
} from './queries';

// Get phone records
export const getPhoneRecordsQuery = async (apolloClient, number) => {
  // Make an api call to get the inbound caller information.
  const phoneRecords = await apolloClient.query({
    query: GET_PHONES_WITH_ASSOCIATIONS,
    variables: { query: { number } },
    fetchPolicy: 'network-only',
  });

  return _get(phoneRecords, 'data.phones.data');
};

// Get list of available enrollers
export const getAvailableEnrollersQuery = async (apolloClient, userId) => {
  const availableEnrollers = await apolloClient.query({
    query: GET_ENROLLERS,
    variables: {
      query: {
        roles: {
          type: {
            $not: [Role.PharmacistUser, Role.PharmacistAdmin],
          },
        },
        call_center_user_status: 'available',
      },
    },
    fetchPolicy: 'network-only',
    onError: error => {
      message.error(`Error getting enrollers: ${error.message}`);
    },
  });

  if (_get(availableEnrollers, 'data.users.data')) {
    const filteredEnrollers = _get(availableEnrollers, 'data.users.data').filter(
      enroller => _get(enroller, 'feature_flags.callCenter') === true,
    );

    const names = [];
    const ids = [];
    const labels = [];
    const phones = [];

    filteredEnrollers.forEach(enroller => {
      if (enroller.id === userId) {
        return null;
      }

      names.push(`${enroller.first_name} ${enroller.last_name}`);
      ids.push(enroller.id);
      labels.push(enroller.first_name);
      phones.push(`client:${enroller.id}`);
    });

    return {
      names,
      ids,
      labels,
      phones,
      primaryIndex: 0,
    };
  }

  return null;
};

export const getTwilioTokenQuery = async apolloClient => {
  const twilioToken = await apolloClient.query({
    query: GET_TWILIO_TOKEN,
    fetchPolicy: 'network-only',
  });

  return _get(twilioToken, 'data.getTwilioToken.data.token');
};

export const getFormattedPhoneAssociations = phones => {
  const { patients, plans, pharmacies, prescribers, unknownPhones } = phones.reduce(
    (acc, phone) => {
      if (phone.patient) {
        return {
          ...acc,
          patients: [
            ...acc.patients,
            {
              ...phone.patient,
              patient_id: phone.patient.id,
              assigned_pharmacy_id: phone.patient.assigned_pharmacy_id,
              phone_id: phone.id,
              phone_label: phone.label,
              label: 'Patient',
              name: `${phone.patient.first_name} ${phone.patient.last_name}`,
              type: 'patient',
            },
          ],
        };
      }
      if (phone?.plan) {
        return {
          ...acc,
          plans: [
            ...acc.plans,
            {
              ...phone.plan,
              plan_id: phone.plan.id,
              name: phone.plan.name,
              phone_id: phone.id,
              phone_label: phone.label,
              label: 'Plan',
              type: 'payer_hotline',
            },
          ],
        };
      }
      if (phone.pharmacy) {
        return {
          ...acc,
          pharmacies: [
            ...acc.pharmacies,
            {
              ...phone.pharmacy,
              name: phone.pharmacy.name,
              pharmacy_id: phone.pharmacy.id,
              phone_id: phone.id,
              phone_label: phone.label,
              label: 'Pharmacy',
              type: 'pharmacy',
            },
          ],
        };
      }
      if (phone.prescriber) {
        return {
          ...acc,
          prescribers: [
            ...acc.prescribers,
            {
              ...phone.prescriber,
              name: phone.prescriber.name,
              prescriber_id: phone.prescriber.id,
              phone_id: phone.id,
              phone_label: phone.label,
              label: 'Prescriber',
              type: 'prescriber',
            },
          ],
        };
      }
      if (phone.is_caller_id) {
        return {
          ...acc,
          unknownPhones: [
            ...acc.unknownPhones,
            {
              name: phone.label,
              phone_id: phone.id,
              phone_label: phone.label,
              label: phone.label,
              type: 'other_external_number',
            },
          ],
        };
      }
      return {
        ...acc,
        unknownPhones: [
          ...acc.unknownPhones,
          {
            phone_id: phone.id,
            phone_label: phone.label,
            label: 'Unknown',
            name: 'Unknown',
            type: 'unknown',
          },
        ],
      };
    },
    {
      patients: [],
      plans: [],
      pharmacies: [],
      prescribers: [],
      unknownPhones: [],
    },
  );

  const bestCandidatePatient =
    patients.filter(patient => patient.sex === 'female')[0] || patients[0];

  const bestCandidate =
    bestCandidatePatient || pharmacies[0] || prescribers[0] || plans[0] || unknownPhones[0];

  return {
    bestCandidate,
    number: phones[0].number,
    patients: patients.length ? patients : undefined,
    plans: plans.length ? plans : undefined,
    pharmacies: pharmacies.length ? pharmacies : undefined,
    prescribers: prescribers.length ? prescribers : undefined,
    unknownPhones: unknownPhones.length ? unknownPhones : undefined,
  };
};

export const getCallRecordQuery = async (apolloClient, callId) => {
  const callRecord = await apolloClient.query({
    query: GET_CALL_RECORD,
    variables: { callId },
    fetchPolicy: 'network-only',
    onError: error => {
      message.error(`Error getting call record: ${error.message}`);
    },
  });

  return _get(callRecord, 'data.getCallRecord.data');
};

export const buildPatientInfo = patientData => {
  // set up patient phones
  const patientName = `${patientData.first_name} ${patientData.last_name}`;
  const primaryPhoneIndex = 0;
  const labels = [];
  const phones = [];
  const phoneIds = [];

  // sort phones by order
  const sortedPhones = [...patientData.phones].sort((a, b) => a.order - b.order);
  sortedPhones.forEach((phone, index) => {
    const { id, label, number } = phone;
    labels[index] = label;
    phones[index] = number;
    phoneIds[index] = id;
  });

  return {
    patientName,
    primaryPhoneIndex,
    labels,
    phones,
    phoneIds,
    pharmacyInfo: {
      pharmacyId:
        _get(patientData, 'assigned_pharmacy_id') || _get(patientData, 'assigned_pharmacy.id'),
      name: _get(patientData, 'assigned_pharmacy.name'),
      status: _get(patientData, 'assigned_pharmacy.status'),
    },
  };
};
