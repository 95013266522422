import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const queryPharmacies = gql`
  query pharmacies($limit: Int, $query: JSON, $skip: Int) {
    pharmacies(limit: $limit, query: $query, skip: $skip) {
      data {
        id
        canAccessTasks
        name
        onfleet_id
        npi
        maintenance_threshold
        pharmacy_asc_id
        parent_pharmacy {
          name
          npi
        }
      }
      limit
      skip
      total
    }
  }
`;
