import { compose } from 'recompose';
import PatientList from './PatientList';
import withSelectedPatientId from '../../hoc/withSelectedPatientId';
import withSelectPatient from '../../hoc/withSelectPatient';
import withSnoozedPatientsVisibility from '../../hoc/withSnoozedPatientsVisibility';

export default compose(
  withSelectPatient,
  withSelectedPatientId,
  withSnoozedPatientsVisibility,
)(PatientList);
