/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Table, Button, message, Tooltip, Typography, Space } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { PharmacyListContext, ActionTypes } from 'contexts/PharmacyListProvider';
import filterSearchBox from '../Filters/Search/Search';
import { GET_PHARMACY_DATA } from '../../graphql/getPharmacyList';
import GET_PHARM_NETWORK_TYPES from '../../graphql/getPharmacyNetworkTypes';
import UPDATE_PHARMACY_ACTIVE_STATUS from '../../graphql/updatePharmacyActiveStatus';
import states from '../../constants/states';
import statuses from '../../constants/status';
import generateVariables from '../../utils/GenerateVariables.utils';
import NewTag from '../../../../../ui/NewTag/NewTag';
import formatPhoneNumber from '../../utils/FormatPhone.utils';
import ChainPharmacyList from '../ChainPharmacyTable/ChainPharmacyTable';
import PharmacyOnHoldConfirmationModal from '../PharmacyOnHoldConfirmationModal/PharmacyOnHoldConfirmationModal';
import withRoles from '../../../../../hoc/withRoles';
import GET_TEAM_LEADS from '../../../EnrollersList/graphql/getTeamLeads';

const { Text } = Typography;

const sx = StyleSheet.create({
  statusSelect: { width: '133px' },
  tableContainer: { width: 'auto', minWidth: 'unset !important' },
  tableOptionsContainer: {
    marginBottom: '20px',
  },
  tableOptionsButtonMargin: {
    marginRight: '16px',
  },
  expandButton: {
    paddingTop: 0,
    paddingLeft: '5px',
    marginTop: 0,
    height: '25px',
  },
  disabledRow: {
    background: '#f5f5f5',
    opacity: '0.5',
    cursor: 'no-drop',
  },
  inactivityCommentMargin: {
    paddingTop: '10px',
  },
  columnTitleIconMargin: {
    marginRight: '8px',
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    wordBreak: 'keep-all',
  },
  lobTitle: {
    fontSize: '12px',
  },
  networkContainer: {
    marginBottom: '4px',
    lineHeight: '1.3',
  },
  newPharmacy: {
    border: '1px solid #D9D9D9',
    color: 'rgba(0,0,0,0.65)',
  },
});

const defaultSelectedPharmacies = {
  pharmacyIds: [],
  pharmacyRecords: [],
};

const LOB = {
  MCAID: 'Medicaid',
  COM: 'Commercial',
  MA: 'Medicare',
};

const PharmacyList = ({ isSystemAdmin, isPharmacyTeam, isPbsTeam, history }) => {
  // data for the table
  const [data, setData] = useState([]);
  const [, setPharmNetworkTypes] = useState([]);
  const [ascCoordinators, setAscs] = useState([]);
  // modal settings
  const [showUpdatePharmacyModal, setShowUpdatePharmacyModal] = useState(false);
  const [selectedPharmacies, setselectedPharmacies] = useState(defaultSelectedPharmacies);
  const { state, dispatch } = useContext(PharmacyListContext);
  const { tableActions, defaultTableActions } = state;
  // const [parentNpi, setParentNpi] = useState(null);

  const [
    getPharmacyList,
    { loading: isFetchingPharmacyList, data: pharmacyData, refetch: refetchPharmacyList },
  ] = useLazyQuery(GET_PHARMACY_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching pharmacy list: ${error.message}`);
    },
  });

  const [updatePharmacyActiveStatus, { loading: isUpdatingPharmacyActiveStatus }] = useMutation(
    UPDATE_PHARMACY_ACTIVE_STATUS,
    {
      onError: error => {
        message.error(`Error updating pharmacy status: ${error.message}`);
      },
      onCompleted: ({ bulkUpdatePharmacyHoldStatus }) => {
        const clonedData = cloneDeep(data);
        const pharmacyIds = bulkUpdatePharmacyHoldStatus?.data?.map(pharmacy => pharmacy.id);
        // Only getting the first pharmacy as all pharmacies will have the same status and comment if the API was successful.
        const pharmacyActiveStatus = bulkUpdatePharmacyHoldStatus?.data?.[0]?.status;
        // const pharmacyActiveStatusComment =
        //   bulkUpdatePharmacyHoldStatus?.data?.[0]?.zipdrug_active_comment;
        const resultData = clonedData.map(pharmacy => {
          if (pharmacyIds.includes(pharmacy.id)) {
            pharmacy.status = pharmacyActiveStatus;
            // pharmacy.zipdrug_active_comment = pharmacyActiveStatusComment;
          }
          return pharmacy;
        });
        setData(resultData);
        setselectedPharmacies(defaultSelectedPharmacies);
        setShowUpdatePharmacyModal(false);
        message.success('Successfully Updated Pharmacy Status');
      },
    },
  );

  const [getPharmacyNetworkTypes, { loading: isGettingPharmacyNetworkTypes }] = useLazyQuery(
    GET_PHARM_NETWORK_TYPES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onError: error => {
        message.error(`Error fetching pharmacy network types: ${error.message}`);
      },
      onCompleted: ({ pharmacyNetworksGroupedByLob }) => {
        setPharmNetworkTypes(pharmacyNetworksGroupedByLob?.data);
      },
    },
  );

  // query to get Pharmacy ASC admins.
  const [getAscCoordinators] = useLazyQuery(GET_TEAM_LEADS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ users }) => {
      const admins = users?.data.reduce((acc, val) => {
        const { first_name, last_name, id } = val;
        acc.push({
          name: `${first_name} ${last_name}`,
          value: id,
          text: `${first_name} ${last_name}`,
        });
        return acc;
      }, []);
      setAscs(admins);
    },
    onError: error => {
      message.error(`Error fetching Pharmacy ASC admins list: ${error.message}`);
    },
  });

  const handleclick = (isEdit, record) => {
    if (isEdit) {
      history.push({
        pathname: `/settings/pharmacylist/modify`,
        search: `${record?.id}`,
        state: {
          isEdit,
        },
      });
    } else {
      history.push({
        pathname: '/settings/pharmacylist/modify',
        state: {
          isEdit,
        },
      });
    }
  };

  const generateColumns = () => {
    const headers = [
      {
        title: 'Pharmacy Name',
        width: '12%',
        dataIndex: 'name',
        key: 'name',
        ...filterSearchBox('name', 'Pharmacy Name', tableActions?.filters?.name),
        filteredValue: tableActions?.filters?.name || null,
        render: (text, record) => {
          return (
            <Space>
              {`${text}`}
              <NewTag
                created_at={record.created_at}
                toggleToolTip={false}
                style={css(sx.newPharmacy)}
              />
            </Space>
          );
        },
      },
      {
        title: 'NPI',
        dataIndex: 'npi',
        width: '8%',
        key: 'npi',
        ...filterSearchBox('npi', 'NPI', tableActions?.filters?.npi),
        filteredValue: tableActions?.filters?.npi || null,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '8%',
        key: 'status',
        filters: statuses.types,
        filteredValue: tableActions?.filters?.status || null,
        // render: (text, record) => {
        render: text => {
          return (
            <>
              <Text>{statuses.mapping[text]}</Text>
              {/* <br />
              <Text className={css(sx.inactivityCommentMargin)}>
                {record?.zipdrug_active_comment}
              </Text> */}
            </>
          );
        },
      },
      {
        title: 'ASC',
        dataIndex: 'associate_service_coordinator',
        width: '10%',
        key: 'associate_service_coordinator',
        filters: ascCoordinators,
        filteredValue: tableActions?.filters?.associate_service_coordinator || null,
        render: text => {
          return text ? `${text?.first_name} ${text?.last_name}` : null;
        },
      },
      {
        title: 'Address',
        dataIndex: 'address',
        width: '14%',
        key: 'address',
        ...filterSearchBox('address', 'Address', tableActions?.filters?.address),
        filteredValue: tableActions?.filters?.address || null,
        render: text => {
          const street = text?.line2 ? `${text?.line1} ${text?.line2}` : text?.line1;

          if (!street) {
            return null;
          }

          return (
            <span className={'fs-hide'}>
              {street}
              <br />
              {`${text?.city}, ${text?.state}, ${text?.postal_code}`}
            </span>
          );
        },
      },
      {
        title: 'State',
        dataIndex: 'address',
        key: 'state',
        width: '7%',
        filters: states,
        filteredValue: tableActions?.filters?.state || null,
        render: text => {
          return text ? `${text?.state}` : null;
        },
      },
      {
        title: 'Main Line',
        dataIndex: 'phones',
        key: 'phones',
        width: '106px',
        ...filterSearchBox('phones', 'Phone (Main Line)', tableActions?.filters?.phoneNumber),
        filteredValue: tableActions?.filters?.phones || null,
        render: text => {
          const phoneNumber = text?.filter(phone => phone?.label === 'Main Line')?.[0]?.number;
          return phoneNumber ? formatPhoneNumber(phoneNumber) : 'N/A';
        },
      },
      {
        title: 'Networks',
        dataIndex: 'pharmacy_networks',
        key: 'pharmacy_networks',
        width: '10%',
        ellipsis: true,
        render: text => {
          const networkByLob = text.reduce((acc, curr) => {
            const currLob = curr?.network_type?.lob;
            const currNetwork = curr?.network_type?.name;

            acc[currLob] = acc[currLob] ? [...acc[currLob], currNetwork] : [currNetwork];

            return acc;
          }, {});

          return (
            <>
              {Object.keys(networkByLob)
                .sort((a, b) => LOB[a].localeCompare(LOB[b]))
                .map(lob => {
                  const lobName = LOB[lob];
                  const networksArr = networkByLob[lob];
                  return (
                    <div className={css(sx.networkContainer)}>
                      <b className={css(sx.lobTitle)}>{lobName}</b>
                      {networksArr
                        .sort((a, b) => a.localeCompare(b))
                        .map(network => (
                          <Tooltip title={`${network}`}>
                            <div className={css(sx.ellipsis)}>{network}</div>
                          </Tooltip>
                        ))}
                    </div>
                  );
                })}
            </>
          );
        },
      },
      {
        title: 'Payer',
        dataIndex: 'payer',
        key: 'payer',
        width: '10%',
        render: text => {
          return text
            .reduce((acc, { name }) => {
              if (!acc.includes(name)) {
                acc.push(name);
              }
              return acc;
            }, [])
            ?.join(', ');
        },
      },
    ];

    // only pharmacy team and System Admin can edit pharmacies
    headers.push({
      title: '',
      key: 'action',
      width: '6%',
      render: (text, record) => (
        <Button type="link" onClick={() => handleclick(true, record)}>
          {isPharmacyTeam || isSystemAdmin ? 'Edit' : 'View'}
        </Button>
      ),
    });
    return headers;
  };

  const fetchPharmacyList = (payload = cloneDeep(tableActions), refetch = false) => {
    if (payload?.sorter && !payload?.sorter?.order) {
      payload.sorter = null;
    }
    const variables = generateVariables(payload);
    if (refetch) {
      refetchPharmacyList({
        ...variables,
      });
    } else {
      getPharmacyList({
        variables: {
          ...variables,
        },
      });
    }
  };

  useEffect(() => {
    fetchPharmacyList();
    // only pharmacy team, pbs team, and System Admin can view the pharmacy list
    if (isPharmacyTeam || isPbsTeam || isSystemAdmin) {
      getPharmacyNetworkTypes();
    }
  }, []);

  useEffect(() => {
    if (pharmacyData?.pharmacies?.data) {
      getAscCoordinators({
        variables: {
          query: {
            roles: {
              type: {
                $in: ['pharmacy_asc_admin', 'pharmacy_asc'],
              },
            },
          },
        },
      });
      setData(pharmacyData?.pharmacies?.data);
    }
  }, [pharmacyData]);

  const onTableChange = (pagination, filters, sorter, { action }) => {
    dispatch({
      type: ActionTypes.UPDATE_PHARMACYLIST_TABLE_SETTINGS,
      payload: {
        pagination: action === 'paginate' ? pagination : { ...pagination, current: 1 },
        filters,
        sorter,
      },
    });

    // To stay consistent always re-direct user to page 1 on filter/sort.
    if (action === 'paginate') {
      fetchPharmacyList({
        pagination,
        filters,
        sorter,
      });
    } else {
      fetchPharmacyList({
        filters,
        sorter,
      });
    }
  };

  // Useful when you update an value and the filter/sort is not applied.
  const refreshList = () => {
    fetchPharmacyList(tableActions, true);
  };

  const clearAllFilters = () => {
    const payload = {
      pagination: defaultTableActions.pagination,
      filters: null,
      sorter: defaultTableActions.sorter,
    };

    dispatch({
      type: ActionTypes.UPDATE_PHARMACYLIST_TABLE_SETTINGS,
      payload,
    });

    fetchPharmacyList(payload);
  };

  const getTableOptions = () => {
    const tableOptions = {
      columns: generateColumns(),
      dataSource: data,
      size: 'middle',
      onChange: onTableChange,
      rowKey: 'id',
      loading:
        isFetchingPharmacyList || isUpdatingPharmacyActiveStatus || isGettingPharmacyNetworkTypes,
      pagination: {
        defaultPageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: [25, 50, 100],
        total: pharmacyData?.pharmacies?.total || 0,
        current: tableActions?.pagination?.current || 1,
      },
      // only pharmacy team and System Admin can select pharmacies to edit
      rowSelection:
        isPharmacyTeam || isSystemAdmin
          ? {
              type: 'checkbox',
              onChange: (selectedRowKeys, selectedRows) => {
                setselectedPharmacies({
                  pharmacyIds: selectedRowKeys,
                  pharmacyRecords: selectedRows,
                });
              },
              selectedRowKeys: selectedPharmacies.pharmacyIds,
            }
          : null,
      expandable: {
        expandedRowRender: record => <ChainPharmacyList data={record?.chain_pharmacies} />,
        expandIcon: ({ expanded, onExpand, record }) => {
          if (!record?.chain_pharmacies?.length) {
            return null;
          }

          if (expanded) {
            return (
              <Button icon={<MinusOutlined />} type="text" onClick={e => onExpand(record, e)} />
            );
          }

          return (
            <Tooltip title="Chain Pharmacies">
              <Button icon={<PlusOutlined />} type="text" onClick={e => onExpand(record, e)} />
            </Tooltip>
          );
        },
      },
    };

    return tableOptions;
  };

  // const onPharmacyStatusUpdate = (zipdrugActiveStatus, zipdrugActiveComment) => {
  const onPharmacyStatusUpdate = zipdrugActiveStatus => {
    updatePharmacyActiveStatus({
      variables: {
        pharmacyIds: selectedPharmacies?.pharmacyIds,
        modifiers: {
          status: zipdrugActiveStatus,
          // zipdrug_active_comment: zipdrugActiveComment,
        },
      },
    });
  };

  return (
    <>
      <div className={css(sx.tableOptionsContainer)}>
        {(isPharmacyTeam || isSystemAdmin) && (
          <Button
            type="secondary"
            className={css(sx.tableOptionsButtonMargin)}
            onClick={() => handleclick(false, [])}
          >
            Add Pharmacy
          </Button>
        )}
        <Button type="secondary" className={css(sx.tableOptionsButtonMargin)} onClick={refreshList}>
          Refresh List
        </Button>
        <Button
          type="secondary"
          onClick={clearAllFilters}
          className={css(sx.tableOptionsButtonMargin)}
        >
          Clear All Filters
        </Button>

        {/* only pharmacy team and System Admin can edit pharmacies */}
        {(isPharmacyTeam || isSystemAdmin) && (
          <>
            {selectedPharmacies?.pharmacyIds?.length > 0 && (
              <>
                <Button
                  type="secondary"
                  onClick={() => setShowUpdatePharmacyModal(true)}
                  className={css(sx.tableOptionsButtonMargin)}
                >
                  Put On Hold
                </Button>
                <Button
                  type="secondary"
                  onClick={() => onPharmacyStatusUpdate(true, '')}
                  className={css(sx.tableOptionsButtonMargin)}
                >
                  Make Active
                </Button>
              </>
            )}
          </>
        )}
      </div>

      <Table {...getTableOptions()} />

      <PharmacyOnHoldConfirmationModal
        visible={showUpdatePharmacyModal}
        submitInactiveComment={onPharmacyStatusUpdate}
        onHoldCancel={() => setShowUpdatePharmacyModal(false)}
        pharmacyCount={selectedPharmacies?.pharmacyIds?.length}
      />
    </>
  );
};

export default withRouter(withRoles(PharmacyList));
