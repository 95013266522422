import { Div, FormCheckboxGroupField } from '@zipdrug/ui';
import aphroditePropType from 'aphrodite-prop-type';
import React from 'react';
import FilterFieldLabel from './FilterFieldLabel';
import FilterMedicationsFieldItem from './FilterMedicationsFieldItem';

const FilterMedicationsField = ({ styles, ...props }) => (
  <FormCheckboxGroupField
    {...props}
    align="start"
    containerRenderer={Div}
    direction="column"
    itemRenderer={FilterMedicationsFieldItem}
    labelRenderer={FilterFieldLabel}
    styles={styles}
    theme="dark"
    values={['hypertension', 'cholesterol', 'diabetes']}
    wrap
  />
);

FilterMedicationsField.propTypes = {
  styles: aphroditePropType,
};

export default FilterMedicationsField;
