import { createClientAndSessionStore } from '@zipdrug/react-api-sdk';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import React from 'react';
import { Provider } from 'react-redux';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import thunkMiddleware from 'redux-thunk';
import ContextProvider from 'contexts';
import observe from './redux/observe';
import reducers from './redux/reducers';
import Routes from './routes/Routes';
import { API_WS_URL, API_HTTP_URL } from './settings';
import ErrorBoundary from './ErrorBoundary';
import './App.less';

const saveSubsetBlacklistFilter = createBlacklistFilter('form', [
  'addressForm',
  'contactForm',
  'createWorkstationPhone',
  'enrollForm',
  'forgotPassword',
  'login',
  'phone',
  'register',
  'registerWorkstation',
  'resetPassword',
]);

const { client, sessionStore } = createClientAndSessionStore({
  blacklist: ['audio', 'filterPanel', 'modal', 'mutation'],
  middleware: [thunkMiddleware],
  persistOptions: {
    transforms: [saveSubsetBlacklistFilter],
  },
  reducers,
  wsUrl: API_WS_URL,
  httpUrl: API_HTTP_URL,
});

export const apolloClient = client.instance();
const store = sessionStore.getStore();

const identifyFullStoryUser = user => {
  if (!user) return null;
  return window.FS.identify(user.id, {
    displayName: `${user.first_name} ${user.last_name}`,
    email: user.email,
  });
};

// Identify user for FullStory on pageload
observe(store, 'session.user', identifyFullStoryUser);

export default () => (
  <ErrorBoundary>
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          <ContextProvider>
            <Routes />
          </ContextProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </Provider>
  </ErrorBoundary>
);
