/* eslint-disable no-undef */
import { mutateLogin } from 'graphql/users';
import { setSession } from '@zipdrug/react-api-sdk';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isEmpty } from 'lodash';
import PIN_LOGIN from '../settings/graphql/mutatePin';
import SEND_SITE_BYPASS from '../settings/graphql/sendSiteBypass';
import { NODE_API_URL, API_HTTP_URL } from '../settings';
import SEND_NEW_PIN from '../settings/graphql/sendNewPin';

const mapDispatchToProps = (
  dispatch,
  { mutateLogin, mutatePinLogin, mutateSendNewPin, mutateSiteDownBypass },
) => ({
  externalLogin({ email, password }) {
    return mutateLogin({
      variables: {
        email,
        password,
      },
    }).then(
      ({
        data: {
          loginRefresh: { data },
        },
        errors,
      }) => {
        if (data.expirationToken) {
          const expirationToken = data.expirationToken;
          return { expirationToken };
        }

        if (errors) {
          return { errors };
        }

        const { user, accessToken, refreshToken, daysUntilPasswordExpires, redirect } = data;

        dispatch(
          setSession({
            token: accessToken,
            refreshToken,
            user,
            redirect,
          }),
        );

        return { user, daysUntilPasswordExpires, redirect };
      },
    );
  },
  pinLogin({ email, pin }) {
    return mutatePinLogin({
      variables: {
        pinModifiers: {
          email,
          pin,
        },
      },
    }).then(
      ({
        data: {
          pinLogin: { data },
        },
        errors,
      }) => {
        if (data.expirationToken) {
          const expirationToken = data.expirationToken;
          return { expirationToken };
        }

        if (errors) {
          return { errors };
        }

        const { user, accessToken, refreshToken, daysUntilPasswordExpires } = data;

        dispatch(
          setSession({
            token: accessToken,
            refreshToken,
            user,
          }),
        );

        return { user, daysUntilPasswordExpires };
      },
    );
  },
  async sendNewPin({ email }) {
    return mutateSendNewPin({
      variables: {
        email,
      },
    }).then(
      ({
        data: {
          sendNewPin: { data, errors },
        },
      }) => {
        return { data, errors };
      },
    );
  },
  siteBypass({ passcode }) {
    return mutateSiteDownBypass({
      variables: {
        passcode,
      },
    }).then(
      ({
        data: {
          sendSiteBypass: { data, errors },
        },
      }) => {
        return { data, errors };
      },
    );
  },
  async login() {
    const root = NODE_API_URL || API_HTTP_URL.replace('/graphql', '');
    const tokenUrl = `${root}/token-retrieval`;
    return fetch(tokenUrl, {
      method: 'GET',
      credentials: 'include',
    }).then(async response => {
      if (response.status === 500) {
        return { isSiteDown: true };
      } else if (response.status !== 200 || !response) {
        return null;
      }

      const tokenData = await response.json();

      if (isEmpty(tokenData)) {
        return null;
      }

      if (tokenData?.expirationToken) {
        const expirationToken = tokenData.expirationToken;
        return { expirationToken };
      }

      const { user, accessToken, refreshToken, daysUntilPasswordExpires } = tokenData.data;

      dispatch(
        setSession({
          token: accessToken,
          refreshToken,
          user,
        }),
      );

      return { user, daysUntilPasswordExpires };
    });
  },
});

export default Component =>
  compose(
    graphql(mutateLogin, { name: 'mutateLogin' }),
    graphql(PIN_LOGIN, { name: 'mutatePinLogin' }),
    graphql(SEND_NEW_PIN, { name: 'mutateSendNewPin' }),
    graphql(SEND_SITE_BYPASS, { name: 'mutateSiteDownBypass' }),
    connect(null, mapDispatchToProps),
  )(Component);
