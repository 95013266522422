import { compose, withProps } from 'recompose';
import {
  Role,
  isRole,
  isSystemAdmin,
  isAdmin,
  isPbsTeam,
  isPharmacyTeam,
  isPharmacistTeam,
  isBdTeam,
  isAscTeam,
  getCurrentUserRole,
  AllRoles,
  UserTypesMapping,
  ExtUserTypesMapping,
  isSystemAdminOrAsc,
  isProjectManager,
} from '../contexts/utils/models';
import withCurrentUser from './withCurrentUser';

const buildRolesProps = ({ currentUser: user, ...props }) => ({
  ...props,
  hasRole: {
    pbsUser: isRole(user, [Role.PbsUser]),
    pbsLead: isRole(user, [Role.PbsLead]),
    pbsAdmin: isRole(user, [Role.PbsAdmin]),
    pharmacyBusinessDev: isRole(user, [Role.PharmacyBusinessDev]),
    pharmacyBusinessDevAdmin: isRole(user, [Role.PharmacyBusinessDevAdmin]),
    pharmacyAsc: isRole(user, [Role.PharmacyAsc]),
    pharmacyAscAdmin: isRole(user, [Role.PharmacyAscAdmin]),
    pharmacistUser: isRole(user, [Role.PharmacistUser]),
    pharmacistAdmin: isRole(user, [Role.PharmacistAdmin]),
    systemAdmin: isRole(user, [Role.SystemAdmin]),
    projectManager: isRole(user, [Role.ProjectManager]),
  },
  isAdmin: isAdmin(user),
  isSystemAdmin: isSystemAdmin(user),
  isPbsTeam: isPbsTeam(user),
  isPharmacyTeam: isPharmacyTeam(user),
  isPharmacistTeam: isPharmacistTeam(user),
  isBdTeam: isBdTeam(user),
  isAscTeam: isAscTeam(user),
  currentUserRole: getCurrentUserRole(user),
  allRoles: AllRoles,
  UserTypesMapping,
  ExtUserTypesMapping,
  isSystemAdminOrAsc: isSystemAdminOrAsc(user),
  isProjectManager: isProjectManager(user),
});

export default Component => compose(withCurrentUser, withProps(buildRolesProps))(Component);
