import { border, colors, FlexItem, Row } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React, { Children, cloneElement } from 'react';

const COLUMN_BORDER = { color: colors.gray92 };
export const ColumnMinWidth = '17vw';

const sx = StyleSheet.create({
  column: {
    ...border.build('left', COLUMN_BORDER),
    flex: 1,
  },
  columnFirst: {
    ...border.build('left', { style: 'none' }),
    maxWidth: ColumnMinWidth,
  },
  columnSecond: {
    minWidth: '50rem',
  },
  columnThird: {
    maxWidth: 'initial',
  },
});

const ThreeColumnPageTemplate = ({ children, styles, ...props }) => (
  <FlexItem {...props} component={Row} styles={styles}>
    {Children.toArray(children).map((child, index) =>
      cloneElement(child, {
        key: index,
        styles: [
          sx.column,
          index === 0 ? sx.columnFirst : null,
          index === 1 ? sx.columnSecond : null,
          index === children.length - 1 ? sx.columnThird : null,
          child.props.styles,
        ],
      }),
    )}
  </FlexItem>
);

ThreeColumnPageTemplate.propTypes = {
  children: PropTypes.node,
  styles: aphroditePropType,
};

export default ThreeColumnPageTemplate;
