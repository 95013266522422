import {
  ArrowButton,
  colors,
  Div,
  ErrorMessage,
  font,
  FormPasswordField,
  Row,
  space,
} from '@zipdrug/ui';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { StyleSheet, css } from 'aphrodite/no-important';

const sx = StyleSheet.create({
  button: {
    width: '100%',
    fontWeight: font.weight.medium,
  },
  description: {
    color: colors.gray57,
    lineHeight: font.lineHeight.looser,
    marginBottom: space.oneAndTwoTenths,
  },
  formField: {
    marginBottom: space.oneAndTwoTenths,
  },
  secondaryActionRow: {
    marginTop: space.oneAndTwoTenths,
  },
  buttonCancel: {
    width: '80px',
    fontSize: '16px',
    margin: '5px',
    alignItem: 'right',
    fontWeight: font.weight.medium,
  },
  buttonOk: {
    width: '80px',
    fontSize: '16px',
    margin: '5px',
    fontWeight: font.weight.medium,
  },
});

const FORMFIELD_PROPS = {
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

export default class ResetPasswordForm extends Component {
  static propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onDashboardButtonClick: PropTypes.func,
    showDashboardButton: PropTypes.bool,
    submitFailed: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    submitting: PropTypes.bool,
    resetting: PropTypes.bool,
    isModal: PropTypes.bool,
    closeModal: PropTypes.func,
  };

  handlePasswordKeyPress = event => {
    // Submit form on Enter keypress
    if (event.which === 13 && !this.props.submitting) {
      event.preventDefault();

      if (this.props.submitSucceeded) return this.props.onDashboardButtonClick();
      return this.props.handleSubmit();
    }

    return null;
  };

  renderDashboardButton = () => {
    const { onDashboardButtonClick, showDashboardButton, submitSucceeded } = this.props;

    return showDashboardButton && submitSucceeded ? (
      <Row styles={sx.secondaryActionRow}>
        <ArrowButton focusable onClick={onDashboardButtonClick} styles={sx.button}>
          Sign in
        </ArrowButton>
      </Row>
    ) : null;
  };

  renderSubmitButton = () => {
    const { closeModal, submitSucceeded, submitting, handleSubmit, resetting } = this.props;

    if (this.props.isModal) {
      return [
        <hr style={{ backgroundColor: '#D3D3D3', border: 'none', height: '1px' }} />,
        <div style={{ textAlign: 'right' }}>
          <Button key="cancel" onClick={() => closeModal()} className={css(sx.buttonCancel)}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            className={css(sx.buttonOk)}
            success={submitSucceeded}
            loading={submitting || resetting}
          >
            Submit
          </Button>
        </div>,
      ];
    }
    return (
      <ArrowButton
        loading={submitting || resetting}
        onClick={handleSubmit}
        styles={sx.button}
        success={submitSucceeded && !resetting}
        text={submitSucceeded ? 'Success!' : 'Submit'}
      />
    );
  };

  renderError = () => {
    const { error, submitFailed } = this.props;

    return error && submitFailed ? (
      <ErrorMessage styles={sx.formField}>{error}</ErrorMessage>
    ) : null;
  };

  render = () => (
    <Div>
      <Div styles={sx.description}>
        Your password must be at least 15 characters long, including at least one capital letter,
        one number, one lowercase letter, and one special character ($ ! @ * ? |). It cannot contain
        spaces or 3 consecutive characters or numbers.
      </Div>
      <FormPasswordField
        {...FORMFIELD_PROPS}
        label="New password"
        name="password"
        placeholder="••••••••"
      />
      <FormPasswordField
        {...FORMFIELD_PROPS}
        label="Confirm new password"
        name="confirmPassword"
        onKeyPress={this.handlePasswordKeyPress}
        placeholder="••••••••"
      />
      {this.renderError()}
      {this.renderSubmitButton()}
      {this.renderDashboardButton()}
    </Div>
  );
}
