import {
  border,
  ButtonRow,
  colors,
  Column,
  Div,
  Dot,
  FlexItem,
  font,
  FullName,
  padding,
  Row,
  Skeleton,
  space,
  transition,
  zIndex,
  Span,
  alertOnError,
} from '@zipdrug/ui';
import { ScheduleOutlined } from '@ant-design/icons';
import { Button, Popover, Col, Typography } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { isNil, upperFirst } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { compose, withStateHandlers, withHandlers } from 'recompose';
import { CPESNIcon, ZipdrugEligibleIcon } from 'ui/CallCenter/svg';
import withRoles from 'hoc/withRoles';
import withCurrentPharmacy from '../../hoc/withCurrentPharmacy';
import ContactDropdownContainer from '../../ui/contact-dropdown/ContactDropdownContainer';
import AddressDropdownContainer from './address-dropdown/AddressDropdownContainer';
import EnrollDropdownContainer from './enrollment-dropdown/EnrollDropdownContainer';
import InsuranceDropdown from './insurance-dropdown/InsuranceDropdown';
import LanguageDropdown from './language-dropdown/LanguageDropdown';
import PharmacyDropdown from './pharmacy-dropdown/PharmacyDropdown';
import GuardianDropdown from './guardian-dropdown/GuardianDropdown';
import SnoozeDropdownContainer from './snooze-dropdown/SnoozeDropdownContainer';
import { DigitalEnrollmentSmall } from '../../ui/CallCenter/svg/DigitalEnrollment';
import EmailButton from './email-dropdown/EmailButton';
import PreferredFollowupButton from './preferred-followup/PreferredFollowupButton';
import DISMISS_FOLLOWUP from './graphql/dismissFollowup';

const { Text } = Typography;

const sx = StyleSheet.create({
  container: {
    ...border.build('left'),
    ...padding.build(0, space.default, 0, space.default),
    height: '6rem',
    zIndex: zIndex[6],
  },
  modelOpenContainer: {
    ...border.build('left'),
    ...padding.build(space.default),
    height: '6rem',
    zIndex: zIndex[7],
  },
  dropDownContainer: {
    ...border.build('left'),
    padding: '5px',
    zIndex: zIndex[5],
  },
  modelOpenDropDownContainer: {
    ...border.build('left'),
    padding: '5px',
    zIndex: zIndex[9],
  },
  editButton: {
    color: colors.theme.primary.default,
    cursor: 'pointer',
  },
  enrollmentDot: {
    marginLeft: 0,
    marginRight: space.quarter,
  },
  info: {
    color: colors.gray62,
    fontSize: font.size.medium,
    marginBottom: '8px',
  },
  memberInfo: {
    color: colors.gray62,
    fontSize: font.size.medium,
    marginBottom: '6px',
  },
  infoLoading: {
    height: font.size.large,
  },
  fadeIn: {
    ...transition.build({ duration: 100 }),
    opacity: 1,
    willChange: 'opacity',
  },
  loading: {
    opacity: 0,
  },
  meta: {
    color: colors.gray20,
    marginTop: '0.7rem',
    whiteSpace: 'nowrap',
  },
  position: {
    marginTop: '-6px',
    marginLeft: '-18px',
  },
  name: {
    color: colors.gray20,
    fontWeight: font.weight.bold,
    marginRight: space.half,
    marginLeft: '2.5px',
  },
  noSelect: {
    userSelect: 'none',
  },
  separator: {
    flex: 'none',
  },
  skeletonPrimary: {
    maxWidth: '16rem',
  },
  skeletonSecondary: {
    marginLeft: space.default,
    maxWidth: '10rem',
    ':first-child': {
      marginLeft: 0,
    },
  },
  digitalEnrollment: {
    color: colors.gray20,
    marginLeft: '15px',
  },
  digitalEnrollmentMargin: {
    marginLeft: '8px',
  },
  iconStyles: {
    marginRight: '5px',
    height: '16px',
  },
  metaPodition: {
    marginTop: '-36px',
  },
  memberValues: {
    color: colors.gray20,
    fontWeight: font.weight.bold,
    paddingRight: '12px',
  },
  memberKeys: {
    color: colors.gray20,
    paddingRight: '4px',
  },
  followupIcon: {
    color: '#949495',
    fontSize: 24,
    margin: '0 5px',
  },
  followupContainer: {
    margin: '0 10px',
    cursor: 'default',
  },
  buttonRowContainer: {
    justifyContent: 'flex-end',
  },
  overlayToggled: {
    zIndex: 700,
  },
  tagContainer: {
    ...border.build('left'),
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    width: '575px',
    margin: '2px',
    padding: '2px 7px',
    border: '1px solid #5009B5',
    backgroundColor: '#EBE4FF',
    fontSize: '15px',
    fontWeight: '500',
    color: '#231E33',
  },
});

class PatientInfo extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    refetchSelectedPatientInfo: PropTypes.func,
    isPbsTeam: PropTypes.bool,
    selectedPatient: PropTypes.object,
    onModelToggled: PropTypes.func,
    isModelOpen: PropTypes.bool,
    dismissFollowup: PropTypes.func,
    isPatientMinorEligible: PropTypes.bool,
  };

  state = {
    isLoading: true,
    hideIcon: false,
  };

  componentWillMount = () => {
    const { isLoading, selectedPatient } = this.props;
    this.setState({ isLoading: isLoading || !selectedPatient });
  };

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  componentWillReceiveProps({ isLoading, selectedPatient }) {
    let newLoadingState = !selectedPatient;
    if (isLoading !== this.props.isLoading && !isNil(isLoading)) {
      newLoadingState = isLoading;
    }

    this.setState({ isLoading: newLoadingState });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const currentHideIcon = window.innerWidth <= 1560;
    if (currentHideIcon !== this.state.hideIcon) {
      this.setState({ hideIcon: currentHideIcon });
    }
  };

  handleDismissRequiresFollowup = () => {
    const { selectedPatient, refetchSelectedPatientInfo } = this.props;

    alertOnError(
      this.props.dismissFollowup({
        variables: {
          patientId: selectedPatient.id,
        },
      }),
    ).then(() => {
      refetchSelectedPatientInfo();
    });
  };

  renderContent = (birthdayMoment, age_years) => (
    <>
      <FlexItem component={Column} style={{ height: '100%', marginTop: '20px' }}>
        {this.renderInfo(birthdayMoment, age_years)}
        {this.renderMemberInfo()}
      </FlexItem>
    </>
  );

  renderContact() {
    const { selectedPatient, refetchSelectedPatientInfo } = this.props;
    if (selectedPatient) {
      return (
        <ContactDropdownContainer
          contactItem={selectedPatient}
          refetchSelectedPatientInfo={refetchSelectedPatientInfo}
        />
      );
    }
    return null;
  }

  renderTag = (age_years, age_months) => {
    return (
      <Text className={css(sx.tag)}>
        This member is a minor
        <span className={css(sx.name)}>{`(${age_years} years and ${age_months} months)`}</span>
      </Text>
    );
  };

  renderInfo = (birthdayMoment, age_years) => {
    const { selectedPatient, isPbsTeam } = this.props;
    if (!selectedPatient) return null;

    const {
      first_name,
      last_name,
      sex,
      enroll_status,
      event_source,
      special_notes,
      dismiss_requires_followup,
      assigned_pharmacy,
    } = selectedPatient;

    const sexAbbrev = sex ? upperFirst(sex.charAt(0)) : '';
    let doNotCallText = '';
    let doNotCallDot = '';
    let serviceTypeCheck = true;
    const getPharmacyServicesType = assigned_pharmacy?.pharmacy_services;
    if (getPharmacyServicesType) {
      serviceTypeCheck = getPharmacyServicesType.some(
        pharmacy_service =>
          pharmacy_service?.pharmacy_service_type.pharmacy_service_type === 'Zipdrug',
      );
    }
    const doesRequireFollowup =
      special_notes?.length > 0 &&
      assigned_pharmacy?.status !== 'inactive' &&
      assigned_pharmacy?.status !== null &&
      serviceTypeCheck &&
      special_notes.indexOf('ZipDrug Claims') > -1 &&
      !dismiss_requires_followup;

    if (selectedPatient.is_dnc && isPbsTeam) {
      doNotCallText = <Div className="fs-hide">Do Not Call</Div>;
      doNotCallDot = <Dot />;
    }
    return (
      <Row align="center">
        <Row align="center" styles={[sx.info, sx.fadeIn]}>
          {selectedPatient?.program_eligibility === 'zipdrug_eligible' ||
          selectedPatient?.program_eligibility === 'zipdrug_and_cpesn_eligible' ? (
            <span className={selectedPatient?.program_eligibility && css(sx.iconStyles)}>
              <ZipdrugEligibleIcon />
            </span>
          ) : null}

          {selectedPatient?.program_eligibility === 'cpesn_eligible' ||
          selectedPatient?.program_eligibility === 'zipdrug_and_cpesn_eligible' ? (
            <span className={selectedPatient?.program_eligibility && css(sx.iconStyles)}>
              <CPESNIcon />
            </span>
          ) : null}

          <FullName id="e2e-patientInfoName" styles={sx.name} user={{ first_name, last_name }} />
          {sexAbbrev && <Div>{`${age_years}${sexAbbrev}`}</Div>}
          <Dot />
          <Div styles={sx.noSelect}>DOB:&nbsp;</Div>
          <Div className="fs-hide" id="e2e-patientInfoDob">
            {birthdayMoment.format('M/DD/YYYY')}
          </Div>
          {doNotCallDot}
          {doNotCallText}
          {event_source &&
            event_source !== 'ZD' &&
            (enroll_status === 'interested' || enroll_status === 'active') && (
              <Row align="center" styles={sx.digitalEnrollment}>
                <DigitalEnrollmentSmall />
                <Span styles={sx.digitalEnrollmentMargin}>Enrolled Online</Span>
              </Row>
            )}
        </Row>
        {doesRequireFollowup && (
          <Row hidden={this.state.hideIcon} className={css(sx.followupContainer)} align="center">
            <Popover
              content={
                <div>
                  <Button type="link" size="small" onClick={this.handleDismissRequiresFollowup}>
                    DISMISS
                  </Button>
                </div>
              }
              trigger="hover"
            >
              <ScheduleOutlined className={css(sx.followupIcon)} />
              <span>Requires Follow Up</span>
            </Popover>
          </Row>
        )}
      </Row>
    );
  };
  renderMemberInfo = () => {
    const { selectedPatient } = this.props;
    const plan_type = selectedPatient?.plans?.find(name => name?.is_primary)?.plan_type;
    return (
      <Row align="center" styles={[sx.memberinfo, sx.fadeIn]}>
        <Div styles={sx.memberKeys}>LOB&nbsp;</Div>
        <Div styles={sx.memberValues}>{plan_type}</Div>
        <Div styles={sx.memberKeys}>Payer&nbsp;</Div>
        <Div styles={sx.memberValues}>{selectedPatient?.payer?.name}</Div>
      </Row>
    );
  };

  renderLoading = () => (
    <FlexItem component={Column} style={{ height: '100%' }}>
      <Row align="center" styles={[sx.info, sx.infoLoading]}>
        <Skeleton color={border.color} styles={sx.skeletonPrimary} />
        <Skeleton styles={sx.skeletonSecondary} />
      </Row>
      <Row align="center" styles={[sx.meta, sx.infoLoading]}>
        <Skeleton styles={sx.skeletonSecondary} />
        <Skeleton styles={sx.skeletonSecondary} />
        <Skeleton styles={sx.skeletonSecondary} />
        <Skeleton styles={sx.skeletonSecondary} />
      </Row>
    </FlexItem>
  );

  renderMeta = () => {
    const { selectedPatient, onModelToggled, isModelOpen, isPatientMinorEligible } = this.props;
    return (
      <Row
        align="center"
        styles={
          isModelOpen
            ? [sx.fadeIn, sx.metaPosition, sx.overlayToggled]
            : [sx.fadeIn, sx.metaPosition]
        }
      >
        <EnrollDropdownContainer patient={selectedPatient} onModelToggled={onModelToggled} />
        <LanguageDropdown patient={selectedPatient} />
        <InsuranceDropdown patient={selectedPatient} />
        <PharmacyDropdown patient={selectedPatient} />
        {isPatientMinorEligible && <GuardianDropdown patient={selectedPatient} />}
      </Row>
    );
  };

  render = () => {
    const {
      selectedPatient,
      isModelOpen,
      refetchSelectedPatientInfo,
      isPatientMinorEligible,
    } = this.props;
    if (!selectedPatient) return null;

    const { birthday } = selectedPatient;
    const birthdayMoment = moment.utc(birthday);
    const age_years = Math.abs(birthdayMoment.diff(moment(), 'years'));
    const age_months = Math.abs(birthdayMoment.diff(moment(), 'months')) % 12;

    return (
      <>
        {isPatientMinorEligible && (
          <Row className={css(sx.tagContainer)}>
            <Col>
              {this.state.isLoading ? this.renderLoading() : this.renderTag(age_years, age_months)}
            </Col>
          </Row>
        )}
        <Row
          align="center"
          justify="space-between"
          styles={isModelOpen ? sx.modelOpenContainer : sx.container}
        >
          <Col>
            {this.state.isLoading
              ? this.renderLoading()
              : this.renderContent(birthdayMoment, age_years)}
          </Col>
          <Col>
            {!this.state.isLoading && (
              <ButtonRow className={css(sx.buttonRowContainer)}>
                <PreferredFollowupButton
                  patient={selectedPatient}
                  refetchSelectedPatientInfo={refetchSelectedPatientInfo}
                />
                <EmailButton
                  patient={selectedPatient}
                  refetchSelectedPatientInfo={refetchSelectedPatientInfo}
                />
                {this.renderContact()}
                <AddressDropdownContainer patient={selectedPatient} />
                <SnoozeDropdownContainer patient={selectedPatient} />
              </ButtonRow>
            )}
          </Col>
        </Row>
        <Div styles={isModelOpen ? sx.modelOpenDropDownContainer : sx.dropDownContainer}>
          {this.state.isLoading ? this.renderLoading() : this.renderMeta()}
        </Div>
      </>
    );
  };
}

export default compose(
  withCurrentPharmacy,
  withStateHandlers(
    { isModelOpen: false },
    {
      setModelOpen: () => value => ({ isModelOpen: value }),
    },
  ),
  withHandlers({
    onModelToggled: props => isOpen => {
      const { setModelOpen } = props;
      setModelOpen(isOpen);
    },
  }),
  graphql(DISMISS_FOLLOWUP, { name: 'dismissFollowup' }),
  withRoles,
)(PatientInfo);
