import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Input, Select, Typography } from 'antd';
import LineOfBusinessToNameMapping from '../../constants/LineOfBusinessToNameMapping';

const sx = StyleSheet.create({
  label: {
    margin: '0 0 2px 0 !important',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  halfSplit: {
    width: '40%',
    marginRight: '16px',
  },
  fullWidth: {
    width: '100%',
  },
  error: {
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#B50D0D',
  },
});

const { Text, Title } = Typography;
const { Option } = Select;

const pharmacyNetworkFormSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  lob: yup.string().required('Line of Business is a required field'),
});

const EditPharmacyNetworkModal = ({
  submitEditPharmacyNetwork,
  editPharmacyNetworkCancel,
  visible,
  pharmacyNetworkRecord,
}) => {
  const [pharmacyNetworkForm, setPharmacyNetworkForm] = useState({
    name: pharmacyNetworkRecord?.name,
    lob: pharmacyNetworkRecord?.lob,
  });
  const [errors, setErrors] = useState({});

  const onChange = (field, value) => {
    if (Object.keys(errors).length > 0) {
      validateForm();
    }
    setPharmacyNetworkForm({
      ...pharmacyNetworkForm,
      [field]: value,
    });
  };

  const validateForm = async () => {
    let errorPayload = {};

    await pharmacyNetworkFormSchema
      .validate({ ...pharmacyNetworkForm }, { abortEarly: false })
      .then(() => {
        setErrors({});
      })
      .catch(error => {
        errorPayload = error?.inner?.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        setErrors(errorPayload);
      });

    return errorPayload;
  };

  const onSubmit = async () => {
    const errorPayload = await validateForm();

    if (Object.keys(errorPayload).length === 0) {
      submitEditPharmacyNetwork(pharmacyNetworkForm);
    }
  };

  return (
    <Modal onOk={onSubmit} onCancel={editPharmacyNetworkCancel} visible={visible} okText="Save">
      <Title className="modelHeaderText" level={3}>
        Edit Pharmacy Network
      </Title>
      <div className={css(sx.flexContainer)}>
        <div className={css(sx.halfSplit)}>
          <Text className={css(sx.label)}>Pharmacy Network</Text>
          <br />
          <Input
            defaultValue={pharmacyNetworkForm?.name}
            value={pharmacyNetworkForm?.name}
            onChange={e => onChange('name', e?.target?.value)}
          />
          <br />
          {errors?.name && <Text className={css(sx.error)}>{errors?.name}</Text>}
        </div>
        <div className={css(sx.halfSplit)}>
          <Text className={css(sx.label)}>Line of Business</Text>
          <br />
          <Select
            className={css(sx.fullWidth)}
            defaultValue={pharmacyNetworkForm?.lob}
            value={pharmacyNetworkForm?.lob}
            onChange={value => {
              onChange('lob', value);
            }}
          >
            {Object.entries(LineOfBusinessToNameMapping).map(entry => (
              <Option value={entry[0]} key={entry[0]}>
                {entry[1]}
              </Option>
            ))}
          </Select>
          <br />
          {errors?.lob && <Text className={css(sx.error)}>{errors?.lob}</Text>}
        </div>
      </div>
    </Modal>
  );
};

EditPharmacyNetworkModal.propTypes = {
  submitEditPharmacyNetwork: PropTypes.func,
  editPharmacyNetworkCancel: PropTypes.func,
  visible: PropTypes.bool,
  pharmacyNetworkRecord: PropTypes.object,
};

export default EditPharmacyNetworkModal;
