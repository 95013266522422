const queryFetchTimes = {};

const fetchOnceOptions = (queryName, options = {}) => {
  const { skip, variables = {} } = options;
  if (skip) return options;

  const key = `${queryName}_${JSON.stringify(variables)}`;
  const times = queryFetchTimes[key] || 1;

  const fetchPolicy = times > 1 ? 'cache' : 'cache-and-network';
  queryFetchTimes[key] = times + 1;

  return {
    fetchPolicy,
    skip,
    variables,
  };
};

export default fetchOnceOptions;
