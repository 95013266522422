import { Column, Div, font, Row, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import PropTypes from 'prop-types';
import DeveloperLoginPanelButton from './DeveloperLoginPanelButton';

const sx = StyleSheet.create({
  container: {
    marginTop: space.two,
    width: '100%',
  },
  heading: {
    flex: 0,
    fontSize: font.size.large,
    fontWeight: font.weight.bold,
    marginBottom: space.default,
  },
  row: {
    flex: 1,
    marginBottom: space.default,
    ':last-of-type': { marginBottom: 0 },
  },
});

const DeveloperLoginPanel = ({ onSubmit }) => (
  <Column styles={sx.container} wrap>
    <Div styles={sx.heading}>Developer Shortcuts</Div>
    <Row align="center" justify="space-between" styles={sx.row}>
      <DeveloperLoginPanelButton onClick={onSubmit} roleType="pharmacy_user" />
      <DeveloperLoginPanelButton onClick={onSubmit} roleType="pharmacy_admin" />
    </Row>
    <Row align="center" justify="space-between" styles={sx.row}>
      <DeveloperLoginPanelButton onClick={onSubmit} roleType="zipdrug_user" />
      <DeveloperLoginPanelButton onClick={onSubmit} roleType="zipdrug_admin" />
    </Row>
  </Column>
);

DeveloperLoginPanel.propTypes = {
  onSubmit: PropTypes.func,
};

export default DeveloperLoginPanel;
