import { StyleSheet } from 'aphrodite/no-important';
import { groupBy, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Column, Div, font, padding, space } from '@zipdrug/ui';
import InsuranceCard from './InsuranceCard';

const sx = StyleSheet.create({
  container: {
    ...padding.build(space.default, font.size.small),
    width: '29rem',
  },
  section: {
    marginBottom: space.default,
    ':last-of-type': {
      marginBottom: 0,
    },
  },
  sectionTitle: {
    cursor: 'default',
    fontWeight: font.weight.bold,
    marginBottom: space.half,
    userSelect: 'none',
  },
});

const filterByPrimary = ({ is_primary }) => (is_primary ? 'primary' : 'secondary');

export default class InsuranceMenu extends Component {
  static propTypes = {
    patient: PropTypes.object,
  };

  componentWillMount = () => this.buildPlanList();
  componentWillReceiveProps = ({ patient: { plans } }) => this.buildPlanList(plans);

  sortedPlans = {};

  buildPlanList = plans => {
    this.sortedPlans = groupBy(plans || this.props.patient?.plans, filterByPrimary);
  };

  renderInsuranceCards = planType =>
    this.sortedPlans[planType].map(plan => <InsuranceCard {...plan} key={plan.description} />);

  renderSections = () =>
    Object.keys(this.sortedPlans).map(planType => (
      <Column key={planType} styles={sx.section}>
        <Div styles={sx.sectionTitle}>{upperFirst(planType)}</Div>
        {this.renderInsuranceCards(planType)}
      </Column>
    ));

  render = () => <Div styles={sx.container}>{this.renderSections()}</Div>;
}
