import { get, isNil, omit, omitBy } from 'lodash';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import withSnoozedPatientsVisibility from './withSnoozedPatientsVisibility';

const parseAssignedPharmacyId = filter => {
  const assigned_pharmacy_id = get(filter, 'assigned_pharmacy_id', {});
  if (typeof assigned_pharmacy_id === 'number') {
    return [assigned_pharmacy_id];
  }

  const { label, value } = assigned_pharmacy_id;
  if (label !== 'All' && value) return [value];

  // If the label is All, do not filter by pharmacy id or it
  // will filter out patients without an assigned pharmacy
  return [];
};

const parsePlanId = filter => {
  const option = get(filter, 'plan_id', {});

  const { label, value } = option || {};
  if (label !== 'All' && value) return value;

  // Only filter if the value is not all
  return [];
};

const buildFilterFromState = filter => {
  const options = omitBy(
    {
      ...filter,
      assigned_pharmacy_id: parseAssignedPharmacyId(filter),
      plan_id: parsePlanId(filter),
    },
    isNil,
  );

  return options;
};

const calculateFilterCount = filter =>
  Object.values(filter).filter(value => value && value.length).length;

const calculatePDCFilter = filter =>
  get(filter, 'pdc', []).map(measure => ({
    gte: parseFloat(get(filter, `${measure}.min`, 0)),
    lte: parseFloat(get(filter, `${measure}.max`, 100)),
    measure,
  }));

const mapStateToProps = state => {
  const filterState = get(state, 'form.filter.values', {});
  const filters = buildFilterFromState(filterState);
  return {
    ...filters,
    filterCount: calculateFilterCount(filters),
    pdc: calculatePDCFilter(filterState),
    search: get(state, 'query.patients.search'),
  };
};

export default Component =>
  compose(
    withSnoozedPatientsVisibility,
    mapProps(props => omit(props, 'onToggleShowSnoozed')),
    connect(mapStateToProps),
  )(Component);
