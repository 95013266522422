import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Button, Modal, Typography, Form, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { StyleSheet, css } from 'aphrodite';
import { ADD_PHARMACY_NOTE } from '../../graphql/pharmacyNotes';

// CSS styles
const sx = StyleSheet.create({
  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  modalContainer: {
    minWidth: '583px',
  },
  label: {
    margin: '0 0 2px 0 !important',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
  uploadButton: {
    padding: '8px 10px',
    fontSize: '16px',
  },
});

const { Title } = Typography;
const AddPharmacyNotesModal = ({ updateNotes, onCloseModal, pharmacyId, visible }) => {
  const [form] = Form.useForm();
  const [uploadedFile, setUploadedFile] = useState();

  const [addPharmacyNote] = useMutation(ADD_PHARMACY_NOTE, {
    onError: error => {
      message.error(`Error Adding Note: ${error.message}`);
    },
    onCompleted: () => {
      message.success('Save successful');
      updateNotes();
      form.resetFields();
      onCloseModal();
    },
  });

  const onCancel = () => {
    form.resetFields();
    onCloseModal();
  };

  const submitForm = () => {
    const newFormValues = form.getFieldsValue();
    form
      .validateFields()
      .then(() => {
        addPharmacyNote({
          variables: {
            text: newFormValues.text,
            pharmacy_id: pharmacyId,
            file: uploadedFile,
          },
        });
      })
      .catch(info => {
        return info;
      });
  };

  return (
    <Modal
      onOk={() => submitForm()}
      onCancel={() => onCancel()}
      visible={visible}
      okText="Save"
      className={css(sx.modalContainer)}
      footer={[
        <Button key="cancel" onClick={() => onCancel()} className={css(sx.button)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => submitForm()} className={css(sx.button)}>
          Add Note
        </Button>,
      ]}
    >
      <Title className="modelHeaderText" level={3}>
        Add Note
      </Title>
      <Form name="modify-form-ref" form={form} layout="vertical">
        <p className={css(sx.label)}>Add Note</p>
        <Form.Item name="text" rules={[{ required: true, message: 'Note is required.' }]}>
          <Input.TextArea />
        </Form.Item>
        <div>
          <div className={css(sx.label)}>
            ADD ATTACHMENT. JPG OR PDF ONLY.
            <Upload
              name="file"
              key="file-upload"
              accept={'.jpg,.pdf'}
              multiple={false}
              beforeUpload={() => false}
              maxCount={'1'}
              onChange={({ file }) => {
                setUploadedFile(file);
              }}
              className={css(sx.uploadButton)}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

AddPharmacyNotesModal.propTypes = {
  updateNotes: PropTypes.func,
  onCloseModal: PropTypes.func,
  pharmacyId: PropTypes.number,
  visible: PropTypes.bool,
};

export default AddPharmacyNotesModal;
