import { ScheduleOutlined } from '@ant-design/icons';
import {
  colors,
  Div,
  EnDash,
  FlexItem,
  font,
  Icon,
  Nbsp,
  position,
  Row,
  Skeleton,
  space,
  Span,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { DigitalEnrollmentLarge } from '../../ui/CallCenter/svg';

const sx = StyleSheet.create({
  container: {
    color: colors.gray45,
    fontSize: font.size.small,
    userSelect: 'text',
  },
  noSelect: {
    userSelect: 'none',
  },
  skeleton: {
    flex: 1,
    marginTop: '0.8rem',
  },
  snoozeIndicator: {
    color: colors.gray45,
  },
  snoozeIndicatorIcon: {
    ...position.size('1.4rem'),
    marginLeft: '0.8rem',
  },
  digitalEnrollment: {
    marginLeft: '20px',
  },
  followUp: {
    fontSize: 26,
    marginLeft: '20px',
  },
});

const SnoozeIndicator = ({ snoozed_until }) => {
  const snoozeDate = moment(snoozed_until);

  // Do not show SnoozeIndicator if date is in the past.
  if (snoozeDate.isBefore()) return null;

  const titleDate = snoozeDate.calendar(null, {
    nextDay: '[tomorrow at] h:mma',
    nextWeek: 'MMMM Do [at] h:mma',
    sameDay: 'h:mma',
    sameElse: 'MMMM Do [at] h:mma',
  });

  return (
    <Row align="safe center" styles={sx.snoozeIndicator} title={`Snoozed until ${titleDate}`}>
      <Div>
        {snoozeDate.calendar(null, {
          nextDay: 'MMM D',
          nextWeek: 'MMM D',
          sameDay: 'h:mm a',
          sameElse: 'MMM D',
        })}
      </Div>
      <Icon name="clock" styles={sx.snoozeIndicatorIcon} />
    </Row>
  );
};

SnoozeIndicator.propTypes = {
  snoozed_until: PropTypes.number,
};

const PatientListItemMetaSection = ({ loading, patient }) => {
  const birthday = get(patient, 'birthday');
  const snoozed_until = get(patient, 'snoozed_until');

  const { event_source, enroll_status, special_notes, dismiss_requires_followup } = patient;
  const doesRequireFollowup =
    special_notes?.length > 0 &&
    special_notes.indexOf('ZipDrug Claims') > -1 &&
    !dismiss_requires_followup;

  return (
    <Row styles={sx.container}>
      {loading ? (
        <Row align="center" styles={sx.skeleton}>
          <Skeleton color={colors.gray27} width={'7.5%'} />
          <Skeleton color={colors.gray27} style={{ marginLeft: space.eighth }} width={'25%'} />
        </Row>
      ) : (
        <Row align="center" component={FlexItem} justify="space-between">
          <Div>
            <Span styles={sx.noSelect}>
              DOB
              <Nbsp />
              <EnDash />
              <Nbsp />
            </Span>
            {birthday && (
              <Span className="fs-hide">{moment.utc(birthday).format('M/DD/YYYY')}</Span>
            )}
            {event_source &&
              event_source !== 'ZD' &&
              (enroll_status === 'interested' || enroll_status === 'active') && (
                <Span styles={sx.digitalEnrollment}>
                  <DigitalEnrollmentLarge />
                </Span>
              )}
            {doesRequireFollowup && (
              <Span styles={sx.followUp}>
                <ScheduleOutlined />
              </Span>
            )}
          </Div>
          {snoozed_until && <SnoozeIndicator snoozed_until={snoozed_until} />}
        </Row>
      )}
    </Row>
  );
};

PatientListItemMetaSection.propTypes = {
  loading: PropTypes.bool,
  patient: PropTypes.object,
};

export default PatientListItemMetaSection;
