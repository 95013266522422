import { Role } from 'contexts/utils/models';
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';

const defaultPayload = {
  skip: 0,
  limit: 15,
};

const generateVariables = searchValue => {
  return {
    ...defaultPayload,
    query: {
      roles: {
        type: {
          $not: [Role.PharmacistUser, Role.PharmacistAdmin],
        },
      },
      $or: _flatten(
        _map(['first_name', 'last_name'], item => {
          return _map(searchValue.split(' '), q => {
            return { [item]: { $ilike: `%${q}%` } };
          });
        }),
      ),
    },
  };
};

export default generateVariables;
