import { compose, withHandlers } from 'recompose';
import PatientListTabs, { TABS } from './PatientListTabs';
import withSnoozedPatientsVisibility from '../../hoc/withSnoozedPatientsVisibility';

export default compose(
  withSnoozedPatientsVisibility,
  withHandlers({
    onChange: ({ onToggleShowSnoozed }) => shouldShowSnoozed =>
      onToggleShowSnoozed(shouldShowSnoozed === TABS.snoozed),
  }),
)(PatientListTabs);
