import { Div, FormNewDropdownField } from '@zipdrug/ui';
import React from 'react';
import FilterFieldLabel from './FilterFieldLabel';

export default props => (
  <FormNewDropdownField
    {...props}
    containerRenderer={Div}
    labelRenderer={FilterFieldLabel}
    tabIndex={0}
    theme="dark"
  />
);
