import React from 'react';
import PropTypes from 'prop-types';
import SessionContextProvider from './SessionContextProvider';
import PharmacyContextProvider from './PharmacyContextProvider';
import WorkstationPhoneContextProvider from './WorkstationPhoneContextProvider';
import CallCenterContextProvider from './CallCenterContextProvider';
import LeadListProvider from './LeadListProvider';
import PharmacyListProvider from './PharmacyListProvider';
import OutcomesProvider from './OutcomesProvider';

export {
  SessionContextProvider,
  PharmacyContextProvider,
  WorkstationPhoneContextProvider,
  LeadListProvider,
};

const ContextProvider = ({ children }) => {
  return (
    <SessionContextProvider>
      <PharmacyContextProvider>
        <WorkstationPhoneContextProvider>
          <CallCenterContextProvider>
            <LeadListProvider>
              <PharmacyListProvider>
                <OutcomesProvider>{children}</OutcomesProvider>
              </PharmacyListProvider>
            </LeadListProvider>
          </CallCenterContextProvider>
        </WorkstationPhoneContextProvider>
      </PharmacyContextProvider>
    </SessionContextProvider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.node,
};

export default React.memo(ContextProvider);
