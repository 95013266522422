import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import withCurrentPharmacy from '../../hoc/withCurrentPharmacy';
import withCurrentUser from '../../hoc/withCurrentUser';
import withWorkstationPhone from '../../hoc/withWorkstationPhone';
import TopBar from './TopBar';

export default compose(
  withRouter,
  withWorkstationPhone,
  withCurrentUser,
  withCurrentPharmacy,
  withHandlers({
    onAccountClick: ({ history }) => () => history.push('/settings/account'),
    onPhoneClick: ({ history }) => () => history.push('/settings/phones'),
    onLeadListClick: ({ history }) => () => history.push('/leadlist'),
    onCohortListClick: ({ history }) => () => history.push('/cohortlist'),
    onMembersClick: ({ history }) => () => history.push('/members'),
    onOutcomesClick: ({ history }) => () => history.push('/outcomes'),
    onHelpClick: ({ history }) => () => history.push('/settings/help'),
  }),
)(TopBar);
