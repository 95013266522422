const ages = [
  {
    text: 'Adults',
    value: 'adults',
  },
  {
    text: 'Minors',
    value: 'minors',
  },
];

export default ages;
