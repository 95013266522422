import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, message, Modal, Typography } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_PHARMCY_NOTE } from '../../graphql/pharmacyNotes';

// CSS styles
const sx = StyleSheet.create({
  modalContainer: {
    minWidth: '583px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
});

const { Title } = Typography;
const DeletePharmacyNotesModal = ({ onCloseModal, notesId, updateNotes, visible }) => {
  const [form] = Form.useForm();

  const [deleteNote] = useMutation(DELETE_PHARMCY_NOTE, {
    onError: error => {
      message.error(`Error Deleting Note: ${error.message}`);
    },
    onCompleted: () => {
      message.success(`Note has been successfully deleted`);
      updateNotes();
      onCloseModal();
    },
  });

  const onCancel = () => {
    onCloseModal();
  };

  const onConfirm = () => {
    deleteNote({
      variables: {
        id: notesId,
      },
    });
  };

  return (
    <Modal
      className={css(sx.modalContainer)}
      footer={[
        <Button key="cancel" onClick={onCancel} className={css(sx.button)}>
          No
        </Button>,
        <Button key="submit" type="primary" onClick={onConfirm} className={css(sx.button)}>
          Yes
        </Button>,
      ]}
      getContainer={false}
      okText="Save"
      onOk={onConfirm}
      onCancel={onCancel}
      visible={visible}
    >
      <Title className="modelHeaderText" level={3}>
        Delete Note
      </Title>
      <Form name="modify-form-ref" form={form} layout="vertical">
        <div className={css(sx.flexContainer)}>
          <div className={css(sx.inputBox)}>
            <p className={css(sx.label)}>Are you sure you want to delete this note?</p>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

DeletePharmacyNotesModal.propTypes = {
  onCloseModal: PropTypes.func,
  notesId: PropTypes.number,
  updateNotes: PropTypes.func,
  visible: PropTypes.bool,
};

export default DeletePharmacyNotesModal;
