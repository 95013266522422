import { border, colors, Div, font, padding, Row, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import React from 'react';
import stylePropType from 'react-style-proptype';
import PropTypes from 'prop-types';
import FilterButton from '../filter/FilterButton';
import PatientListSearch from './PatientListSearch';
import PatientListTabsContainer from './PatientListTabsContainer';
import SearchResultsListHeader from './SearchResultsListHeader';

const sx = StyleSheet.create({
  container: {
    color: colors.white,
  },
  searchContainer: padding.build(space.default, space.default, font.size.xSmall),
  tabs: border.build('bottom', { color: colors.gray27 }),
});

const PatientListHeader = ({
  filterCount,
  isFilterPanelOpen,
  onClearFilters,
  onSearch,
  onToggleFilterPanel,
  search,
  style,
  styles,
  totalPatients,
}) => (
  <Div style={style} styles={[sx.container, styles]}>
    <Row align="center" justify="space-between" styles={sx.searchContainer}>
      <PatientListSearch onChange={onSearch} value={search} />
      <FilterButton isActive={!!filterCount || isFilterPanelOpen} onClick={onToggleFilterPanel} />
    </Row>
    {isFilterPanelOpen || filterCount || search ? (
      <SearchResultsListHeader
        filterCount={filterCount}
        onClearFilters={onClearFilters}
        search={search}
        totalPatients={totalPatients}
      />
    ) : (
      <PatientListTabsContainer styles={sx.tabs} />
    )}
  </Div>
);

PatientListHeader.propTypes = {
  ...SearchResultsListHeader.propTypes,
  isFilterPanelOpen: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  onToggleFilterPanel: PropTypes.func,
  search: PropTypes.string,
  style: stylePropType,
  styles: aphroditePropType,
};

export default PatientListHeader;
