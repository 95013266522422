import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withRoles from '../hoc/withRoles';
import SettingsSidebar from './SettingsSidebar';
import mutateLogout from './graphql/mutateLogout';

class SettingsSidebarContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
    isAdmin: PropTypes.bool,
    isPbsTeam: PropTypes.bool,
    location: PropTypes.object,
    mutateLogout: PropTypes.func,
  };

  handleClickAccount = () => this.props.history.push('/settings/account');
  handleClickHelp = () => this.props.history.push('/settings/help');
  handleClickPhones = () => this.props.history.push('/settings/phones');
  handleClickTeam = () => this.props.history.push('/settings/team');
  handleClickSupervisor = () => this.props.history.push('/settings/supervisor');
  handleClickVoicemail = () => this.props.history.push('/settings/voicemail');
  handleClickPharmacies = () => this.props.history.push('/settings/pharmacylist');
  handleClickPharmacyNetworks = () => this.props.history.push('/settings/pharmacynetworkslist');
  hancleClickZipcodeAssignment = () => this.props.history.push('/settings/zipcodeassignment');
  handleClickCampaigns = () => this.props.history.push('/settings/campaigns');
  handleClickEnrollersList = () => this.props.history.push('/settings/enrollerlist');
  handleClickNotes = () => this.props.history.push('/settings/noteslist');

  handleLogout = () => {
    this.props.history.push('/signin');
  };

  render = () => (
    <SettingsSidebar
      {...this.props}
      location={this.props.location}
      onClickSupervisor={this.handleClickSupervisor}
      onClickAccount={this.handleClickAccount}
      onClickHelp={this.handleClickHelp}
      onClickLogout={this.handleLogout}
      onClickPhones={this.handleClickPhones}
      onClickTeam={this.handleClickTeam}
      onClickPharmacyList={this.handleClickPharmacies}
      onClickPharmacyNetworksList={this.handleClickPharmacyNetworks}
      onClickZipcodeAssignment={this.hancleClickZipcodeAssignment}
      onClickCampaigns={this.handleClickCampaigns}
      onClickEnrollersList={this.handleClickEnrollersList}
      onClickNotesList={this.handleClickNotes}
      onClickVoicemail={this.handleClickVoicemail}
    />
  );
}

export default compose(
  graphql(mutateLogout, { name: 'mutateLogout' }),
  withRoles,
  withRouter,
)(SettingsSidebarContainer);
