import React, { useState, useEffect, useContext } from 'react';
import { patients } from '@zipdrug/react-api-sdk';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Button, Modal, Typography, Form, Select, Input, message } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { OutcomesContext } from 'contexts/OutcomesProvider';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import GET_OUTCOMES from './graphql/getOutcomes';
import STATUS_BUTTON_MAP from './utils/statusMap';

// CSS styles
const sx = StyleSheet.create({
  largeInputBox: { width: '50%' },
  inputBox: { minWidth: '170px', marginRight: '16px' },
  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  modalContainer: {
    minWidth: '583px',
  },
  label: {
    margin: '0 0 2px 0 !important',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  largeText: {
    fontSize: '16px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
  uploadButton: {
    padding: '8px 10px',
    fontSize: '16px',
  },
  errorMessage: {
    color: 'red',
  },
  successMessage: {
    color: 'green',
  },
  title: {
    fontWeight: 500,
  },
});

const { Title } = Typography;
const { Option: SelectOption } = Select;
const EnrollStatusModal = ({
  onCloseModal, // use this to close the model and refresh the states.
  patient,
  visible,
  isZipdrugUser,
  transformEnrolledStatus,
  enrollmentStatus,
}) => {
  // states
  const [form] = Form.useForm();
  const [outcomeTypes, setOutcomeReasonTypes] = useState([]);
  const [categoryArr, setCategoryArr] = useState([]);
  const [reasonsArr, setReasonsArr] = useState([]);
  const [disabledSave, setDisabledSave] = useState(true);
  const [showCategory, setCategoryVisible] = useState(false);
  const [finalFormValues, setFinalFormValues] = useState({});

  const {
    setOutcomesContext,
    setOutcomesUpdatedInCallContext,
    setIsEnrollStatusUpdatedContext,
    setUpdatedPharmacyContext,
    updatedPharmacyContext,
  } = useContext(OutcomesContext);
  const { callCenterUserStatusContext, isCallCenterActivatedContext } = useContext(
    CallCenterContext,
  );

  const capitalizeAllLetters = string => {
    return string
      .replace('_', ' ')
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ');
  };

  const capitalizeFirstLetter = string => {
    return (string.charAt(0).toUpperCase() + string.slice(1)).replace('_', ' ');
  };

  const [getOutcomeTypes] = useLazyQuery(GET_OUTCOMES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error Fetching Status Outcomes Information: ${error.message}`);
    },
    onCompleted: ({ outcomeReasonTypes }) => {
      if (enrollmentStatus && outcomeReasonTypes?.data) {
        filterOutcomeTypes(STATUS_BUTTON_MAP[enrollmentStatus], outcomeReasonTypes?.data);
      }
      checkValues();
    },
  });

  const [updateEnrollStatus] = useMutation(patients.mutateUpdatePatient, {
    onError: error => {
      message.error(`Error Updating Enroll Status: ${error.message}`);
    },
    onCompleted: ({ updatePatient }) => {
      setOutcomesContext(finalFormValues);
      setUpdatedPharmacyContext(updatePatient?.data?.assigned_pharmacy_id);
      // if enroll_status updated during call, prefill call outcomes modal
      const finalOutcome = finalFormValues.enroll_status_outcome;
      const enrollStatus = capitalizeAllLetters(
        transformEnrolledStatus(finalFormValues.enroll_status),
      );
      if (
        isCallCenterActivatedContext &&
        callCenterUserStatusContext === 'oncall' &&
        (finalOutcome === 'Enrolled' || finalOutcome === 'Not Interested')
      ) {
        setOutcomesUpdatedInCallContext({
          status: enrollStatus,
          reasons: finalFormValues.enroll_status_outcome_reason_ids,
          notes: finalFormValues.enroll_notes,
        });
      }
      message.success(`Enrollment status changed to ${enrollStatus}`);
      // if enroll_status updated, update enroll dropdown on member's page
      setIsEnrollStatusUpdatedContext(finalFormValues.enroll_status);
      form.resetFields();
      onCloseModal();
    },
  });

  const onCancel = () => {
    form.resetFields();
    setCategoryVisible(false);
    onCloseModal();
  };

  const submitForm = () => {
    const newFormValues = form.getFieldsValue();
    // get category name if category options not shown
    if (!showCategory) {
      const outcomeObj = outcomeTypes.find(o => o.enroll_status === newFormValues.status);
      newFormValues.category = outcomeObj.outcome;
    }
    const modifier = {
      enroll_status: newFormValues.status,
      enroll_status_outcome_reason_ids: newFormValues.reasons,
      enroll_status_outcome: newFormValues.category,
      enroll_notes: newFormValues.notes,
    };
    setFinalFormValues(modifier);
    form.validateFields().then(() => {
      updateEnrollStatus({
        variables: {
          id: patient.id,
          modifier,
        },
      });
      form.resetFields();
    });
  };

  const onStatusChange = (status, currentOutcomeTypes) => {
    form.resetFields(['category', 'reasons']);
    const filteredOutcomeTypes = currentOutcomeTypes.filter(c => c.enroll_status === status);
    setCategoryArr(filteredOutcomeTypes);
    setReasonsArr(filteredOutcomeTypes.filter(o => o.outcome === filteredOutcomeTypes[0].outcome));
  };

  const onCategoryChange = (category, currentOutcomeTypes) => {
    form.resetFields(['reasons']);
    setReasonsArr(currentOutcomeTypes.filter(c => c.outcome === category));
  };

  const updateDropdownValues = values => {
    const changedField = Object.keys(values)[0];
    if (changedField === 'status') {
      onStatusChange(values.status, outcomeTypes);
    } else if (changedField === 'category') {
      onCategoryChange(values.category, outcomeTypes);
    }
    checkValues();
  };

  const filterOutcomeTypes = (statuses, outcomeTypes) => {
    const status = statuses.filter(s => (s.zipdrugOnly && isZipdrugUser) || !s.zipdrugOnly);
    const statusArr = status.map(s => s.value);
    const reasonTypes = outcomeTypes.filter(r => {
      if (!patient?.assigned_pharmacy_id && !updatedPharmacyContext) {
        return statusArr.includes(r.enroll_status) && r.outcome !== 'ZD Pharmacy Abrasion';
      }
      return statusArr.includes(r.enroll_status);
    });
    setOutcomeReasonTypes(reasonTypes);
    setCategoryArr(reasonTypes);
  };

  const handleStatusChange = status => {
    form.validateFields(['status']);
    setCategoryVisible(status === 'not_interested');
  };

  const checkValues = () => {
    let emptyBoolean = false;
    const newFormValues = form.getFieldsValue();
    emptyBoolean = !!newFormValues.status && newFormValues.reasons?.length > 0;
    if (showCategory) {
      emptyBoolean = !!newFormValues.category;
    }
    emptyBoolean = newFormValues.reasons?.length > 0;
    setDisabledSave(!emptyBoolean);
  };

  useEffect(() => {
    getOutcomeTypes({
      variables: {
        query: {
          enroll_status_outcome_type: true,
        },
      },
    });
    setCategoryVisible(false);
    setReasonsArr([]);
  }, [visible]);

  return (
    <Modal
      onOk={() => submitForm()}
      onCancel={() => onCancel()}
      visible={visible}
      okText="Save"
      className={css(sx.modalContainer)}
      footer={[
        <Button key="cancel" onClick={() => onCancel()} className={css(sx.button)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={disabledSave}
          onClick={() => submitForm()}
          className={css(sx.button)}
        >
          Save
        </Button>,
      ]}
      getContainer={false}
    >
      <Title level={2} className={css(sx.title)}>
        Change Status
      </Title>
      <Form
        name="modify-form-ref"
        form={form}
        layout="vertical"
        onValuesChange={changedValues => updateDropdownValues(changedValues)}
      >
        <div className={css(sx.flexContainer)}>
          <div className={css(sx.inputBox)}>
            <p className={css(sx.label)}>Status</p>
            <Form.Item name="status" rules={[{ required: true, message: 'Status is required.' }]}>
              <Select
                size="large"
                key="status-select"
                onChange={value => handleStatusChange(value)}
                placeholder="<Select>"
              >
                {uniqBy(outcomeTypes, 'enroll_status').map(status => (
                  <SelectOption
                    value={status.enroll_status}
                    key={`${status.enroll_status}-${status.outcome_reason}`}
                  >
                    {status?.enroll_status &&
                      capitalizeFirstLetter(transformEnrolledStatus(status.enroll_status))}
                  </SelectOption>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        {showCategory ? (
          <div className={css(sx.flexContainer)}>
            <div className={css(sx.inputBox)}>
              <p className={css(sx.label)}>Category</p>
              <Form.Item
                name="category"
                rules={[{ required: showCategory, message: 'Category is required.' }]}
              >
                {categoryArr.length > 0 && (
                  <Select size="large" key="category-select" placeholder="<Select>">
                    {uniqBy(categoryArr, 'outcome').map(category => (
                      <SelectOption
                        value={category.outcome}
                        key={`${category.outcome}-${category.outcome_reason}`}
                      >
                        {category?.outcome}
                      </SelectOption>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>
        ) : null}
        <div>
          <p className={css(sx.label)}>Select Reasons (Can Select Multiple)</p>
          <Form.Item name="reasons" rules={[{ required: true, message: 'Reasons are required.' }]}>
            <Select
              disabled={reasonsArr.length === 0}
              key="reasons-select"
              mode="multiple"
              showArrow
              size="large"
              placeholder="<Select>"
            >
              {uniqBy(reasonsArr, 'outcome_reason').map(reason => (
                <SelectOption value={reason.id} key={`${reason.id}-${reason.outcome_reason}`}>
                  {reason?.outcome_reason}
                </SelectOption>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <p className={css(sx.label)}>Notes</p>
          <Form.Item name="notes" rules={[{ required: false }]}>
            <Input.TextArea />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

EnrollStatusModal.propTypes = {
  isZipdrugUser: PropTypes.bool,
  onCloseModal: PropTypes.func,
  patient: PropTypes.object,
  enrollmentStatus: PropTypes.string,
  visible: PropTypes.bool,
  transformEnrolledStatus: PropTypes.func,
};

export default EnrollStatusModal;
