import {
  border,
  colors,
  font,
  Icon,
  Label,
  padding,
  placeholder,
  position,
  Row,
  space,
  TextInput,
  transition,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

const sx = StyleSheet.create({
  clearIcon: {
    ...position.size('1.4rem'),
    color: colors.white,
    textDecoration: 'underline',
    cursor: 'pointer',
    ':hover': { color: colors.gray57 },
    ':active': { color: colors.gray45 },
  },
  container: {
    ...border.build('', { color: colors.bluePurple }),
    ...padding.build(space.half, font.size.xSmall),
    backgroundColor: '#EBE4FF',
    borderRadius: border.radius,
    height: '3.2rem',
    cursor: 'text',
    flex: 1,
    outline: 'none',
    marginRight: space.half,
    ':only-child': {
      marginRight: 0,
    },
  },
  searchIcon: {
    ...position.size('1.4rem'),
    ...transition.build(),
    color: colors.bluePurple,
    flex: 'none',
    lineHeight: 0,
  },
  searchIconFocus: {
    color: colors.bluePurple,
  },
  input: {
    ...border.buildRadius('', '0.4rem'),
    ...padding.build(0, font.size.nano),
    ...placeholder.build({
      color: colors.bluePurple,
      fontWeight: font.weight.normal,
    }),
    backgroundColor: '#EBE4FF',
    border: 'none',
    color: colors.bluePurple,
    fontSize: font.size.small,
    height: 'initial',
  },
});

export default class PatientListSearch extends Component {
  static propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    styles: aphroditePropType,
    value: PropTypes.string,
  };

  static defaultProps = {
    id: 'e2e-searchInput',
  };

  state = {
    isEnterKeyDown: false,
    isFocus: false,
    isHover: false,
    textInputValue: '',
  };

  componentDidMount = () => {
    if (this.props.value && !this.state.textInputValue) {
      this.setState({ textInputValue: this.props.value });
    }
  };

  onChange = e => {
    const value = e.target.value;
    this.setState({ textInputValue: value });
    this.handleFilter(value);
  };

  handleFilter = debounce(val => {
    this.props.onChange('', val);
  }, 500);

  buildInlineStyle = () => ({ color: this.state.isEnterKeyDown ? colors.yellow : null });

  handleBlur = () => this.setState({ isFocus: false });
  handleClearInput = () => {
    this.setState({ textInputValue: '' });
    this.props.onChange('', '');
  };
  handleFocus = () => this.setState({ isFocus: true });

  handleKeyDown = ({ key }) => {
    if (key === 'Enter') {
      this.setState({ isEnterKeyDown: true });
    }
  };

  handleKeyUp = () => {
    if (this.state.isEnterKeyDown) {
      this.setState({ isEnterKeyDown: false });
    }
  };

  handleMouseEnter = () => this.setState({ isHover: true });
  handleMouseLeave = () => this.setState({ isHover: false });

  renderClearIcon = () =>
    this.state.textInputValue ? (
      <Icon
        name="circleCloseFilled"
        onClick={this.handleClearInput}
        styles={sx.clearIcon}
        title="Clear search"
      />
    ) : null;

  renderInput = () => (
    <TextInput
      className="fs-hide"
      id={this.props.id}
      name="search"
      onBlur={this.handleBlur}
      onChange={this.onChange}
      onFocus={this.handleFocus}
      onKeyDown={this.handleKeyDown}
      onKeyUp={this.handleKeyUp}
      placeholder="Search all members"
      style={this.buildInlineStyle()}
      styles={sx.input}
      value={this.state.textInputValue}
    />
  );

  renderSearchIcon = () => {
    const { value } = this.props;
    const { isFocus, isHover } = this.state;

    return (
      <Icon
        name="search"
        styles={[sx.searchIcon, isFocus || isHover || value ? sx.searchIconFocus : null]}
      />
    );
  };

  render = () => (
    <Row
      align="center"
      component={Label}
      htmlFor={this.props.id}
      onMouseEnter={this.handleMouseEnter}
      onMouseLeave={this.handleMouseLeave}
      style={this.buildInlineStyle()}
      styles={[sx.container, this.state.isFocus ? sx.containerFocus : null, this.props.styles]}
      tabIndex={0}
    >
      {this.renderSearchIcon()}
      {this.renderInput()}
      {this.renderClearIcon()}
    </Row>
  );
}
