import { CheckboxItem, Span, TruncateText } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import { lowerCase, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FilterCheckboxGroupField from './FilterCheckboxGroupField';

// The ordering of this array is intentional and represents the scale of
// decending importance.
const AlertTypesByImportance = [
  'sms',
  '@mention',
  'voicemail',
  'missed_call',
  'intake',
  'note',
  'failed_delivery',
];

const sx = StyleSheet.create({
  checkboxItem: {
    minWidth: '50%',
  },
  container: {
    minHeight: '12rem',
  },
});

export default class FilterAlertsField extends Component {
  static propTypes = {
    alertsCountByType: PropTypes.object.isRequired,
    styles: aphroditePropType,
  };

  buildValues = () => {
    const availableAlertTypes = Object.keys(this.props.alertsCountByType);
    return AlertTypesByImportance.filter(value => availableAlertTypes.includes(value));
  };

  renderItem = itemProps => (
    <CheckboxItem {...itemProps} labelRenderer={this.renderItemLabel} styles={sx.checkboxItem} />
  );

  renderItemLabel = ({ label, styles, value }) => {
    const alertCount = this.props.alertsCountByType[value];
    const shouldIgnoreFormatting = label === '@mention' || label === 'sms';
    const formattedLabel = shouldIgnoreFormatting ? label : upperFirst(lowerCase(label));

    return (
      <TruncateText styles={styles}>
        <Span>{formattedLabel}</Span>
        {!!alertCount && <Span>&nbsp;({alertCount})</Span>}
      </TruncateText>
    );
  };

  render = () => (
    <FilterCheckboxGroupField
      {...this.props}
      itemRenderer={this.renderItem}
      styles={[sx.container, this.props.styles]}
      values={this.buildValues()}
      wrap
    />
  );
}
