import { colors } from '@zipdrug/ui';
import { StyleSheet, css } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';
import FilterPanel from '../filter/FilterPanel';
import PatientListContainer from '../list/PatientListContainer';
import PatientListHeaderContainer from './PatientListHeaderContainer';

const sx = StyleSheet.create({
  sidebarContainer: {
    // container width info is passed in 'styles' prop from PatientsPage
    display: 'flex',
    flexDirection: 'column',
  },
  patientListContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    zIndex: '600',
    backgroundColor: colors.darkBlue,
    flex: '7',
  },
  patientList: {
    flex: 1,
  },
  sidebarOpen: {
    zIndex: 700,
  },
});

const PatientsSidebar = ({
  filterCount,
  isFilterPanelOpen,
  isLoading,
  loadMoreRows,
  onToggleFilterPanelVisibility,
  patients,
  search,
  styles: widthStyles,
  totalPatients,
}) => {
  return (
    <div
      className={
        isFilterPanelOpen
          ? css(sx.sidebarOpen, sx.sidebarContainer, widthStyles)
          : css(sx.sidebarContainer, widthStyles)
      }
    >
      {isFilterPanelOpen && <FilterPanel onToggleFilterPanel={onToggleFilterPanelVisibility} />}

      <div className={css(sx.patientListContainer)}>
        <PatientListHeaderContainer
          filterCount={filterCount}
          isFilterPanelOpen={isFilterPanelOpen}
          onToggleFilterPanel={onToggleFilterPanelVisibility}
          search={search}
          totalPatients={totalPatients}
        />

        <div className={css(sx.patientList)}>
          <PatientListContainer
            filterCount={filterCount}
            isLoading={isLoading}
            loadMoreRows={loadMoreRows}
            patients={patients}
            search={search}
            totalPatients={totalPatients}
          />
        </div>
      </div>
    </div>
  );
};

PatientsSidebar.propTypes = {
  filterCount: PropTypes.number,
  isFilterPanelOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadMoreRows: PropTypes.func,
  onToggleFilterPanelVisibility: PropTypes.func,
  patients: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string,
  styles: aphroditePropType,
  totalPatients: PropTypes.number,
};

export default PatientsSidebar;
