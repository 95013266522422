import { colors, formatPhone } from '@zipdrug/ui';
import React, { useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { PlayCircleOutlined, RedoOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import { css, StyleSheet } from 'aphrodite/no-important';
import gql from 'graphql-tag';
import { get } from 'lodash';
import PageHeading from '../../ui/PageHeading';
import SettingsSidebarContainer from '../SettingsSidebarContainer';
import { MarkNewIcon, MarkListenedIcon } from './svg';
import dateConverter from '../../dashboard/shared/utils/dateConverter';
import Button from '../../ui/CallCenter/components/Button';
import { CallCenterContext } from '../../contexts/CallCenterContextProvider';
import './VoicemailPage.css';

export const GET_ALERTS = gql`
  query alerts($query: JSON, $limit: Int, $skip: Int) {
    alerts(query: $query, limit: $limit, skip: $skip) {
      data {
        id
        call_id
        completed_at
        created_at
        type
        patient {
          id
          first_name
          last_name
          language
        }
        call {
          id
          file_url
          recording_duration
          from_phone {
            id
            number
          }
        }
      }
      total
    }
  }
`;

export const UPDATE_ALERT = gql`
  mutation updateAlert($id: Int, $completed: Boolean) {
    updateAlert(id: $id, completed: $completed) {
      data {
        id
        completed_at
        type
      }
    }
  }
`;

const sx = StyleSheet.create({
  bold: {
    fontWeigt: 'bold',
  },
  flex: {
    display: 'flex',
  },
  pdg30: {
    padding: '0px 30px',
  },
  mgn20: {
    margin: '20px 0px',
  },
  width100: {
    width: '100%',
  },
  voicemailPage: {
    background: '#F2F2F2',
  },
  refresh: {
    display: 'inline-block',
    cursor: 'pointer',
    margin: '15px 0px',
    color: colors.bluePurple,
    fontWeight: 'bold',
    ':hover': {
      color: '#371895',
    },
  },
  patientLink: {
    color: '#5A2FD4',
    fontWeight: 'bold',
    cursor: 'pointer',
    ':hover': {
      color: '#371895',
    },
  },
  noOutline: {
    outline: 'none',
  },
  clrBlack: {
    color: 'black',
  },
  actionIcon: {
    height: '2em',
    display: 'flex',
    justifyContent: 'center',
    ':hover': {
      color: 'purple',
    },
  },
  unread: {
    background: 'red',
    height: '100%',
  },
  markBtn: {
    height: '30px',
    width: '30px',
    padding: '2px',
    border: 'none',
  },
});

const getDuration = seconds => {
  if (seconds < 60) {
    return `0:${seconds < 10 ? '0' : ''}${seconds}`;
  }
  const minutes = parseInt(seconds / 60, 10);
  const remainSeconds = seconds - minutes * 60;

  return `${minutes}:${remainSeconds}`;
};

const startingSize = 10;

const VoicemailPage = ({ history: { push } }) => {
  const { setSelectedPatientId } = useContext(CallCenterContext);
  const [currentPagination, setCurrentPagination] = useState({ skip: 0, limit: startingSize });

  const [updateAlert] = useMutation(UPDATE_ALERT);

  const { loading: loadingUsers, data: alertsData, refetch } = useQuery(GET_ALERTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      skip: 0,
      limit: startingSize,
      query: {
        type: {
          $in: ['missed_call', 'voicemail'],
        },
      },
    },
  });

  const alerts = get(alertsData, 'alerts.data');
  const data = alerts
    ? alerts.map(alert => {
        let caller;
        let patientId;
        if (alert.patient) {
          caller = `${alert.patient.first_name} ${alert.patient.last_name}`;
          patientId = alert.patient.id;
        } else if (get(alert, 'call.from_phone')) {
          caller = formatPhone(alert.call.from_phone.number);
        } else {
          caller = 'Unkown';
        }

        return {
          key: alert.id,
          unread: !alert.completed_at,
          callDuration:
            alert.type === 'voicemail' ? getDuration(alert.call.recording_duration) : null,
          date: dateConverter(alert.created_at, 'MM/DD/YYYY'),
          time: dateConverter(alert.created_at, 'LT'),
          caller,
          patientId,
          language: alert.patient ? alert.patient.language : 'none',
          file_url: alert.call.file_url,
          type: alert.type,
        };
      })
    : [];

  const columns = [
    {
      title: '',
      dataIndex: 'unread',
      key: 'unread',
      className: 'unread-col',
      render: unread => {
        return <div className={css(unread ? sx.unread : null)} />;
      },
    },
    {
      title: '',
      dataIndex: 'play',
      key: 'play',
      render: (text, record) => {
        return (
          <div className={css(sx.actionIcon, sx.clrBlack)}>
            {record.type === 'voicemail' ? (
              <Tooltip title="Listen" placement="bottom">
                <PlayCircleOutlined
                  style={{ fontSize: '23px', cursor: 'pointer' }}
                  onClick={() => {
                    window.open(record.file_url, '_blank');
                    if (record.unread) {
                      updateAlert({ variables: { id: record.key, completed: true } });
                    }
                  }}
                />
              </Tooltip>
            ) : null}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'mark',
      key: 'mark',
      render: (status, record) => {
        const style = {
          fontSize: '23px',
          color: 'purple',
          cursor: 'pointer',
        };
        return (
          <div>
            <Button type="purpleOutline" styles={sx.markBtn}>
              {record.unread ? (
                <Tooltip title="Mark as Complete" placement="bottom">
                  <MarkListenedIcon
                    style={style}
                    onClick={() => {
                      updateAlert({ variables: { id: record.key, completed: true } });
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Mark as New" placement="bottom">
                  <MarkNewIcon
                    style={style}
                    onClick={() => {
                      updateAlert({ variables: { id: record.key, completed: false } });
                    }}
                  />
                </Tooltip>
              )}
            </Button>
          </div>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: date => {
        return <div title="Date">{date}</div>;
      },
    },
    {
      title: 'Time',
      key: 'time',
      dataIndex: 'time',
      render: time => <div title="Time">{time}</div>,
    },
    {
      title: 'Caller',
      key: 'caller',
      dataIndex: 'caller',
      render: (caller, record) => {
        return (
          <div
            tabIndex={0}
            role="button"
            title="Caller"
            className={`${css(sx.noOutline, record.patientId ? sx.patientLink : null)} fs-hide`}
            onClick={() => {
              if (record.patientId) {
                setSelectedPatientId(record.patientId);
                push('/members');
              }
            }}
          >
            {caller}
          </div>
        );
      },
    },
    {
      title: 'Call Duration',
      key: 'callDuration',
      dataIndex: 'callDuration',
      render: callDuration => {
        return <div title="Call Duration">{callDuration || '-'}</div>;
      },
    },
    {
      title: 'Language',
      key: 'language',
      dataIndex: 'language',
      render: language => (
        <div>
          {
            {
              english: 'English',
              spanish: 'Spanish',
              none: '-',
            }[language]
          }
        </div>
      ),
    },
  ];

  const updateQuery = (page, pageSize) => {
    const pagination = { skip: (page - 1) * pageSize, limit: pageSize };
    setCurrentPagination(pagination);
    return refetch(pagination);
  };

  return (
    <div className={css(sx.flex, sx.voicemailPage)}>
      <SettingsSidebarContainer />

      <div className={css(sx.width100)}>
        <PageHeading title="Voicemails and Missed Calls" />

        <div className={css(sx.pdg30)}>
          <div
            tabIndex={0}
            role="button"
            title="Refresh data"
            className={css(sx.refresh, sx.noOutline)}
            onClick={() => refetch(currentPagination)}
          >
            <RedoOutlined /> Refresh Table
          </div>

          <div className={css(sx.mgn20)}>
            <Table
              loading={loadingUsers}
              columns={columns}
              dataSource={data}
              className="fs-hide"
              pagination={{
                pageSizeOptions: [5, 10, 15, 25, 50, 100],
                defaultPageSize: startingSize,
                showSizeChanger: true,
                total: get(alertsData, 'alerts.total', 0),
                onChange: updateQuery,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

VoicemailPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(VoicemailPage);
