import gql from 'graphql-tag';

export const GET_TWILIO_TOKEN = gql`
  query getTwilioToken {
    getTwilioToken {
      data {
        token
      }
    }
  }
`;

export const CALL_CENTER_USER_STATUS_SUBSCRIPTION = gql`
  subscription getCallCenterUserStatusSubscription {
    getCallCenterUserStatusSubscription {
      deregisterTwilio
      callCenterUserStatus
    }
  }
`;

export const UPDATE_USER_ACTIVITY = gql`
  mutation updateUserActivity {
    updateUserActivity
  }
`;

export const UPDATE_CALL_CENTER_USER_STATUS = gql`
  mutation updateCallCenterUserStatus(
    $callCenterUserStatus: CallCenterUserStatusEnum
    $callId: Int
  ) {
    updateCallCenterUserStatus(callCenterUserStatus: $callCenterUserStatus, callId: $callId)
  }
`;

export const CREATE_CALL_RECORD = gql`
  mutation createCallRecord(
    $toPhoneId: Int
    $dialpadNumber: String
    $toPharmacyId: Int
    $toUserId: Int
    $direction: CallDirectionType
  ) {
    createCallRecord(
      toPhoneId: $toPhoneId
      dialpadNumber: $dialpadNumber
      toPharmacyId: $toPharmacyId
      toUserId: $toUserId
      direction: $direction
    ) {
      data {
        callId
        toPhoneId
        toPharmacyId
        toUserId
        callerIdNumber
      }
    }
  }
`;

export const UPDATE_CALL_RECORD = gql`
  mutation updateCall(
    $call_id: Int!
    $to_phone_id: Int
    $from_phone_id: Int
    $to_user_id: Int
    $from_user_id: Int
    $to_pharmacy_id: Int
    $direction: CallDirectionType
  ) {
    updateCall(
      call_id: $call_id
      to_phone_id: $to_phone_id
      from_phone_id: $from_phone_id
      to_user_id: $to_user_id
      from_user_id: $from_user_id
      to_pharmacy_id: $to_pharmacy_id
      direction: $direction
    ) {
      data
    }
  }
`;

export const UPDATE_PHONE = gql`
  mutation updatePhone($id: Int!, $modifier: PhoneModifier!) {
    updatePhone(id: $id, modifier: $modifier) {
      data {
        extension
        id
        label
        number
      }
      errors
    }
  }
`;

export const ADD_PHONE = gql`
  mutation createPhone(
    $extension: String
    $label: String!
    $number: String!
    $pharmacy_id: Int
    $can_sms: Boolean
    $patient_id: Int
  ) {
    createPhone(
      extension: $extension
      label: $label
      number: $number
      pharmacy_id: $pharmacy_id
      can_sms: $can_sms
      patient_id: $patient_id
    ) {
      data {
        id
        label
        number
      }
      errors
    }
  }
`;

export const SAVE_CALL_TO_ACTIVITY = gql`
  mutation saveCallToPatientActivity($call_id: Int!, $patient_id: Int!) {
    saveCallToPatientActivity(call_id: $call_id, patient_id: $patient_id) {
      data {
        id
      }
      errors
    }
  }
`;

export const GET_CALL_RECORD = gql`
  query getCallRecord($callId: Int!) {
    getCallRecord(callId: $callId) {
      data {
        id
        from_phone_id
        to_phone_id
        from_user_id
        to_user_id
        to_pharmacy_id
        direction
        language_preference
        lob_preference
      }
    }
  }
`;

export const GET_ACTIVE_CONFERENCE = gql`
  query getActiveConference($callId: Int!) {
    getActiveConference(callId: $callId) {
      data {
        confFriendlyName
        conferenceSid
        conferenceCreatedAt
        participants {
          callSid
          type
          number
          muted
          coaching
          hold
          name
          typeId
          hasMultipleAssociations
        }
      }
    }
  }
`;

export const UPDATE_PARTICIPANT = gql`
  mutation updateParticipant(
    $conferenceId: String!
    $participantSid: String!
    $action: participantActionInput!
  ) {
    updateParticipant(
      conferenceSid: $conferenceId
      participantSid: $participantSid
      action: $action
    )
  }
`;

export const ADD_PARTICIPANT = gql`
  mutation addParticipant(
    $conferenceSid: String!
    $participantType: ParticipantTypeEnum!
    $pharmacyId: Int
    $phoneNumber: String
    $toPhoneId: String
    $userId: Int
  ) {
    addParticipant(
      conferenceSid: $conferenceSid
      participantType: $participantType
      pharmacyId: $pharmacyId
      phoneNumber: $phoneNumber
      toPhoneId: $toPhoneId
      userId: $userId
    )
  }
`;

export const ACTIVE_CONFERENCE_SUBSCRIPTION = gql`
  subscription getActiveConferenceSubscription($conferenceSid: String!) {
    getActiveConferenceSubscription(conferenceSid: $conferenceSid) {
      action # ADD, REMOVE, UPDATE
      conferenceSid
      participants {
        callSid
        type
        number
        muted
        coaching
        hold
        name
        typeId
        hasMultipleAssociations
      }
    }
  }
`;

export const GET_ENROLLERS = gql`
  query getEnrollers($query: JSON) {
    users(query: $query) {
      data {
        id
        first_name
        last_name
        inbound_eligible
        call_center_user_status
        feature_flags
      }
    }
  }
`;

export const GET_PHONES_WITH_ASSOCIATIONS = gql`
  query phones($query: JSON!) {
    phones(query: $query) {
      data {
        id
        number
        label
        order
        patient_id
        plan_id
        pharmacy_id
        prescriber_id
        is_caller_id
        patient {
          assigned_pharmacy_id
          enroll_status
          id
          language
          first_name
          last_name
          birthday
          sex
          payer {
            id
            name
          }
        }
        pharmacy {
          id
          name
          npi
        }
        plan {
          description
          id
          name
        }
        prescriber {
          id
          name
          npi
        }
      }
      errors
    }
  }
`;

export const END_CALL = gql`
  mutation endCall($callSid: String!) {
    endCall(callSid: $callSid)
  }
`;

export const GET_PHARMACIES = gql`
  query pharmacies {
    pharmacies {
      data {
        id
        name
        npi
        status
        maintenance_threshold
        address {
          id
          city
          label
          postal_code
          state
        }
        phones {
          id
          label
          number
          pharmacy_id
          extension
          order
        }
      }
    }
  }
`;

export const GET_CALL_OUTCOMES = gql`
  query outcomeReasonTypes($limit: Int, $skip: Int, $query: JSON) {
    outcomeReasonTypes(limit: $limit, skip: $skip, query: $query) {
      data {
        id
        outcome
        outcome_reason
        enroll_status
        pitched
      }
    }
  }
`;

export const ADD_CALL_OUTCOMES = gql`
  mutation createCallOutcome($callId: Int, $outcomeReasonTypeIds: [Int]!, $notes: String) {
    createCallOutcome(callId: $callId, outcomeReasonTypeIds: $outcomeReasonTypeIds, notes: $notes) {
      data {
        id
        patient_id
        notes
        __typename
      }
      errors
      __typename
    }
  }
`;

export const GET_USER_CALL_CENTER_STATUS = gql`
  query getCallCenterStatusByUserId($userId: Int!) {
    getCallCenterStatusByUserId(userId: $userId) {
      data {
        call_center_user_status
      }
    }
  }
`;
