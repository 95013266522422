import { StyleSheet } from 'aphrodite/no-important';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, colors, SplitDropdown, SplitDropdownButton, Tooltip } from '@zipdrug/ui';
import activeCallAnimation from '../activeCallAnimation';
import ContactFormContainer from './ContactFormContainer';
import ContactListContainer from './ContactListContainer';

const EMPTY_OBJECT = {};

const sx = StyleSheet.create({
  callActive: {
    backgroundColor: colors.greenDark,
    borderColor: colors.greenDarker,
    color: colors.white,
  },
  callActiveIcon: {
    color: colors.white,
  },
  callActiveIconAnimation: activeCallAnimation,
  disabled: {
    color: '#00000040',
    background: '#f5f5f5',
    borderColor: '#d9d9d9',
    textShadow: 'none',
    boxShadow: 'none',
    cursor: 'not-allowed',
    ':hover': {
      background: '#f5f5f5',
      borderColor: '#d9d9d9',
      color: '#00000040',
    },
  },
});

export default class ContactDropdown extends Component {
  static propTypes = {
    contactItem: PropTypes.object,
    isCalling: PropTypes.bool,
    isEditable: PropTypes.bool,
    onCallPhone: PropTypes.func,
    isCallCenterActivatedContext: PropTypes.bool,
    callCenterUserStatusContext: PropTypes.string,
    currentUser: PropTypes.shape({
      feature_flags: PropTypes.object,
    }),
    refetchSelectedPatientInfo: PropTypes.func,
    updateCallCenterStatusMutationContext: PropTypes.func,
  };

  static defaultProps = {
    contactItem: { phones: [] },
    isEditable: true,
  };

  state = {
    editItem: EMPTY_OBJECT,
    isEditing: false,
  };

  buildButtonStyles = () => [
    SplitDropdownButton.containerStyles,
    this.props.isCalling ? sx.callActive : null,
  ];

  handleClickEditContact = item => {
    this.handleToggleEditState();
    this.setState({ editItem: item });
  };

  handlePrimaryActionClick = () =>
    this.props.onCallPhone(get(this.props.contactItem, 'phones[0].id'));

  handleToggleDropdown = isOpen => {
    if (isOpen && !this.props.callCenterUserStatusContext !== 'oncall') {
      this.props.updateCallCenterStatusMutationContext('calldialing');
    }
    if (!isOpen) {
      // if closing modal, refetch patientInfo
      if (this.props && this.props.refetchSelectedPatientInfo) {
        this.props.refetchSelectedPatientInfo();
      }
      if (!this.props.callCenterUserStatusContext !== 'oncall') {
        this.props.updateCallCenterStatusMutationContext('available');
      }
      this.setState({ isEditing: false });
    }
  };

  handleToggleEditState = () =>
    this.setState(prevState => {
      const newState = {};

      if (prevState.isEditing) {
        newState.editItem = EMPTY_OBJECT;
      }

      newState.isEditing = !prevState.isEditing;
      return newState;
    });

  renderButton = () => {
    const { contactItem, isCalling } = this.props;

    const primaryLabel = get(contactItem, 'phones[0].label', '');
    return primaryLabel ? (
      <SplitDropdownButton
        icon="phone"
        iconStyles={isCalling ? sx.callActiveIconAnimation : null}
        styles={isCalling ? sx.callActiveIcon : null}
        text={isCalling ? 'Calling' : 'Call'}
        textColor={isCalling ? colors.white : colors.gray20}
        title={primaryLabel}
      />
    ) : null;
  };

  renderMenu = () =>
    this.state.isEditing ? (
      <ContactFormContainer
        contactItem={this.props.contactItem}
        onClickCancel={this.handleToggleEditState}
        phone={this.state.editItem}
      />
    ) : (
      <ContactListContainer
        contactItem={this.props.contactItem}
        isEditable={this.props.isEditable}
        onEditItem={this.handleClickEditContact}
        onToggleEditState={this.handleToggleEditState}
      />
    );
  render = () => {
    const { isCallCenterActivatedContext, callCenterUserStatusContext, contactItem } = this.props;
    const callCenterFeatureFlag = get(this.props.currentUser, 'feature_flags.callCenter');

    // button is disabled if 1. call center is not activated 2. user is on a call and this is the patient call button
    const shouldBeDisabled =
      callCenterFeatureFlag &&
      (!isCallCenterActivatedContext ||
        (callCenterUserStatusContext === 'oncall' && contactItem.__typename === 'Patient'));

    if (
      new Set(['callable_unclear', 'not_callable_dnc', 'not_callable_outside_hours']).has(
        this.props.contactItem.call_status,
      )
    ) {
      let content = '';

      switch (this.props.contactItem.call_status) {
        case 'not_callable_dnc':
          content = 'The patient has asked us not to call them.';
          break;
        case 'not_callable_outside_hours':
          content = "The patient's timezone indicates they're currently not within calling hours.";
          break;
        default:
          content = "The patient's timezone is unknown.";
          break;
      }

      return (
        <Tooltip arrow={false} content={content} distance={10} size="big">
          <SplitDropdown
            {...this.props}
            buttonRenderer={<Button size="small" />}
            buttonStyles={this.buildButtonStyles()}
            menuRenderer={this.renderMenu()}
            onToggle={this.handleToggleDropdown}
            small
            toggleButtonProps={{ id: 'e2e-contactDropdownDropButton' }}
            type="warning"
          >
            {this.renderButton()}
          </SplitDropdown>
        </Tooltip>
      );
    }
    return (
      <SplitDropdown
        {...this.props}
        buttonRenderer={<Button disabled={shouldBeDisabled} size="small" />}
        buttonStyles={[this.buildButtonStyles(), shouldBeDisabled ? sx.disabled : null]}
        menuRenderer={this.renderMenu()}
        onClick={this.handlePrimaryActionClick}
        onToggle={this.handleToggleDropdown}
        small
        toggleButtonProps={{ id: 'e2e-contactDropdownDropButton' }}
        type="success"
      >
        {this.renderButton()}
      </SplitDropdown>
    );
  };
}
