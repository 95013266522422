import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Typography } from 'antd';
import ResetPasswordFormContainer from './ResetPasswordFormContainer';

const sx = StyleSheet.create({
  modalContainer: {
    minWidth: '579px',
    minHeight: '252px',
  },
  description: {
    height: '99px',
    width: '539px',
    fontSize: '16px',
  },
});

const { Title } = Typography;

const ResetPasswordModal = ({ closeModal, modalVisibility }) => {
  return (
    <Modal
      onCancel={closeModal}
      visible={modalVisibility}
      className={css(sx.modalContainer)}
      footer={null}
    >
      <Title className="modelHeaderText" level={3}>
        Reset Password
      </Title>
      <ResetPasswordFormContainer closeModal={closeModal} history={history} />
    </Modal>
  );
};

ResetPasswordModal.propTypes = {
  closeModal: PropTypes.func,
  modalVisibility: PropTypes.bool,
};

export default ResetPasswordModal;
