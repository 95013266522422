import { setIpError, withLogout, withSession } from '@zipdrug/react-api-sdk';
import withSsoLogin from 'hoc/withSsoLogin';
import Cookies from 'js-cookie';
import { emailField, requiredField, validateForm, ArrowButton, space, font } from '@zipdrug/ui';
import withSiteBypass from 'hoc/withSiteBypass';
import moment from 'moment';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm, SubmissionError } from 'redux-form';
import { graphql } from 'react-apollo';
import { setPasswordExpiration, setPharmacyId } from '../redux/queryReducer';
import { CallCenterContext } from '../contexts/CallCenterContextProvider';
import { IS_NEW_BROWSER_SESSION } from '../routes/AuthenticatedComponent';
import LoginForm from './LoginForm';
import { LAST_USER_ACTIVITY_COOKIE } from '../session-trackers/IdleTimerContainer';
import mutateLogoutQuery from '../settings/graphql/mutateLogout';
import { PBSTeamRoles, PharmacyTeamRoles, ManagerRoles } from '../contexts/utils/models';
import { NODE_API_URL, API_HTTP_URL } from '../settings.js';

const sx = StyleSheet.create({
  arrowButton: {
    flex: 'none',
    marginTop: space.half,
    width: '100%',
    fontWeight: font.weight.medium,
  },
});

const ERROR_MESSAGES = {
  invalidEmail: 'is invalid',
  required: 'is required.',
};

const LOGIN_SCHEMA = {
  email: {
    ...emailField,
    required: true,
  },
  password: requiredField,
};

const validate = values => validateForm(values, LOGIN_SCHEMA, ERROR_MESSAGES);

const ReduxLoginForm = reduxForm({
  form: 'login',
  validate,
})(LoginForm);

class LoginFormContainer extends Component {
  static propTypes = {
    isAnthemEmployee: PropTypes.bool,
    history: PropTypes.object.isRequired,
    login: PropTypes.func,
    externalLogin: PropTypes.func,
    setUser: PropTypes.func,
    ipError: PropTypes.any,
    setIpError: PropTypes.func,
    siteByPass: PropTypes.bool,
  };

  state = {
    ssoUrl: '',
  };

  componentDidMount = async () => {
    const { mutateLogout, onLogout, userId, login } = this.props;

    const isSiteBypass = Cookies.get('site_down_bypass');

    const root = NODE_API_URL || API_HTTP_URL.replace('/graphql', '');
    const samlFetchUrl = `${root}/sso-login`;
    await fetch(samlFetchUrl, { method: 'GET' })
      .then(async res => {
        if (![401, 200].includes(res?.status)) {
          if (!this.props.siteByPass && !isSiteBypass) {
            this.props.history.push({
              pathname: '/site-down',
            });
          }
        }
        let samlUrl;
        if (res) {
          const samlResponse = await res?.json();
          samlUrl = samlResponse.samlUrl;
        }
        this.setState({ ssoUrl: samlUrl });
      })
      .catch(() => {});
    if (userId) {
      // Update call center user status to unavailable
      await this.context.updateCallCenterUserStatusOfflineMutationContext('offline');
      await mutateLogout();
      Cookies.remove('auth-session-cookie');
    } else {
      login()
        .catch(e => {
          let _error = 'No connection';
          if (e.errors && e.errors.length) {
            if (e.errors[0].type === 'AccountLocked') {
              const accountLockDate = new Date(e.errors[0].message);
              const timeOptions = { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };
              _error = `Account locked. Please try again after ${accountLockDate.toLocaleDateString(
                'en-US',
              )} ${accountLockDate.toLocaleTimeString('en-US', timeOptions)}.`;
            } else {
              _error = e.errors[0].message;
            }
          }
          throw new SubmissionError({ _error });
        })
        .then(res => {
          if (res?.isSiteDown && !this.props.siteByPass && !isSiteBypass) {
            this.props.history.push({
              pathname: '/site-down',
            });
          }
          if (res) {
            this.handleLoginResponse(res);
          }
        });
    }
    onLogout();
  };

  handleLoginResponse = res => {
    this.props.setIpError({ ipError: null });

    if (res.redirect) {
      this.props.history.push({
        pathname: `/pin-authentication`,
        state: { userId: res.user.email },
      });
      return;
    }

    if (res.expirationToken) {
      const expirationToken = res.expirationToken;
      this.props.history.push(`/reset-password?token=${expirationToken}&redirect=care`);
      return;
    }

    if (res.errors) {
      throw new SubmissionError(res.errors);
    }

    if (res.daysUntilPasswordExpires) {
      this.props.setPasswordExpiration({ passwordExpiration: res.daysUntilPasswordExpires });
    }

    // This cookie is use to keep track whether this session is a new browser session. New browser session means the user
    // restarted/close their browser. This is used by AuthenticatedRoutes.js to determine whether to log off user if it's not a new browser session.
    Cookies.set(IS_NEW_BROWSER_SESSION, true);
    Cookies.set(LAST_USER_ACTIVITY_COOKIE, Date.now());

    this.props.setUser(res.user);

    // Re-direct PBS to LeadLists
    if (PBSTeamRoles.includes(res?.user?.roles?.[0]?.type)) {
      this.props.history.push({
        pathname: '/leadlist',
        state: { daysUntilPasswordExpires: 1 },
      });
    } else if (PharmacyTeamRoles.includes(res?.user?.roles?.[0]?.type)) {
      this.props.history.push({
        pathname: '/settings/pharmacylist',
        state: { daysUntilPasswordExpires: 1 },
      });
    } else if (ManagerRoles.includes(res?.user?.roles?.[0]?.type)) {
      this.props.history.push({
        pathname: res?.user?.feature_flags?.cohort ? '/cohortlist' : '/settings/account',
        state: { daysUntilPasswordExpires: 1 },
      });
    } else {
      this.props.history.push({
        pathname: '/members',
        state: { daysUntilPasswordExpires: 1 },
      });
    }
  };

  static contextType = CallCenterContext;

  handleRequestResetPassword = () => this.props.history.push('/forgot-password');

  handleSubmit = ({ email, password }) =>
    this.props
      .externalLogin({ email, password })
      .catch(e => {
        let _error = 'No connection';
        if (e.graphQLErrors && e.graphQLErrors.length) {
          _error = e.graphQLErrors[0].message;
          if (_error.includes('ACCOUNT LOCKED')) {
            const newError = _error.replace('ACCOUNT LOCKED. PLEASE TRY AGAIN AFTER ', '');
            const lockDateTime = moment(new Date(newError)).format('MM/DD/YYYY h:mma');
            _error = `ACCOUNT LOCKED. PLEASE TRY AGAIN AFTER ${lockDateTime}`;
          }
        }
        throw new SubmissionError({ _error });
      })
      .then(res => {
        if (res) {
          this.handleLoginResponse(res);
        }
      });

  render = () => (
    <>
      {this.props.isAnthemEmployee ? (
        <a href={this.state.ssoUrl}>
          <ArrowButton styles={sx.arrowButton} text="Employee login" />
        </a>
      ) : (
        <ReduxLoginForm
          isAnthemEmployee={this.props.isAnthemEmployee}
          onRequestResetPassword={this.handleRequestResetPassword}
          onSubmit={this.handleSubmit}
          ipError={this.props.ipError}
        />
      )}
    </>
  );
}

const mapStateToProps = store => ({
  ipError: store.session.ipError,
});

const mapDispatchToProps = dispatch => ({
  setUser(user) {
    // If the current user has a pharmacy role
    // filter the queries by that pharmacy
    const roles = user?.roles?.filter(r => r.pharmacy_id);
    if (roles?.length) {
      dispatch(setPharmacyId({ pharmacy_id: roles[0].pharmacy_id }));
    }
  },
  setPasswordExpiration: ({ passwordExpiration }) =>
    dispatch(setPasswordExpiration({ passwordExpiration })),
  setIpError: ({ ipError }) => dispatch(setIpError({ ipError })),
});

LoginFormContainer.propTypes = {
  setPasswordExpiration: PropTypes.func,
  onLogout: PropTypes.func,
  mutateLogout: PropTypes.func,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default compose(
  graphql(mutateLogoutQuery, { name: 'mutateLogout' }),
  withLogout,
  withSsoLogin,
  withSession,
  withSiteBypass,
  connect(mapStateToProps, mapDispatchToProps),
)(LoginFormContainer);
