import { plans } from '@zipdrug/react-api-sdk';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withRoles from './withRoles';
import fetchOnceOptions from './utils/fetchOnceOptions';

export default function withPlans(Component) {
  const ComponentWithData = graphql(plans.queryPlans, {
    skip: ({ userId, hasRole: { pharmacistUser, pharmacistAdmin } }) =>
      !userId || (pharmacistUser && pharmacistAdmin),
    options: () =>
      fetchOnceOptions('plans', {
        // for now we only care about primary plans
        // if that changes make this a configurable option
        variables: { query: { is_primary: true } },
      }),
    props: ({ data: { loading: isLoading, plans: result } }) => ({
      isLoading,
      plans: get(result, 'data', []),
    }),
  })(Component);

  return compose(withRoles)(ComponentWithData);
}
